import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {DataContext} from './DataContext';

function CoursesPage() {
  const { organizationName } = useParams();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const { data } = useContext(DataContext);
  
  
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (!isLoggedIn) {
      navigate(`/${organizationName}/login`);
      return;
    }

    // Assuming data is available and structured as shown in the image
    const coursesData = data?.[0]?.attributes?.courses?.data || [];
    const formattedCourses = coursesData.map(course => ({
      id: course.id,
      name: course.attributes.title,
      cover: `https://backend.ailms.co${course.attributes.cover}`,
      description: course.attributes.description,
    }));
    setCourses(formattedCourses);
  }, [navigate, organizationName, data]); // Added data to dependency array

  return (
    <div>
    <div
      className="flex items-center min-h-screen p-6"
      style={{ backgroundColor: data?.[0]?.attributes?.PrimaryColor || '#f0f0f0' }}
    >
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl">
        <div className="p-6">
          <ul>
            {courses.length > 0 ? (
              courses.map(course => (
                <li key={course.id} className="mb-4 flex items-center border rounded-lg p-4 shadow-sm">
                  <img src={course.cover} alt={course.name} className="w-1/4 h-48 object-cover rounded mr-4" />
                  <div>
                    <Link 
                      to={{
                        pathname: `/lms/${organizationName}/courses/${course.id}`,
                        state: { organizationId: data?.[0]?.id }
                      }} 
                      className="block mb-2 text-lg font-bold"
                    >
                      {course.name}
                    </Link>
                    <p className="text-gray-600 mb-2 text-sm line-clamp-4">{course.description}</p>
                    <button 
                      onClick={() => window.open(`/lms/${organizationName}/courses/${course.id}`, '_blank')}
                      className="px-4 py-2 bg-blue-500 text-white rounded"
                    >
                      Launch Course
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <p>No courses available. Contact your admin.</p>
            )}
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}

export default CoursesPage;
