import React, { useState } from "react";
import { api } from "../../../api/api";

const FloatingSupportButton = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null); // eslint-disable-line no-unused-vars
  const [honeypot, setHoneypot] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(null); // Reset status message

    if (honeypot) {
      // If honeypot field is filled, assume it's a bot
      setStatus({ success: false, message: "Spam detected." });
      return;
    }

    try {
      await api.post("/api/email", {
        to: process.env.REACT_APP_SUPPORT_EMAIL, // Add this to your .env file
        subject: `Support Request from ${formData.name}`,
        text: formData.message,
        html: `<p><strong>Name:</strong> ${formData.name}</p>
               <p><strong>Email:</strong> ${formData.email}</p>
               <p><strong>Message:</strong> ${formData.message}</p>`,
      });
      setStatus({ success: true, message: "Email sent successfully!" });
    } catch (error) {
      setStatus({
        success: false,
        message: error.response?.data?.error?.message || "Failed to send email.",
      });
    }
  };

  return (
    <div>
      {/* Floating Button */}
      <button
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#007bff",
          color: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          fontSize: "18px",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => setFormVisible(!isFormVisible)}
      >
        ?
      </button>

      {/* Rollout Form */}
      {isFormVisible && (
        <div
          style={{
            position: "fixed",
            bottom: "100px",
            right: "20px",
            width: "300px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            padding: "20px",
            zIndex: 1000,
          }}
        >
          <h4 style={{ color: "#000" }}>Contact Support</h4>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: "10px" }}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "5px 0",
                  boxSizing: "border-box",
                  color: "#000",
                  border: "1px solid #ccc",
                }}
                required
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "5px 0",
                  boxSizing: "border-box",
                  color: "#000",
                  border: "1px solid #ccc",
                }}
                required
              />
            </div>
            <div style={{ marginBottom: "10px" }}>
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleInputChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  margin: "5px 0",
                  boxSizing: "border-box",
                  color: "#000",
                  border: "1px solid #ccc",
                }}
                required
              />
            </div>
            <div style={{ display: "none" }}>
              <input
                type="text"
                name="honeypot"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
              />
            </div>
            <button
              type="submit"
              style={{
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "4px",
                cursor: "pointer",
                width: "100%",
              }}
            >
              Send
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default FloatingSupportButton;
