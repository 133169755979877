import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Topbar from "../../modules/LayoutModule/Content/Topbar/Topbar";
import Footer from "../../modules/LayoutModule/Content/Footer/Footer";

function BlogList() {
    const [posts, setPosts] = useState([]);
    const [popularPosts, setPopularPosts] = useState([]);

    useEffect(() => {
        axios.get('https://backend.ailms.co/api/articles')
            .then(response => {
                setPosts(response.data.data);
                // Assuming popular posts are the first 3 for simplicity
                setPopularPosts(response.data.data.slice(0, 2));
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <>
            <Topbar />
            {/* Jumbotron with Search Bar */}
        <div className="relative text-white text-center w-full"> {/* Removed py-12 */}
          <img src="header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" /> {/* Full width image */}
          <img src="component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4" /> {/* Added image in the bottom left corner */}
          <div className="relative z-10 py-12"> {/* Added py-12 to inner div */}
            <h1 className="text-4xl font-bold mb-4">Blog</h1>
          </div>
        </div>
            <main className="container mx-auto mt-8">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-8/12 px-4 mb-8">
                        {posts.map(post => (
                            <div key={post.id} className="mb-8">
                                <img src={post.attributes.image?.url || 'https://via.placeholder.com/1200x600'} alt={post.attributes.Title} className="w-full h-64 object-cover rounded" />
                                <h2 className="text-4xl font-bold mt-4 mb-2">{post.attributes.Title}</h2>
                                <p className="text-gray-700 mb-4">{post.attributes.Content[0]?.children[0]?.text.substring(0, 200)}...</p>
                                <Link to={{ pathname: `/blog/${post.id}`, state: { id: post.id } }} className="text-blue-500 hover:underline">Read More</Link>
                            </div>
                        ))}
                    </div>
                    <div className="w-full md:w-4/12 px-4 mb-8">
                        <div className="bg-custom-gray px-4 py-6 rounded">
                            <h3 className="text-lg font-bold mb-2">Popular Articles</h3>
                            <ul className="list-disc list-inside">
                                {popularPosts.map(post => (
                                    <li key={post.id}>
                                        <Link to={{ pathname: `/blog/${post.attributes.Slug}`, state: { id: post.id } }} className="text-gray-700 hover:text-gray-900">{post.attributes.Title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
            {/* {posts.length > 0 && <BlogArticle posts={posts} />} Render BlogArticle only if posts is not empty */}
        </>
    );
}

export default BlogList;