// src/hooks/useProjectForm.js
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addProject, updateProject } from "../store/reducers/projectListSlice";
import { useAlert } from "../context/AlertContext";
import { api } from "../api/api";

const schema = yup.object().shape({
  name: yup.string().required("Project name is required"),
  project_image: yup
    .mixed()
    .test("required", "Project image is required", (value) => {
      return value?.[0]?.type || value?.url;
    })
    .test("fileType", "Unsupported File Format", (value) =>
      value && value.length > 0
        ? ["image/jpeg", "image/png"].includes(value[0].type)
        : true
    ),
});

export const useProjectForm = (initialValues = {}, isEdit = false) => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { ...initialValues },
  });

  const onSubmit = async (data) => {
    let response = null;
    const formData = new FormData();
    formData.append("name", data.name);
    if (data?.project_image && !data?.project_image?.url) {
      formData.append("project_image", data?.project_image?.[0]);
    }
    try {
      if (isEdit) {
        response = await api.put(`/api/projects/${data.id}`, formData);
      } else {
        response = await api.post("/api/projects", formData);
      }
      if (response && response.data) {
        const projectData = response.data;
        dispatch(isEdit ? updateProject(projectData) : addProject(projectData));
        showAlert(
          "success",
          `Project ${isEdit ? "updated" : "added"} successfully`
        );
      }
    } catch (error) {
      console.error("Error submitting project:", error);
      console.log("Response from backend:", error?.response);
      showAlert("error", error?.response?.data?.error?.message || "An unexpected error occurred.");
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    isDirty,
    onSubmit,
    watch,
    clearErrors,
  };
};