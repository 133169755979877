// src/utils/useHandleCheckout.js

import { useAuth } from "../context/AuthContext";
import { api } from "../api/api";
import { useNavigate } from "react-router-dom";

const useHandleCheckout = () => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  const handleCheckout = async (project_id, amount) => {
    // Check if the user is authenticated
    if (!isAuthenticated) {
      // Store the current payment data in location.state for redirection after login
      navigate("/login", {
        state: {
          redirectTo: "/checkout", // Store the current checkout page
          project_id: project_id,  // Pass the project ID
          amount: amount,   // Pass the project price
        },
    
      });
      console.log("redirecting to login123", project_id, amount);
      return;
    }
   
    try {
      // Proceed with checkout session creation
      const response = await api.post("/api/create-checkout-session", {
        amount: amount,
        customerEmail: user.email,
        successUrl: 'https://ailms.co/dashboard',
        cancelUrl: 'https://ailms.co/marketplace',
        projectId: project_id,
        userId: user.id,
      });
  
      console.log("response", response);
      const sessionUrl = response.url;
      window.location.href = sessionUrl;
    } catch (error) {
      console.error('Error creating Stripe Checkout session:', error);
    }
  };

  return handleCheckout;
};

export default useHandleCheckout;
