import React, { useMemo, useState } from "react";

const SingleSelectionPreview = ({
  data,
  previewRef,
  showResult = true,
  setResultData,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [result, setResult] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedOption !== null) {
      if (selectedOption === data.correctAnswer) {
        setResult({ msg: "Correct Answer!", type: "green" });
      } else {
        setResult({ msg: "Incorrect Answer!", type: "red" });
      }
    } else {
      setResult(null);
    }
  };
  const options = useMemo(() => data?.options?.filter((i) => i.label), [data]);

  const handleOptionChange = (index) => {
    setSelectedOption(index);
    setResultData?.({
      id: data.id,
      answer: options[index].label,
      answerId: index,
    });
  };

  return data?.title ? (
    <div className="mx-auto bg-white p-3 rounded-md shadow-md">
      <div ref={previewRef} id="preview-quiz">
        <h2 className="text-[16px] font-bold mb-4 break-all leading-5">
          {data.title}
        </h2>
        {options?.length ? (
          <form onSubmit={handleSubmit}>
            <div className="space-y-2">
              {options.map((option, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="radio"
                    id={`single-option-${index}`}
                    name="option"
                    value={index}
                    checked={selectedOption === index}
                    onChange={() => handleOptionChange(index)}
                    className="mr-2 text-[16px]"
                  />
                  <label
                    htmlFor={`single-option-${index}`}
                    className="select-none text-[16px] break-all leading-5"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            {showResult && (
              <button
                type="submit"
                disabled={selectedOption === undefined || selectedOption === null}
                className="bg-blue-500 text-[16px] text-white px-4 py-2 rounded-md mt-4 items-center"
              >
                Submit
              </button>
            )}
          </form>
        ) : null}
      </div>
      {result?.msg && showResult && (
        <p
          className={`mt-4 text-${result?.type}-600 font-semibold text-[16px]`}
        >
          {result?.msg}
        </p>
      )}
    </div>
  ) : (
    <p className="max-w-lg mx-auto text-center text-slate-400">No preview</p>
  );
};

export default SingleSelectionPreview;
