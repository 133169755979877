// src/container/UserLMSContainer/LMSContainer.jsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DataProvider } from './DataContext';  // Context for shared data
import LMSTopBar from './LMSTopBar';                   // The TopBar for LMS
import LMSLogin from './LMSLogin';                     // The login page for LMS users
import CoursesListPage from './CoursesListPage';       // List of courses
import CourseDetailPage from './CourseDetail';     // Detail page for each course

const LMSContainer = () => {
  return (
    <DataProvider> {/* Providing data context for the LMS */}
      <LMSTopBar />    {/* Always display TopBar */}
      
      {/* Nested routing for LMS */}
      <Routes>
        {/* Route for LMSLogin - Display this when user lands on /lms/:organizationName */}
        <Route path="/" element={<LMSLogin />} />

        {/* Route for the courses list - /lms/:organizationName/courses */}
        <Route path="courses" element={<CoursesListPage />} />

        {/* Route for the course detail page - /lms/:organizationName/courses/:courseId */}
        <Route path="courses/:courseId" element={<CourseDetailPage />} />
      </Routes>
    </DataProvider>
  );
};

export default LMSContainer;
