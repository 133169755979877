import {CircleIcon, HexagonIcon, LineIcon, SquareIcon, StarIcon, TriangleIcon} from "./iconHelper";
import {fabric} from 'fabric';
import React from "react";
import {centerFabricObject} from "./helpers";
import {v4 as uuidv4} from "uuid";
import {addSvgToStore, setAllMediaToSlide} from '../store/reducers/slideListSlice'




export const shapes = [
    {
        path: [
            { x: 342, y: 69 },
            { x: 381, y: 130 },
            { x: 452, y: 128 },
            { x: 409, y: 187 },
            { x: 449, y: 241 },
            { x: 381, y: 241 },
            { x: 346, y: 297 },
            { x: 308, y: 241 },
            { x: 236, y: 239 },
            { x: 284, y: 188 },
            { x: 238, y: 130 },
            { x: 308, y: 129 }
        ],
        icon:<StarIcon/>,
        name: 'Star',
        type:'polygon'
    },
    {
        path: [
            { x: 50, y: 50 },
            { x: 150, y: 50 },
            { x: 150, y: 150 },
            { x: 50, y: 150 }
        ],
        icon:<SquareIcon/>,
        name: 'Square',
        type:'polygon'
    },
    {
        path: [
            { x: 100, y: 50 },
            { x: 150, y: 75 },
            { x: 150, y: 125 },
            { x: 100, y: 150 },
            { x: 50, y: 125 },
            { x: 50, y: 75 }
        ],
        icon:<HexagonIcon/>,
        name: 'Hexagon',
        type:'polygon'
    },
    {
        path: [
            { x: 50, y: 150 },
            { x: 150, y: 150 },
            { x: 100, y: 50 }
        ],
        icon:<TriangleIcon/>,
        name: 'Triangle',
        type:'polygon'
    },
    {
        icon:<CircleIcon/>,
        path: [],
        name: 'Circle',
        type:'circle'
    },
    {
        icon:<LineIcon/>,
        path: [100,100,200,100],
        name: 'Line',
        type:'line'
    },

]

export class ShapesHelper{
    constructor(props, dispatch) {
        const {activeSlide} = props;
        this.canvas = activeSlide.canvas;
        this.slideId = activeSlide.id;
        this.dispatch = dispatch; // Store dispatch for later use
    }

    /**
     * gets the type and path points array to create the shape on canvas
     * @param type
     * @param points
     */
    add(type,points=[],url=null, obj ={}){
        if(type === 'polygon'){
            let polygon = new fabric.Polygon(points,{
                id: uuidv4(),
                fill:'#FFFFFF',
                stroke:'#000000',
                strokeWidth:2,
                listStyle: 'none',
                link: '',
                jumpToSlide: 0,
                ...obj
            });
            this.addToCanvas(polygon)

        }
        // after this all the cases other than the polygon will be handled
        else if(type === 'circle'){
            let circle = new fabric.Circle({
                id: uuidv4(),
                fill:'#FFFFFF',
                stroke:'#000000',
                radius:80,
                strokeWidth:2,
                listStyle: 'none',
                link: '',
                jumpToSlide: 0,
                ...obj
            })
            this.addToCanvas(circle)
        }
        else if(type === 'svg'){
            this.addSvg(url, obj)
        }
        else if(type === 'line'){
            this.addLine(points, obj);
        }
    }

    addLine(points, obj){
        var line = new fabric.Line(points, {
            id: uuidv4(),
            fill: 'black', // Line color
            stroke: 'black', // Line color
            strokeWidth: 2, // Line width
            listStyle: 'none',
            link: '',
            padding:5,
            jumpToSlide: 0,
            selectable: true, // Allow line to be selected
            hasControls: true, // Show controls for resizing, rotating, etc.
            ...obj
        });
        line.setControlsVisibility({
            mt: false,
            mb: false,
            tl: false,
            tr: false,
            bl: false,
            br: false,

        })
        this.addToCanvas(line);
    }

    /**
     * add svgs on the canvas using the url fetched from s3
     * @param url
     */

    addSvg(url, svgObj) {
        fabric.loadSVGFromURL(url, (objects, options) => {
            var obj = fabric.util.groupSVGElements(objects, options);
            obj.scale(2);
            const svgAttributes = {
                id: uuidv4(),
                type: "svg",
                fill: svgObj?.fill || "black",
                src: url, // Preserve the SVG URL

            };
            console.log('svgAttributes', svgAttributes);
            obj.set(svgAttributes);
            this.addToCanvas(obj);
            console.log('Dispatching addSvgToStore with:', svgAttributes);
            this.dispatch(addSvgToStore(svgAttributes)); // Use this.dispatch
            this.dispatch(setAllMediaToSlide({type:'shape', data: svgAttributes})); 
        });
    }
    /**
     * Add the object in the center of the canvas
     * @param obj
     */
    addToCanvas(obj){
        centerFabricObject(obj, this.canvas);
        this.canvas.add(obj);
        this.canvas.setActiveObject(obj);
        this.canvas.renderAll();
        
    }
    
}

