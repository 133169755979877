export const createScormManifest = (projectId, courseName, slideListData) => {
    const xmlHeader = `<?xml version="1.0" encoding="UTF-8"?>`;
  
    const manifestStart = `
    <manifest xmlns="http://www.imsproject.org/xsd/imscp_rootv1p1p2"
              xmlns:adlcp="http://www.adlnet.org/xsd/adlcp_rootv1p2"
              identifier="com.example.project.${projectId}"
              version="1.2">
      <metadata>
        <schema>ADL SCORM</schema>
        <schemaversion>1.2</schemaversion>
      </metadata>
      <organizations default="ORG-1">
        <organization identifier="ORG-1">
          <title>${courseName}</title>`;
  
    let itemElements = "";
    slideListData.forEach((slide, index) => {
      itemElements += `
          <item identifier="ITEM-${index + 1}" identifierref="RES-${index + 1}">
            <title>${slide.name}</title>
          </item>`;
    });
  
    const organizationEnd = `
        </organization>
      </organizations>
      <resources>`;
  
    let resourceElements = "";
    slideListData.forEach((slide, index) => {
      resourceElements += `
        <resource identifier="RES-${index + 1}" type="webcontent" adlcp:scormtype="sco" href="index.html">
          <file href="index.html"/>`;
  
      // Loop through the media files for this slide
      slide.media.forEach((mediaItem) => {
        const fileName = getFileNameFromUrl(mediaItem.src);
        if (mediaItem.type === "image") {
          resourceElements += `<file href="media/images/${fileName}"/>`;
        } else if (mediaItem.type === "audio") {
          resourceElements += `<file href="media/audio/${fileName}"/>`;
        } else if (mediaItem.type === "video") {
          resourceElements += `<file href="media/video/${fileName}"/>`;
        }
      });
  
      resourceElements += `</resource>`;
    });
  
    const manifestEnd = `
      </resources>
    </manifest>`;
  
    // Combine all parts of the manifest into one string
    const imsManifest = `${xmlHeader}
      ${manifestStart}
      ${itemElements}
      ${organizationEnd}
      ${resourceElements}
      ${manifestEnd}`;
  
    return imsManifest;
  };
  
  // Helper function to get the file name from a URL
  const getFileNameFromUrl = (url) => {
    if (!url) {
      return ''; // Return an empty string or handle the error as needed
    }
    const urlSegments = url.split("/");
    return urlSegments.pop().split("?")[0]; // Remove any query parameters
  };
  
