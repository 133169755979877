import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { publicApi } from '../../api/publicApi';
import pipwerks from 'pipwerks-scorm-api-wrapper';
import '../../utils/scormApi'; // Import the mock SCORM API
import { DataContext } from './DataContext';

function CourseDetailPage() {
  const { courseId } = useParams(); // Extract courseId from URL
  const [courseData, setCourseData] = useState(null);
  const [isIframeLoading, setIsIframeLoading] = useState(true); // Add state for iframe loading
  const { data, user } = useContext(DataContext); // Ensure organizationId is also extracted
  console.log('data', data);
  const sessionStartTimeRef = useRef(Date.now()); // Use useRef for session start time
  const totalTimeRef = useRef(pipwerks.SCORM.get("cmi.core.total_time"));

  // Parse SCORM time format
  const parseSCORMTime = (scormTime) => {
    const [hours, minutes, seconds] = scormTime.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
  };
  totalTimeRef.current = totalTimeRef.current ? parseSCORMTime(totalTimeRef.current) : 0;

  // Function to format session time in SCORM format (HH:MM:SS)
  const formatSessionTime = (seconds) => {
    const h = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
  };

  

  // SCORM initialization remains unchanged
  useEffect(() => {
    const scorm = pipwerks.SCORM;
    
    // Initialize the pipwerks SCORM API
    const scormInitialized = scorm.init();
    if (scormInitialized) {
      console.log('SCORM initialized successfully');
    } else {
      console.error('Failed to initialize SCORM');
    }
    // Expose SCORM to iframe content
    window.pipwerks = pipwerks;

    // Cleanup when the component unmounts
    return () => {
      // scorm.terminate();
    };
  }, [courseId]);

  // Fetch course data from API
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await publicApi.get(`/api/courses/${courseId}`);
        setCourseData(response.data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    if (courseId) {
      fetchCourseData();
    }
  }, [courseId]); // Only courseId is in the dependency array

  // Handle SCORM data updates from iframe
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'scormUpdate') {
        const { location, status, successStatus, sessionTime } = event.data;

        // Update session end time and total time
        const sessionEndTime = Date.now();
        const sessionSeconds = Math.floor((sessionEndTime - sessionStartTimeRef.current) / 1000);
        sessionStartTimeRef.current = sessionEndTime;

        totalTimeRef.current += sessionSeconds;

        // Update SCORM data using pipwerks SCORM API
        pipwerks.SCORM.set("cmi.core.organization_id", data?.[0]?.id); // Set user name
        pipwerks.SCORM.set("cmi.core.lesson_location", location);
        pipwerks.SCORM.set("cmi.core.lesson_status", status);
        pipwerks.SCORM.set("cmi.success_status", successStatus);
        pipwerks.SCORM.set("cmi.core.session_time", sessionTime);
        pipwerks.SCORM.set("cmi.core.total_time", formatSessionTime(totalTimeRef.current));
        pipwerks.SCORM.set("cmi.core.student_id", user);
        pipwerks.SCORM.set("cmi.core.course", courseData?.attributes?.title); // Set course title

        // Save SCORM data
        pipwerks.SCORM.save();
        
        console.log(`SCORM data updated: Location ${location}, Status: ${status}, Session Time: ${sessionTime}`);
      }

      // Handle quiz interactions
      if (event.data && event.data.type === 'scormInteraction') {
        const { interactionId, learnerResponse, result } = event.data;

        pipwerks.SCORM.set(`cmi.interactions.${interactionId}.id`, interactionId);
        pipwerks.SCORM.set(`cmi.interactions.${interactionId}.learner_response`, learnerResponse);
        pipwerks.SCORM.set(`cmi.interactions.${interactionId}.result`, result);
        pipwerks.SCORM.save();

        console.log(`SCORM interaction updated: ${interactionId}, Response: ${learnerResponse}, Result: ${result}`);
      }
    };

    window.addEventListener('message', handleMessage);

    const handleBeforeUnload = () => {
      pipwerks.SCORM.set("cmi.core.exit", "suspend"); // Mark as suspended on exit
      pipwerks.SCORM.save();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [data, courseData, user]);

  if (!courseId) return <div>Loading...</div>;

  return (
    <div>
      <h1>{courseData?.attributes?.title || 'Course Details'}</h1>

      {isIframeLoading && <div className="spinner">Loading...</div>} {/* Add loading spinner */}

      <iframe
        src={courseData?.attributes?.course_url} // Use the URL from courseData
        width="100%"
        height="500px"
        onLoad={() => {
          console.log("SCORM package loaded");
          setIsIframeLoading(false); // Set loading to false when iframe is loaded
        }}
        allowFullScreen
        title="SCORM Course Content"
      ></iframe>
    </div>
  );
}

export default CourseDetailPage;
