import React, { useState } from 'react';
import { Text, Square, MediaImage, MediaVideo, MicrophonePlus, Puzzle, DashboardDots, UserStar } from "iconoir-react"; 

function Sidebar({ onActiveComponentChange }) {
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
        onActiveComponentChange(item);
    };

    const renderIconWithText = (IconComponent, text, item) => (
        <div
            className={`flex flex-col items-center w-16 h-16 rounded-md justify-center cursor-pointer transition-colors duration-200 hover:text-black ${selectedItem === item ? 'bg-[#F9FAFB] text-black' : 'text-white'}`}
            onClick={() => handleItemClick(item)}
            style={{ padding: 0 }} // Remove padding
        >
            <IconComponent className={`w-6 h-6 ${selectedItem === item ? 'text-black' : 'text-white'}`}/>  
            <span className={`mt-1 font-xxs ${selectedItem === item ? 'text-black' : 'text-white'}`}>{text}</span>
        </div>
    );

    return (
        <aside className="w-28 bg-custom-topBlue text-white text-xxs flex flex-col items-center justify-between">
            <div className="flex w-full flex-col items-center">
                {renderIconWithText(Text, 'Text', 'text')}
                {renderIconWithText(Square, 'Shape', 'shape')}
                {renderIconWithText(MediaImage, 'Image', 'image')}
                {renderIconWithText(MediaVideo, 'Video', 'video')}
                {renderIconWithText(MicrophonePlus, 'Audio', 'audio')}
                {renderIconWithText(Puzzle, 'Components', 'components')}
                {renderIconWithText(DashboardDots, 'Quiz', 'quiz')}
                {renderIconWithText(UserStar, 'Avatar', 'avatar')}
            </div>
        </aside>
        
    );
}

export default Sidebar;
