import React, { useEffect, useState } from 'react';
import { User, ThumbsUp, ClockRotateRight } from 'iconoir-react';
import {api} from '../../api/api';
import { useAuth } from '../../context/AuthContext';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Import this to automatically register all necessary components

const AnalyticsSection = () => {
  const { user } = useAuth();
  const [scormData, setScormData] = useState([]);

  useEffect(() => {
    const fetchScormData = async () => {
      const pageSize = 25; // Set the page size as per your requirement
      let page = 1;
      let allData = [];
      let totalPages = 1;

      try {
        do {
          const response = await api.get(`/api/scorms?filters[organization][OrgAdmin][$eq]=${user.email}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`);
          const data = response.data.map(item => item.attributes);
          allData = allData.concat(data);
          totalPages = response.meta.pagination.pageCount;
          page += 1;
        } while (page <= totalPages);

        setScormData(allData);
      } catch (error) {
        console.error('Error fetching SCORM data:', error);
      }
    };

    fetchScormData();
  }, [user.email]);

  // Calculate total users
  const totalUsers = new Set(scormData.map(item => item.userEmail)).size;
  const activeSessions = scormData.filter(item => item.status === 'in-progress').length;
  const completedSessions = scormData.filter(item => item.status === 'completed').length;

  const sortedScormData = [...scormData].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  // Prepare data for the stacked bar chart
  const courseNames = [...new Set(scormData.map(item => item.course_name))];
  const completedData = courseNames.map(course => 
    scormData.filter(item => item.course_name === course && item.status === 'completed').length
  );
  const inProgressData = courseNames.map(course => 
    scormData.filter(item => item.course_name === course && item.status === 'in-progress').length
  );

  const chartData = {
    labels: courseNames,
    datasets: [
      {
        label: 'Completed',
        data: completedData,
        backgroundColor: 'rgba(34, 197, 94, 0.5)', // Tailwind green-500 with opacity
      },
      {
        label: 'In Progress',
        data: inProgressData,
        backgroundColor: 'rgba(234, 179, 8, 0.5)', // Tailwind yellow-500 with opacity
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        position: 'top',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div className="bg-white p-4">
      <main className="flex-1">
        <h1 className="text-2xl font-bold mb-4">Analytics</h1>
        <div className="grid gap-4 mb-8 grid-cols-1 md:grid-cols-3">
          {/* Cards */}
          <div className="flex items-center p-3 bg-white border border-gray-200 rounded-lg shadow-xs">
            <div className="p-2 mr-3 text-orange-500 bg-orange-100 rounded-full">
              <User className="w-4 h-4" />
            </div>
            <div>
              <p className="mb-1 text-xs font-medium text-black">
                Total Users
              </p>
              <p className="text-sm font-semibold text-black">
                {totalUsers}
              </p>
            </div>
          </div>
          <div className="flex items-center p-3 bg-white border border-gray-200 rounded-lg shadow-xs">
            <div className="p-2 mr-3 text-green-500 bg-green-100 rounded-full">
              <ThumbsUp className="w-4 h-4" />
            </div>
            <div>
              <p className="mb-1 text-xs font-medium text-black">
                Active Sessions
              </p>
              <p className="text-sm font-semibold text-black">
                {activeSessions}
              </p>
            </div>
          </div>
          <div className="flex items-center p-3 bg-white border border-gray-200 rounded-lg shadow-xs">
            <div className="p-2 mr-3 text-blue-500 bg-blue-100 rounded-full">
              <ClockRotateRight className="w-4 h-4" />
            </div>
            <div>
              <p className="mb-1 text-xs font-medium text-black">
                Completed Sessions
              </p>
              <p className="text-sm font-semibold text-black">
                {completedSessions}
              </p>
            </div>
          </div>
        </div>
        <p className="text-sm my-4">Total Users in last 6 months</p>
        <Bar data={chartData} options={chartOptions} />

        <div className="flex space-x-4 p-4">
          
        </div>
        
        {/* Latest Activity Table */}
        <p className="text-sm my-4">Latest Activity</p>

        <div className="latest-activity-table mt-8">
          <div className="w-full overflow-hidden rounded-md shadow-xs">
            <div className="w-full overflow-x-auto">
              <table className="w-full whitespace-no-wrap border border-gray-200">
                <thead>
                  <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50">
                    <th className="px-4 py-3">User Email</th>
                    <th className="px-4 py-3">Course Name</th>
                    <th className="px-4 py-3">Last Activity</th>
                    <th className="px-4 py-3">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y">
                  {sortedScormData.map((item, index) => (
                    <tr key={index} className="text-gray-700">
                      <td className="px-4 py-3 text-sm">{item.userEmail}</td>
                      <td className="px-4 py-3 text-sm">{item.course_name}</td>
                      <td className="px-4 py-3 text-sm">{new Date(item.updatedAt).toLocaleDateString()}</td>
                      <td className="px-4 py-3 text-sm">
                        <span
                          className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${
                            item.status === 'completed'
                              ? 'bg-green-100 text-green-800'
                              : item.status === 'in-progress'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-100 text-gray-800'
                          }`}
                        >
                          {item.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AnalyticsSection;
