import { publicApi } from "../api/publicApi";

let scormData = {};


const saveScormDataToBackend = async (scormData) => {
  try {
    const organization = String(scormData['cmi.core.organization_id']);
    const course_name = String(scormData['cmi.core.course']);
    //const session_time = String(scormData['cmi.core.session_time'] || '00:00');
    console.log('session_time', scormData['cmi.core.session_time']);
    const total_time = String(scormData['cmi.core.total_time'] || '00:00:00');
    const success_status = String(scormData['cmi.success_status'] || 'unknown');
    const response = await publicApi.post('/api/scorms', {
      data: {
        userEmail: scormData['cmi.core.student_id'],
        organization: organization, // Ensure this is correctly mapped
        progress: scormData['cmi.core.lesson_location'],
        score: scormData['cmi.core.score.raw'],
        status: scormData['cmi.core.lesson_status'],
        course_name: course_name,
        session_time: scormData['cmi.core.session_time'],
        total_time: total_time,
        success_status: success_status,
      },
    });
    
    const result = await response.data;
    console.log('SCORM data saved to backend:', result);
  } catch (error) {
    console.error('Error saving SCORM data to backend:', error);
  }
};

window.API = {
  LMSInitialize: function(param) {
    console.log("LMSInitialize called", param);
    return "true"; // Simulate successful initialization
  },
  LMSFinish: function(param) {
    console.log("LMSFinish called", param);
    return "true"; // Simulate successful finish
  },
  LMSGetValue: function(key) {
    console.log("LMSGetValue called", key);
    // Return mock data or empty string if not available
    return "";
  },
  LMSSetValue: function(key, value) {
    console.log("LMSSetValue called", key, value);
    // Store key-value pairs in the temporary storage
    scormData[key] = value;
    return "true"; 
  },
  LMSCommit: function(param) {
    console.log("LMSCommit called", param);
    // Call the function to save the SCORM data to the backend
    saveScormDataToBackend(scormData);
    return "true";
  },
  LMSGetLastError: function() {
    return "0"; // No error
  },
  LMSGetErrorString: function(errorCode) {
    return "No error";
  },
  LMSGetDiagnostic: function(errorCode) {
    return "No error";
  }
};
