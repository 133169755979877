import React from 'react';

export const CopyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-[14px]"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
      />
    </svg>
  );
};

export const CutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-[14px]"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0m7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
    </svg>
  );
};

export const DuplicateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-[14px]"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1M3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z" />
    </svg>
  );
};

export const PasteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-[14px]"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
      <path d="M3.5 1h.585A1.5 1.5 0 0 0 4 1.5V2a1.5 1.5 0 0 0 1.5 1.5h5A1.5 1.5 0 0 0 12 2v-.5q-.001-.264-.085-.5h.585A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1" />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-[15px]"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
    </svg>
  );
};
export const StarIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3174 3.61183C11.543 3.24404 11.6558 3.06014 11.8009 2.99739C11.9275 2.9426 12.0712 2.9426 12.1979 2.99739C12.3429 3.06014 12.4557 3.24404 12.6813 3.61183L14.6663 6.84813C14.7513 6.98682 14.7939 7.05616 14.8519 7.10659C14.9032 7.15121 14.9631 7.1849 15.0279 7.20557C15.1012 7.22893 15.1825 7.22924 15.3452 7.22986L19.2503 7.24464C19.7117 7.24638 19.9425 7.24726 20.0741 7.34454C20.1889 7.42937 20.2619 7.55921 20.2748 7.70135C20.2897 7.86435 20.1707 8.062 19.9326 8.45732L18.0479 11.5873C17.9575 11.7373 17.9124 11.8123 17.8947 11.8924C17.8791 11.9633 17.8791 12.0367 17.8947 12.1075C17.9124 12.1876 17.9575 12.2626 18.0479 12.4127L19.9326 15.5426C20.1707 15.9379 20.2897 16.1356 20.2748 16.2986C20.2619 16.4407 20.1889 16.5706 20.0741 16.6554C19.9425 16.7527 19.7117 16.7536 19.2503 16.7553L15.3452 16.7701C15.1825 16.7707 15.1012 16.771 15.0279 16.7944C14.9631 16.815 14.9032 16.8487 14.8519 16.8934C14.7939 16.9438 14.7513 17.0131 14.6663 17.1518L12.6813 20.3881C12.4557 20.7559 12.3429 20.9398 12.1979 21.0026C12.0712 21.0573 11.9275 21.0573 11.8009 21.0026C11.6558 20.9398 11.543 20.7559 11.3174 20.3881L9.33246 17.1518C9.2474 17.0131 9.20487 16.9438 9.14686 16.8934C9.09552 16.8487 9.03562 16.815 8.97081 16.7944C8.89759 16.771 8.81624 16.7707 8.65354 16.7701L4.74844 16.7553C4.28699 16.7536 4.05627 16.7527 3.92465 16.6554C3.80987 16.5706 3.73682 16.4407 3.72389 16.2986C3.70907 16.1356 3.82809 15.9379 4.06613 15.5426L5.95087 12.4127C6.0412 12.2626 6.08637 12.1876 6.10401 12.1075C6.11962 12.0367 6.11962 11.9633 6.10401 11.8924C6.08637 11.8123 6.0412 11.7373 5.95087 11.5873L4.06613 8.45731C3.82809 8.062 3.70907 7.86435 3.72389 7.70135C3.73682 7.55921 3.80987 7.42937 3.92465 7.34454C4.05627 7.24726 4.28699 7.24638 4.74844 7.24464L8.65354 7.22986C8.81624 7.22924 8.89759 7.22893 8.97081 7.20557C9.03562 7.1849 9.09552 7.15121 9.14686 7.10659C9.20487 7.05616 9.2474 6.98682 9.33246 6.84813L11.3174 3.61183Z"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SquareIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="2"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const TriangleIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Shape / Triangle">
        <path
          id="Vector"
          d="M4.37891 15.1999C3.46947 16.775 3.01489 17.5634 3.08281 18.2097C3.14206 18.7734 3.43792 19.2851 3.89648 19.6182C4.42204 20.0001 5.3309 20.0001 7.14853 20.0001H16.8515C18.6691 20.0001 19.5778 20.0001 20.1034 19.6182C20.5619 19.2851 20.8579 18.7734 20.9172 18.2097C20.9851 17.5634 20.5307 16.775 19.6212 15.1999L14.7715 6.79986C13.8621 5.22468 13.4071 4.43722 12.8135 4.17291C12.2957 3.94236 11.704 3.94236 11.1862 4.17291C10.5928 4.43711 10.1381 5.22458 9.22946 6.79845L4.37891 15.1999Z"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const HexagonIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8001 21.5382C12.5087 21.7065 12.3629 21.7906 12.208 21.8235C12.0709 21.8527 11.9293 21.8527 11.7922 21.8235C11.6373 21.7906 11.4915 21.7065 11.2001 21.5382L4.13984 17.462C3.8484 17.2937 3.70268 17.2096 3.5967 17.0919C3.50293 16.9877 3.43209 16.865 3.38879 16.7318C3.33984 16.5811 3.33984 16.4129 3.33984 16.0763V7.92385C3.33984 7.58732 3.33984 7.41905 3.38879 7.26842C3.43209 7.13514 3.50293 7.01245 3.5967 6.9083C3.70268 6.7906 3.8484 6.70647 4.13984 6.5382L11.2001 2.46196C11.4915 2.2937 11.6373 2.20957 11.7922 2.17664C11.9293 2.1475 12.0709 2.1475 12.208 2.17664C12.3629 2.20957 12.5087 2.2937 12.8001 2.46196L19.8604 6.5382C20.1518 6.70647 20.2975 6.7906 20.4035 6.9083C20.4973 7.01245 20.5681 7.13514 20.6114 7.26842C20.6604 7.41905 20.6604 7.58732 20.6604 7.92384V16.0763C20.6604 16.4129 20.6604 16.5811 20.6114 16.7318C20.5681 16.865 20.4973 16.9877 20.4035 17.0919C20.2975 17.2096 20.1518 17.2937 19.8604 17.462L12.8001 21.5382Z"
        stroke="#000000"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CircleIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SearchIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DuplicateSheetIcon = ({className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`size-3 cursor-pointer text-gray-700 hover:text-gray-500 ${className || ''}`}
      fill="currentColor"
      viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
      />
    </svg>
  );
};

export const DeleteSheetIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`size-3 cursor-pointer text-gray-700 hover:text-red-500 ${className || ''}`}
      fill="currentColor"
      viewBox="0 0 16 16">
      <path
        strokeWidth="3"
        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
      />
      <path
        strokeWidth="3"
        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
      />
    </svg>
  );
};

export const AddSheetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="size-10 cursor-pointer text-gray-400 transition-colors duration-200 group-hover:text-gray-500">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
    </svg>
  );
};

export const LayerFrontIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-3 text-gray-600"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2z" />
    </svg>
  );
};

export const LayerBackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-3 text-gray-600"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
    </svg>
  );
};

export const LinkUrlIcon = () => {
  return (
    <svg
      className="size-4 text-gray-600"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5 7H16.5C19.2614 7 21.5 9.23858 21.5 12C21.5 14.7614 19.2614 17 16.5 17H14.5M10.5 7H8.5C5.73858 7 3.5 9.23858 3.5 12C3.5 14.7614 5.73858 17 8.5 17H10.5M8.5 12H16.5"
        stroke="rgb(75, 85, 99)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LinkSlideIcon = () => {
  return (
    <svg
      className="size-4 text-gray-600"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 3H8.7C7.5799 3 7.01984 3 6.59202 3.21799C6.21569 3.40973 5.90973 3.71569 5.71799 4.09202C5.5 4.51984 5.5 5.0799 5.5 6.2V17.8C5.5 18.9201 5.5 19.4802 5.71799 19.908C5.90973 20.2843 6.21569 20.5903 6.59202 20.782C7.01984 21 7.5799 21 8.7 21H16.3C17.4201 21 17.9802 21 18.408 20.782C18.7843 20.5903 19.0903 20.2843 19.282 19.908C19.5 19.4802 19.5 18.9201 19.5 17.8V9M13.5 3L19.5 9M13.5 3V7.4C13.5 7.96005 13.5 8.24008 13.609 8.45399C13.7049 8.64215 13.8578 8.79513 14.046 8.89101C14.2599 9 14.5399 9 15.1 9H19.5M10.5 11.5H14.5V17.5L12.5 16.3094L10.5 17.5V11.5Z"
        stroke="rgb(75, 85, 99)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 size-3"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708" />
    </svg>
  );
};

export const PreviewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 size-3"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
    </svg>
  );
};

export const UserIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="size-7 transition-colors text-gray-700 duration-200 hover:text-gray-500"
      fill="currentColor"
      viewBox="0 0 16 16" {...props}>
      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
    </svg>
  );
};

export const AddTextIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2 size-5 text-gray-600"
      fill="currentColor"
      viewBox="0 0 16 16">
      <path d="M12.258 3h-8.51l-.083 2.46h.479c.26-1.544.758-1.783 2.693-1.845l.424-.013v7.827c0 .663-.144.82-1.3.923v.52h4.082v-.52c-1.162-.103-1.306-.26-1.306-.923V3.602l.431.013c1.934.062 2.434.301 2.693 1.846h.479z" />
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 12H20M12 4V20"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const FillIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9382 11.6849C13.9382 11.9144 13.7522 12.1005 13.5227 12.1005C13.2932 12.1005 13.1072 11.9144 13.1072 11.6849C13.1072 11.6873 13.1071 11.6886 13.1073 11.6886C13.1083 11.6887 13.1217 11.5751 13.2931 11.3179C13.3551 11.2249 13.4312 11.1229 13.5227 11.0118C13.6142 11.1229 13.6903 11.2249 13.7523 11.3179C13.9237 11.5751 13.9371 11.6887 13.9382 11.6886C13.9383 11.6886 13.9382 11.6873 13.9382 11.6849Z"
        fill="#344054"
        stroke="#344054"
        strokeWidth="2"
      />
      <path
        d="M7.80884 1.95474L9.05999 3.2059"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.80977 2.95566L3.30469 8.46077L7.3084 12.4645L12.8135 6.95936L8.80977 2.95566Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4.67578 7.11035L10.678 9.08373"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.84473 15.2237H16"
        stroke="#344054"
        strokeWidth="1.41553"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const BorderFillIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.92236 9.5H9.93236M9.92236 13.5H9.93236M9.92236 5.5H9.93236M13.9224 9.5H13.9324M5.92236 9.5H5.93236M5.12236 17.5H14.7224C15.8425 17.5 16.4025 17.5 16.8303 17.282C17.2067 17.0903 17.5126 16.7843 17.7044 16.408C17.9224 15.9802 17.9224 15.4201 17.9224 14.3V4.7C17.9224 3.5799 17.9224 3.01984 17.7044 2.59202C17.5126 2.21569 17.2067 1.90973 16.8303 1.71799C16.4025 1.5 15.8425 1.5 14.7224 1.5H5.12236C4.00226 1.5 3.44221 1.5 3.01438 1.71799C2.63806 1.90973 2.3321 2.21569 2.14035 2.59202C1.92236 3.01984 1.92236 3.57989 1.92236 4.7V14.3C1.92236 15.4201 1.92236 15.9802 2.14035 16.408C2.3321 16.7843 2.63806 17.0903 3.01438 17.282C3.44221 17.5 4.00226 17.5 5.12236 17.5Z"
        stroke="black"
      />
    </svg>
  );
};
export const LineIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="matrix(-1, 0, 0, -1, 0, 0)rotate(90)">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {' '}
        <g id="Interface / Line_Xl">
          {' '}
          <path
            id="Vector"
            d="M12 21V3"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />{' '}
        </g>{' '}
      </g>
    </svg>
  );
};
export const PlayIcon = () => {
  return (
    <svg
      width="15px"
      height="15px"
      viewBox="-0.5 0 7 7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#000000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {' '}
        <defs> </defs>{' '}
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          {' '}
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-347.000000, -3766.000000)"
            fill="#ffffff">
            {' '}
            <g id="icons" transform="translate(56.000000, 160.000000)">
              {' '}
              <path
                d="M296.494737,3608.57322 L292.500752,3606.14219 C291.83208,3605.73542 291,3606.25002 291,3607.06891 L291,3611.93095 C291,3612.7509 291.83208,3613.26444 292.500752,3612.85767 L296.494737,3610.42771 C297.168421,3610.01774 297.168421,3608.98319 296.494737,3608.57322"
                id="play-[#ffffff]">
                {' '}
              </path>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};
export const PauseIcon = () => {
  return (
    <svg
      width="15px"
      height="15px"
      viewBox="-1 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-227.000000, -3765.000000)"
          fill="#ffffff">
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M172,3605 C171.448,3605 171,3605.448 171,3606 L171,3612 C171,3612.552 171.448,3613 172,3613 C172.552,3613 173,3612.552 173,3612 L173,3606 C173,3605.448 172.552,3605 172,3605 M177,3606 L177,3612 C177,3612.552 176.552,3613 176,3613 C175.448,3613 175,3612.552 175,3612 L175,3606 C175,3605.448 175.448,3605 176,3605 C176.552,3605 177,3605.448 177,3606"
              id="pause-[#1006]"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 size-5"
      fill="currentColor"
      strike="currentColor"
      viewBox="0 0 24 24">
      <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
    </svg>
  );
};

export const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 size-6"
      fill="currentColor"
      strike="currentColor"
      viewBox="0 0 24 24">
      <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z" />
    </svg>
  );
};

export const YoutubeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 size-6"
      fill="currentColor"
      strike="currentColor"
      viewBox="0 0 24 24">
      <path d="M21.8,8.001c0,0-0.195-1.378-0.795-1.985c-0.76-0.797-1.613-0.801-2.004-0.847c-2.799-0.202-6.997-0.202-6.997-0.202 h-0.009c0,0-4.198,0-6.997,0.202C4.608,5.216,3.756,5.22,2.995,6.016C2.395,6.623,2.2,8.001,2.2,8.001S2,9.62,2,11.238v1.517 c0,1.618,0.2,3.237,0.2,3.237s0.195,1.378,0.795,1.985c0.761,0.797,1.76,0.771,2.205,0.855c1.6,0.153,6.8,0.201,6.8,0.201 s4.203-0.006,7.001-0.209c0.391-0.047,1.243-0.051,2.004-0.847c0.6-0.607,0.795-1.985,0.795-1.985s0.2-1.618,0.2-3.237v-1.517 C22,9.62,21.8,8.001,21.8,8.001z M9.935,14.594l-0.001-5.62l5.404,2.82L9.935,14.594z" />
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1 size-6"
      fill="currentColor"
      strike="currentColor"
      viewBox="0 0 24 24">
      <path d="M12,4.622c2.403,0,2.688,0.009,3.637,0.052c0.877,0.04,1.354,0.187,1.671,0.31c0.42,0.163,0.72,0.358,1.035,0.673 c0.315,0.315,0.51,0.615,0.673,1.035c0.123,0.317,0.27,0.794,0.31,1.671c0.043,0.949,0.052,1.234,0.052,3.637 s-0.009,2.688-0.052,3.637c-0.04,0.877-0.187,1.354-0.31,1.671c-0.163,0.42-0.358,0.72-0.673,1.035 c-0.315,0.315-0.615,0.51-1.035,0.673c-0.317,0.123-0.794,0.27-1.671,0.31c-0.949,0.043-1.233,0.052-3.637,0.052 s-2.688-0.009-3.637-0.052c-0.877-0.04-1.354-0.187-1.671-0.31c-0.42-0.163-0.72-0.358-1.035-0.673 c-0.315-0.315-0.51-0.615-0.673-1.035c-0.123-0.317-0.27-0.794-0.31-1.671C4.631,14.688,4.622,14.403,4.622,12 s0.009-2.688,0.052-3.637c0.04-0.877,0.187-1.354,0.31-1.671c0.163-0.42,0.358-0.72,0.673-1.035 c0.315-0.315,0.615-0.51,1.035-0.673c0.317-0.123,0.794-0.27,1.671-0.31C9.312,4.631,9.597,4.622,12,4.622 M12,3 C9.556,3,9.249,3.01,8.289,3.054C7.331,3.098,6.677,3.25,6.105,3.472C5.513,3.702,5.011,4.01,4.511,4.511 c-0.5,0.5-0.808,1.002-1.038,1.594C3.25,6.677,3.098,7.331,3.054,8.289C3.01,9.249,3,9.556,3,12c0,2.444,0.01,2.751,0.054,3.711 c0.044,0.958,0.196,1.612,0.418,2.185c0.23,0.592,0.538,1.094,1.038,1.594c0.5,0.5,1.002,0.808,1.594,1.038 c0.572,0.222,1.227,0.375,2.185,0.418C9.249,20.99,9.556,21,12,21s2.751-0.01,3.711-0.054c0.958-0.044,1.612-0.196,2.185-0.418 c0.592-0.23,1.094-0.538,1.594-1.038c0.5-0.5,0.808-1.002,1.038-1.594c0.222-0.572,0.375-1.227,0.418-2.185 C20.99,14.751,21,14.444,21,12s-0.01-2.751-0.054-3.711c-0.044-0.958-0.196-1.612-0.418-2.185c-0.23-0.592-0.538-1.094-1.038-1.594 c-0.5-0.5-1.002-0.808-1.594-1.038c-0.572-0.222-1.227-0.375-2.185-0.418C14.751,3.01,14.444,3,12,3L12,3z M12,7.378 c-2.552,0-4.622,2.069-4.622,4.622S9.448,16.622,12,16.622s4.622-2.069,4.622-4.622S14.552,7.378,12,7.378z M12,15 c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S13.657,15,12,15z M16.804,6.116c-0.596,0-1.08,0.484-1.08,1.08 s0.484,1.08,1.08,1.08c0.596,0,1.08-0.484,1.08-1.08S17.401,6.116,16.804,6.116z" />
    </svg>
  );
};

export const GoogleIcon = () => {
  return (
    <svg
      width="26px"
      height="26px"
      viewBox="-0.5 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <defs></defs>
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Color-" transform="translate(-401.000000, -860.000000)">
          <g id="Google" transform="translate(401.000000, 860.000000)">
            <path
              d="M9.82727273,24 C9.82727273,22.4757333 10.0804318,21.0144 10.5322727,19.6437333 L2.62345455,13.6042667 C1.08206818,16.7338667 0.213636364,20.2602667 0.213636364,24 C0.213636364,27.7365333 1.081,31.2608 2.62025,34.3882667 L10.5247955,28.3370667 C10.0772273,26.9728 9.82727273,25.5168 9.82727273,24"
              id="Fill-1"
              fill="#FBBC05"></path>
            <path
              d="M23.7136364,10.1333333 C27.025,10.1333333 30.0159091,11.3066667 32.3659091,13.2266667 L39.2022727,6.4 C35.0363636,2.77333333 29.6954545,0.533333333 23.7136364,0.533333333 C14.4268636,0.533333333 6.44540909,5.84426667 2.62345455,13.6042667 L10.5322727,19.6437333 C12.3545909,14.112 17.5491591,10.1333333 23.7136364,10.1333333"
              id="Fill-2"
              fill="#EB4335"></path>
            <path
              d="M23.7136364,37.8666667 C17.5491591,37.8666667 12.3545909,33.888 10.5322727,28.3562667 L2.62345455,34.3946667 C6.44540909,42.1557333 14.4268636,47.4666667 23.7136364,47.4666667 C29.4455,47.4666667 34.9177955,45.4314667 39.0249545,41.6181333 L31.5177727,35.8144 C29.3995682,37.1488 26.7323182,37.8666667 23.7136364,37.8666667"
              id="Fill-3"
              fill="#34A853"></path>
            <path
              d="M46.1454545,24 C46.1454545,22.6133333 45.9318182,21.12 45.6113636,19.7333333 L23.7136364,19.7333333 L23.7136364,28.8 L36.3181818,28.8 C35.6879545,31.8912 33.9724545,34.2677333 31.5177727,35.8144 L39.0249545,41.6181333 C43.3393409,37.6138667 46.1454545,31.6490667 46.1454545,24"
              id="Fill-4"
              fill="#4285F4"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ScromIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      class="w-6 h-6 text-primary-500">
      <path d="M11.25 5h1.5v15h-1.5V5zM6 10h1.5v10H6V10zm12 4h-1.5v6H18v-6z" />
    </svg>
  );
};

export const AIEnableIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      class="w-6 h-6 text-primary-500">
      <path d="m20.8 10.7-4.3-4.3-1.1 1.1 4.3 4.3c.1.1.1.3 0 .4l-4.3 4.3 1.1 1.1 4.3-4.3c.7-.8.7-1.9 0-2.6zM4.2 11.8l4.3-4.3-1-1-4.3 4.3c-.7.7-.7 1.8 0 2.5l4.3 4.3 1.1-1.1-4.3-4.3c-.2-.1-.2-.3-.1-.4z" />
    </svg>
  );
};

export const CollaborationIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      class="w-6 h-6 text-primary-500">
      <path d="M10 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm1.5 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm2.25 7.5v-1A2.75 2.75 0 0 0 11 8.25H7A2.75 2.75 0 0 0 4.25 11v1h1.5v-1c0-.69.56-1.25 1.25-1.25h4c.69 0 1.25.56 1.25 1.25v1h1.5zM4 20h9v-1.5H4V20zm16-4H4v-1.5h16V16z" />
    </svg>
  );
};

export const TickMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className="mr-2"
      width="20px"
      height="20px">
      <path
        d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"
        fill="blue"
      />
    </svg>
  );
};

export const FeaturesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="mr-2"
      width="30px"
      height="30px">
      <path
        d="M16 10h4c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1zm-8 4H4c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h4c.6 0 1-.4 1-1v-4c0-.6-.4-1-1-1zm10-2.6L14.5 15l1.1 1.1 1.7-1.7c-.1 1.1-.3 2.3-.9 2.9-.3.3-.7.5-1.3.5h-4.5v1.5H15c.9 0 1.7-.3 2.3-.9 1-1 1.3-2.7 1.4-4l1.8 1.8 1.1-1.1-3.6-3.7zM6.8 9.7c.1-1.1.3-2.3.9-2.9.4-.4.8-.6 1.3-.6h4.5V4.8H9c-.9 0-1.7.3-2.3.9-1 1-1.3 2.7-1.4 4L3.5 8l-1 1L6 12.6 9.5 9l-1-1-1.7 1.7z"
        fill="blue"
      />
    </svg>
  );
};

export const CloudIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary"
      fill="currentColor">
      <path d="M17.3 10.1c0-2.5-2.1-4.4-4.8-4.4-2.2 0-4.1 1.4-4.6 3.3h-.2C5.7 9 4 10.7 4 12.8c0 2.1 1.7 3.8 3.7 3.8h9c1.8 0 3.2-1.5 3.2-3.3.1-1.6-1.1-2.9-2.6-3.2zm-.5 5.1h-9c-1.2 0-2.2-1.1-2.2-2.3s1-2.4 2.2-2.4h1.3l.3-1.1c.4-1.3 1.7-2.2 3.2-2.2 1.8 0 3.3 1.3 3.3 2.9v1.3l1.3.2c.8.1 1.4.9 1.4 1.8-.1 1-.9 1.8-1.8 1.8z" />
    </svg>
  );
};

export const ReviewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary"
      fill="currentColor">
      <path
        fillRule="evenodd"
        d="M9.706 8.646a.25.25 0 0 1-.188.137l-4.626.672a.25.25 0 0 0-.139.427l3.348 3.262a.25.25 0 0 1 .072.222l-.79 4.607a.25.25 0 0 0 .362.264l4.138-2.176a.25.25 0 0 1 .233 0l4.137 2.175a.25.25 0 0 0 .363-.263l-.79-4.607a.25.25 0 0 1 .072-.222l3.347-3.262a.25.25 0 0 0-.139-.427l-4.626-.672a.25.25 0 0 1-.188-.137l-2.069-4.192a.25.25 0 0 0-.448 0L9.706 8.646zM12 7.39l-.948 1.921a1.75 1.75 0 0 1-1.317.957l-2.12.308 1.534 1.495c.412.402.6.982.503 1.55l-.362 2.11 1.896-.997a1.75 1.75 0 0 1 1.629 0l1.895.997-.362-2.11a1.75 1.75 0 0 1 .504-1.55l1.533-1.495-2.12-.308a1.75 1.75 0 0 1-1.317-.957L12 7.39z"
      />
    </svg>
  );
};

export const SourceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary"
      fill="currentColor">
      <path d="M18 5.5H6a.5.5 0 0 0-.5.5v3h13V6a.5.5 0 0 0-.5-.5zm.5 5H10v8h8a.5.5 0 0 0 .5-.5v-7.5zm-10 0h-3V18a.5.5 0 0 0 .5.5h2.5v-8zM6 4h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z" />
    </svg>
  );
};

export const SupportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary"
      fill="currentColor">
      <path d="M12 3.3c-4.8 0-8.8 3.9-8.8 8.8 0 4.8 3.9 8.8 8.8 8.8 4.8 0 8.8-3.9 8.8-8.8s-4-8.8-8.8-8.8zm6.5 5.5h-2.6C15.4 7.3 14.8 6 14 5c2 .6 3.6 2 4.5 3.8zm.7 3.2c0 .6-.1 1.2-.2 1.8h-2.9c.1-.6.1-1.2.1-1.8s-.1-1.2-.1-1.8H19c.2.6.2 1.2.2 1.8zM12 18.7c-1-.7-1.8-1.9-2.3-3.5h4.6c-.5 1.6-1.3 2.9-2.3 3.5zm-2.6-4.9c-.1-.6-.1-1.1-.1-1.8 0-.6.1-1.2.1-1.8h5.2c.1.6.1 1.1.1 1.8s-.1 1.2-.1 1.8H9.4zM4.8 12c0-.6.1-1.2.2-1.8h2.9c-.1.6-.1 1.2-.1 1.8 0 .6.1 1.2.1 1.8H5c-.2-.6-.2-1.2-.2-1.8zM12 5.3c1 .7 1.8 1.9 2.3 3.5H9.7c.5-1.6 1.3-2.9 2.3-3.5zM10 5c-.8 1-1.4 2.3-1.8 3.8H5.5C6.4 7 8 5.6 10 5zM5.5 15.3h2.6c.4 1.5 1 2.8 1.8 3.7-1.8-.6-3.5-2-4.4-3.7zM14 19c.8-1 1.4-2.2 1.8-3.7h2.6C17.6 17 16 18.4 14 19z" />
    </svg>
  );
};

export const ResponsiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary mr-4"
      fill="currentColor">
      <path d="M15 4H9c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm.5 14c0 .3-.2.5-.5.5H9c-.3 0-.5-.2-.5-.5V6c0-.3.2-.5.5-.5h6c.3 0 .5.2.5.5v12zm-4.5-.5h2V16h-2v1.5z" />
    </svg>
  );
};

export const CommerceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary mr-4">
      <path
        fillRule="evenodd"
        d="M19.75 11H21V8.667L19.875 4H4.125L3 8.667V11h1.25v8.75h15.5V11zm-1.5 0H5.75v7.25H10V13h4v5.25h4.25V11zm-5.5-5.5h2.067l.486 3.24.028.76H12.75v-4zm-3.567 0h2.067v4H8.669l.028-.76.486-3.24zm7.615 3.1-.464-3.1h2.36l.806 3.345V9.5h-2.668l-.034-.9zM7.666 5.5h-2.36L4.5 8.845V9.5h2.668l.034-.9.464-3.1z"
      />
    </svg>
  );
};

export const ConversionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="h-6 w-6 text-primary mr-4"
      fill="currentColor">
      <path d="m15.1 4.8-3-2.5V4c-4.4 0-8 3.6-8 8 0 3.7 2.5 6.9 6 7.7.3.1.6.1 1 .2l.2-1.5c-.4 0-.7-.1-1.1-.2l-.1.2v-.2c-2.6-.8-4.5-3.3-4.5-6.2 0-3.6 2.9-6.5 6.5-6.5v1.8l3-2.5zM20 11c-.2-1.4-.7-2.7-1.6-3.8l-1.2.8c.7.9 1.1 2 1.3 3.1L20 11zm-1.5 1.8c-.1.5-.2 1.1-.4 1.6s-.5 1-.8 1.5l1.2.9c.4-.5.8-1.1 1-1.8s.5-1.3.5-2l-1.5-.2zm-5.6 5.6.2 1.5c1.4-.2 2.7-.7 3.8-1.6l-.9-1.1c-.9.7-2 1.1-3.1 1.2z" />
    </svg>
  );
};

export const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      class="w-6 h-6 text-gray-400">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        strokeWidth="2"
        d="M19 9l-7 7-7-7"></path>
    </svg>
  );
};

export const AdvancedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-rocket">
      <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path>
      <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path>
      <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path>
      <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path>
    </svg>
  );
};
export const InteractiveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-bookmark-plus">
      <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z"></path>
      <line x1="12" x2="12" y1="7" y2="13"></line>
      <line x1="15" x2="9" y1="10" y2="10"></line>
    </svg>
  );
};

export const VideoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-video">
      <path d="m22 8-6 4 6 4V8Z"></path>
      <rect width="14" height="12" x="2" y="6" rx="2" ry="2"></rect>
    </svg>
  );
};

export const QuizIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-file-question">
      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
      <path d="M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2"></path>
      <path d="M12 17h.01"></path>
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg
      fill="#000000"
      height="18px"
      width="18px"
      version="1.1"
      className="mr-2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.971 384.971">
      <g>
        <path
          d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03
       C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
       C192.485,366.299,187.095,360.91,180.455,360.91z"
        />
        <path
          d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279
       c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
       c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"
        />
      </g>
    </svg>
  );
};

export const NewsfeedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_496)">
        <path
          opacity="0.3"
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7358 20.9962C6.8874 20.8564 3 16.8822 3 12C3 11.9168 3.00113 11.8339 3.00337 11.7513C3.66233 11.8143 4.85637 11.9574 4.91263 12.4204C4.99049 13.0609 4.91263 13.8616 5.45805 14.1018C6.00346 14.342 6.15932 13.1409 6.62675 13.4613C7.09418 13.7816 8.34087 14.0899 8.34087 14.6562C8.34087 15.2224 8.10715 16.1035 8.34087 16.2636C8.57458 16.4238 9.50897 17.5446 9.50921 17.7048C9.50957 17.865 9.83857 18.6794 9.7404 18.9907C9.65905 19.2487 9.24858 20.0502 8.8506 20.4146C9.75315 20.7621 10.7236 20.9658 11.7358 20.9961V20.9962ZM8.28273 3.80112C9.41584 3.28656 10.6745 3 12 3C15.5115 3 18.5532 5.01097 20.0364 7.94408C20.0697 8.72412 20.0638 9.39135 20.2361 9.63274C21.1132 10.8601 18.0995 11.7043 18.5573 13.5605C18.759 14.3795 16.5528 14.1197 16.014 14.8864C15.4748 15.6527 14.1575 15.1378 13.852 14.9905C13.5466 14.8432 12.3766 15.3341 12.4789 14.4995C12.5806 13.6646 13.2923 13.6156 14.0556 13.272C14.8185 12.9287 15.9189 11.7872 15.3782 11.638C12.8323 10.9362 11.9638 8.47852 11.9638 8.47852C11.811 8.44901 11.8494 6.74109 11.1884 6.69207C10.5267 6.6428 10.1705 6.88841 9.20436 6.69207C8.23765 6.49573 8.44144 5.85744 8.28872 4.48256C8.25454 4.17416 8.25619 3.95717 8.28273 3.80112ZM20.9992 11.877C20.9997 11.918 21 11.9589 21 12C21 16.9407 17.0188 20.9515 12.0895 20.9996C16.9702 20.9503 20.9337 16.8884 20.9992 11.877Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_121_496">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TemplatesIcon = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9066 1.22217H6.09383C5.47221 1.22217 4.87605 1.47971 4.43649 1.93813C3.99694 2.39655 3.75 3.01831 3.75 3.66661V10.1851C3.75054 11.7129 4.26557 13.1918 5.20517 14.3639C6.14476 15.5359 7.44922 16.3266 8.89081 16.5977C8.98191 16.6131 9.06485 16.6617 9.12491 16.7347C9.18497 16.8078 9.21829 16.9007 9.21894 16.997V18.7407C9.21894 18.8487 9.17779 18.9524 9.10453 19.0288C9.03127 19.1052 8.93191 19.1481 8.82831 19.1481H7.65639C7.44918 19.1481 7.25046 19.2339 7.10394 19.3867C6.95742 19.5396 6.87511 19.7468 6.87511 19.9629C6.87511 20.179 6.95742 20.3863 7.10394 20.5391C7.25046 20.6919 7.44918 20.7777 7.65639 20.7777H12.3441C12.5513 20.7777 12.75 20.6919 12.8965 20.5391C13.043 20.3863 13.1253 20.179 13.1253 19.9629C13.1253 19.7468 13.043 19.5396 12.8965 19.3867C12.75 19.2339 12.5513 19.1481 12.3441 19.1481H11.1721C11.0685 19.1481 10.9692 19.1052 10.8959 19.0288C10.8227 18.9524 10.7815 18.8487 10.7815 18.7407V16.9888C10.7822 16.8926 10.8155 16.7997 10.8755 16.7266C10.9356 16.6535 11.0185 16.605 11.1096 16.5896C12.5499 16.3187 13.8533 15.5292 14.7928 14.3589C15.7322 13.1885 16.2481 11.7115 16.2504 10.1851V3.66661C16.2504 3.01831 16.0035 2.39655 15.564 1.93813C15.1244 1.47971 14.5282 1.22217 13.9066 1.22217Z"
        fill="white"
      />
      <rect
        opacity="0.5"
        y="3.66663"
        width="2.5"
        height="6.11111"
        rx="1.25"
        fill="white"
      />
      <rect
        opacity="0.5"
        x="17.5"
        y="3.66663"
        width="2.5"
        height="6.11111"
        rx="1.25"
        fill="white"
      />
    </svg>
  );
};

export const DocumentationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_515)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 5H19.5C20.3284 5 21 5.67157 21 6.5C21 7.32843 20.3284 8 19.5 8H10.5C9.67157 8 9 7.32843 9 6.5C9 5.67157 9.67157 5 10.5 5ZM10.5 10H16.5C17.3284 10 18 10.6716 18 11.5C18 12.3284 17.3284 13 16.5 13H10.5C9.67157 13 9 12.3284 9 11.5C9 10.6716 9.67157 10 10.5 10ZM10.5 15H13.5C14.3284 15 15 15.6716 15 16.5C15 17.3284 14.3284 18 13.5 18H10.5C9.67157 18 9 17.3284 9 16.5C9 15.6716 9.67157 15 10.5 15Z"
          fill="white"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 8C4.67157 8 4 7.32843 4 6.5C4 5.67157 4.67157 5 5.5 5C6.32843 5 7 5.67157 7 6.5C7 7.32843 6.32843 8 5.5 8ZM5.5 13C4.67157 13 4 12.3284 4 11.5C4 10.6716 4.67157 10 5.5 10C6.32843 10 7 10.6716 7 11.5C7 12.3284 6.32843 13 5.5 13ZM5.5 18C4.67157 18 4 17.3284 4 16.5C4 15.6716 4.67157 15 5.5 15C6.32843 15 7 15.6716 7 16.5C7 17.3284 6.32843 18 5.5 18Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_121_515">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CommunityIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_121_533)">
        <path
          opacity="0.3"
          d="M18 14C16.3431 14 15 12.6569 15 11C15 9.34315 16.3431 8 18 8C19.6569 8 21 9.34315 21 11C21 12.6569 19.6569 14 18 14ZM9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7C13 9.20914 11.2091 11 9 11Z"
          fill="white"
        />
        <path
          d="M17.6012 15.0006C21.0077 15.0379 23.7892 16.7601 23.9985 20.4C24.0069 20.5466 23.9985 21 23.4559 21H19.6C19.6 18.7491 18.8563 16.6718 17.6012 15.0006ZM0.000651684 20.1992C0.388259 15.4265 4.26191 13 8.98334 13C13.7712 13 17.7049 15.2932 17.9979 20.2C18.0096 20.3955 17.9979 21 17.2467 21C13.5411 21 8.03472 21 0.727502 21C0.476712 21 -0.0204618 20.4592 0.000651684 20.1992Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_121_533">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NotificationIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4998 12C19.3282 12 19.9998 12.6716 19.9998 13.5C19.9998 14.3284 19.3282 15 18.4998 15H5.49982C4.67139 15 3.99982 14.3284 3.99982 13.5C3.99982 12.6716 4.67139 12 5.49982 12L7.55809 6.97553C7.80957 4.71226 9.72261 3 11.9998 3C14.277 3 16.1901 4.71226 16.4415 6.97553L18.4998 12Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18Z"
        fill="white"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.28625 5.31761C2.09948 5.31761 1.91272 5.24291 1.76331 5.0935L0.269192 3.59938C-0.0296313 3.30056 -0.0296313 2.85232 0.269192 2.5535C0.568015 2.25468 1.0536 2.25468 1.31507 2.5535L2.28625 3.52467L4.75154 1.05938C5.05036 0.76056 5.4986 0.76056 5.79742 1.05938C6.09624 1.35821 6.09624 1.80644 5.79742 2.10526L2.80919 5.0935C2.65978 5.24291 2.47301 5.31761 2.28625 5.31761Z"
        fill="white"
      />
    </svg>
  );
};
export const ChevronRightIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10 17L15 12L10 7" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
