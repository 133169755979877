import { fabric } from "fabric";
import { groupBy, pick, uniqueId } from "lodash";
const REGEX_VAR = new RegExp(/`[\s*a-zA-Z0-9-_.@$!%,();:"|&']+?`/g);
const REF_TARGET = {
    NONE: "fill",
    NEON: "stroke",
    HOLLOW: "stroke",
    LIFT: "fill",
    SHADOW: "fill"
};
//@ts-ignore
fabric.Textbox.prototype.lineStyles = {};
//@ts-ignore
// Then customize the size or shape of visible controls
// Example: Customize the rotation control (mtr)
//fabric.Textbox.prototype.controls.cornerShape = 'circle';
fabric.Textbox.prototype.bulletStyleMap = ["", "●", "■", "○", "◦", "•"];
fabric.Text.prototype.initDimensions = function () {
    if (this.__skipDimension) {
        return;
    }
    console.log('Before dimension calculation:', this);
    console.log('Is this object extensible?', Object.isExtensible(this));
    this._splitText();
    this._clearCache();
    // Log after splitting text and clearing cache
    console.log('After splitting text:', this);
    this.width = this.calcTextWidth() + (this._getLineLeftOffset(1) || this.cursorWidth);
    if (this.textAlign.indexOf("justify") !== -1) {
        this.enlargeSpaces();
    }
    this.height = this.calcTextHeight();
    this.saveState({
        propertySet: "_dimensionAffectingProps"
    });
};


/***
 * Custom class inherited from fabric js textbox
 */
export class StaticTextObject extends fabric.Textbox {
    static type = "textbox";
    fontURL = "";
    params = [];
    paramBounds = [];
    effect;
    light;
    lineStyles = {};
    bulletStyle;
    bulletStyleMap = ["","■", "●", "○", "◦", "•"];
    isBulletText;
    isNumberBullet;
    
    /**
     * Extracts parameters from keys in the given text using a predefined regular expression.
     * @param {string} text - The input text to extract parameters from.
     * @returns {Array} - An array of objects representing the extracted parameters.
     */
    getParamsFromKeys(text) {
        let params = [];
        const matches = text.matchAll(REGEX_VAR);
        for (const match of matches) {
            const matchWord = match["0"];
            const startIndex = match["index"];
            params = params.concat({
                key: matchWord,
                startIndex: startIndex,
                endIndex: startIndex + matchWord.length,
                id: uniqueId(matchWord),
                name: matchWord
                    .slice(1, matchWord.length - 1)
                    .toLowerCase()
                    .split(" ")
                    .join("_")
            });
        }
        return params;
    }
   
    /**
     * Registers a hover event listener on the canvas for the current object.
     * Updates the hover cursor based on the mouse position and the object's parameter bounds.
     */
    registerHoverEvent() {
        this.canvas.on("mouse:move", e => {
            if (!this.isEditing && e.target === this) {
                const pointer = this.canvas.getPointer(e.e, false);
                const key = this.paramBounds.find(key => {
                    if (pointer.x >= key.left + this.left &&
                        pointer.x <= this.left + key.left + key.width &&
                        pointer.y >= key.top + this.top &&
                        pointer.y <= this.top + key.top + key.height) {
                        return true;
                    }
                    else {
                        return false;
                    }
                });
                if (key) {
                    this.hoverCursor = "pointer";
                }
                else {
                    this.hoverCursor = "move";
                }
            }
        });
    }
    updateIndentLevel() {
        return this.textLines.map((line) => ({ indentLevel: 2 }));
    }
    /**
     * Initializes the custom text object with specified options.
     * @param {object} options - Options for initializing the custom text object.
     *   @param {string} text - The text content.
     *   @param {Array} params - An array of parameters.
     *   @param {object} styles - Styles for the text.
     *   @param {boolean} isBulletText - Indicates whether the text has bullet points.
     *   @param {object} lineStyles - Styles for the lines in the text.
     *   @param {number} bulletStyle - Style of the bullet points.
     *   @param {object} bulletStyleMap - Map of bullet styles.
     *   @param {boolean} isNumberBullet - Indicates whether the bullet points are numbers.
     *   @param {object} ...textOptions - Additional text options.
     */
    initialize(options) {
        const { text, params, styles, isBulletText, lineStyles, bulletStyle, bulletStyleMap, isNumberBullet, ...textOptions } = options;
        this.params = params ? params : [];
        this.paramBounds = [];
        //@ts-ignore
        super.initialize(text, {
            ...textOptions,
            erasable: false
        });
        this.bulletStyle = bulletStyle || 1;
        this.objectCaching = false;
        this.isBulletText = isBulletText || false;
        this.isNumberBullet = isNumberBullet || false;
        this.lineStyles = lineStyles || this.updateIndentLevel();
        this.styles = styles || {};
        if (styles && Array.isArray(styles)) {
            styles.forEach((style, i) => {
                const prevArrayLetters = text.split("").filter((t, i) => i <= style.start);
                let contPrevLineBreak = 0;
                for (const t of prevArrayLetters) {
                    t === "\n" && contPrevLineBreak++;
                }
                const currentArrayLetters = text.split("").filter((t, i) => i > style.start && i <= style.end && t);
                let contCurrentLineBreak = 0;
                for (const t of currentArrayLetters) {
                    t === "\n" && contCurrentLineBreak++;
                }
                 // Initialize this.styles if undefined or ensure it's extensible
                if (!this.styles) {
                    this.styles = {};
                } else if (!Object.isExtensible(this.styles)) {
                    this.styles = Object.assign({}, this.styles); // Clone to make it extensible
                }

                // Use the line index or `i` (assuming this represents a line of text)
                const lineIndex = i;

                // Initialize this.styles[lineIndex] if undefined or ensure it's extensible
                if (!this.styles[lineIndex]) {
                    this.styles[lineIndex] = {};
                } else if (!Object.isExtensible(this.styles[lineIndex])) {
                    this.styles[lineIndex] = Object.assign({}, this.styles[lineIndex]); // Clone to make it extensible
                }
                this.setSelectionStyles(style.style, style.start + contPrevLineBreak, style.end + contCurrentLineBreak + contPrevLineBreak);
            });
        }
        this.on("added", () => {
            this.registerHoverEvent();
            this.updateParams();
        });
        this.on("editing:entered", () => {
            this.clearStyles();
            // this._initHiddenTextAreaEvents()
        });
        this.on("editing:exited", () => {
            this.updateParams();
            // this._removeHiddenTextAreaEvents()
        });
        this.on("modified", () => {
            this.updateParams();
        });
        // this.on("changed", (e) => this._onChangedText(e));
        this.on("scaling",(e)=>{

        })
        return this;
    }
    _getBulletStyle() {
        // Function logic goes here
        return this.bulletStyle;
    }
    toggleBulletOnText(isNumber=false) {
        let obj = this;
            if (!obj.isBulletText && !obj.isNumberBullet && isNumber) {
                obj.isBulletText = true;
                obj.isNumberBullet = true;
            }
            else if(obj.isNumberBullet && isNumber){
                obj.isNumberBullet = false;
                obj.isBulletText = false;
            }
            else if(!obj.isNumberBullet && obj.isBulletText && isNumber){
                obj.isNumberBullet = true;
            }
            else if (!obj.isBulletText && !obj.isNumberBullet && !isNumber) {
                obj.isBulletText = true;
            }
            else if (obj.isNumberBullet && !isNumber) {
                obj.isBulletText = true;
                obj.isNumberBullet = false;
            }
            else if (obj.isBulletText  && !isNumber) {
                obj.isBulletText = false;
            }

            obj.initDimensions();
            this.canvas?.requestRenderAll();
            // this.canvas.fire("object:modified", { target: obj });

    };

    /**
     * Key  events for text object
     * @private
     */

    _initHiddenTextAreaEvents() {
        // Get the underlying HTML textarea element
        const textarea = this.hiddenTextarea;
        if (textarea) {
            textarea.addEventListener('keyup', this.handleKeyUp);
        }
    }

    /**
     * Unsubscribes the key events
     * @private
     */
    _removeHiddenTextAreaEvents() {
        // Get the underlying HTML textarea element
        const textarea = this.hiddenTextarea;
        if (textarea) {
            textarea.removeEventListener('keyup', this.handleKeyUp);
        }
    }

    /**
     * Handle the scenarios on key up
     * @param e
     * @returns {boolean}
     */
    handleKeyUp(e) {
        e.preventDefault();
        // Perform actions when a key is released
        if (e.keyCode === 9) {
            // Tab key pressed
            var start = this.selectionStart;
            var end = this.selectionEnd;
            var text = this.text;
            // Insert tab space at the cursor position
            this.text = text.slice(0, start) + '\t' + text.slice(end);
            // Move the cursor forward by one position
            this.selectionStart = this.selectionEnd = start + 1;
            // Prevent the default tab behavior
            e.preventDefault();
            return false;
        }
        // Additional logic...
    }

    /**
     * Handle the scenarios on key down
     * @param e
     */
    onKeyDown(e) {
        if (this.isEditing && (e.keyCode === 8 || e.code === "Backspace")) {
            const textSelected = this.getSelectedText();
            const startIndex = this.selectionStart, endIndex = this.selectionEnd;
            let newLineStyles = {}, lineStyles = this.lineStyles;
            if (!textSelected) {
                const { charIndex, lineIndex } = this.get2DCursorLocation(startIndex, true);
                if (charIndex === 0) { //if cursor is in first.
                    for (const key in lineStyles) {
                        const keyOut = parseInt(key);
                        if (lineIndex === keyOut) {
                            newLineStyles[keyOut] = lineStyles[keyOut + 1];
                            for (const key1 in lineStyles) {
                                const keyIn = parseInt(key1);
                                if (keyIn > keyOut) {
                                    newLineStyles[keyIn] = lineStyles[keyIn + 1];
                                }
                            }
                            delete newLineStyles[Object.entries(newLineStyles).length - 1];
                            break;
                        }
                        else
                            newLineStyles[key] = lineStyles[key];
                    }
                    this.lineStyles = newLineStyles;
                    this.dirty = true;
                }
            }
            else {
                const startPosition = this.get2DCursorLocation(startIndex, true);
                const startLineIndex = startPosition.lineIndex;
                const endPosition = this.get2DCursorLocation(endIndex, true);
                const endLineIndex = endPosition.lineIndex;
                for (const key in lineStyles) {
                    const lineKey = parseInt(key);
                    if (lineKey >= startLineIndex + 1 && lineKey <= endLineIndex) {
                        delete lineStyles[lineKey];
                        continue;
                    }
                    if (lineKey > endLineIndex)
                        newLineStyles[Object.entries(newLineStyles).length] = lineStyles[lineKey];
                    else
                        newLineStyles[lineKey] = lineStyles[lineKey];
                }
                this.lineStyles = newLineStyles;
                this.dirty = true;
            }
        }
        if (this.isEditing && (e.keyCode === 13 && e.code === "Enter")) {
            // Get the current line index and the line's indent level
            const cursorLineIndexSkip = this.get2DCursorLocation(this.selectionStart)?.lineIndex;
            const cursorPosition = this.get2DCursorLocation(this.selectionStart, true);
            const currentLineIndex = cursorPosition.lineIndex;
            const currentIndentLevel = this.isLineIndent(cursorLineIndexSkip);
            const nextLineIndex = currentLineIndex + 1;
            let oldLineIndentStyles = { ...this.lineStyles };
            let newLineIndentStyles = {};
            for (const lineKey in oldLineIndentStyles) {
                const key = parseInt(lineKey);
                if (oldLineIndentStyles[key])
                    newLineIndentStyles[key] = oldLineIndentStyles[key];
                if (key === currentLineIndex && currentLineIndex === Object.entries(oldLineIndentStyles).length - 1) {
                    // last line
                    newLineIndentStyles[currentLineIndex + 1] = oldLineIndentStyles[currentLineIndex];
                    break;
                }
                else if (key === nextLineIndex) {
                    newLineIndentStyles[key] = { indentLevel: currentIndentLevel };
                    for (let i = nextLineIndex; i < Object.entries(oldLineIndentStyles).length; i++) {
                        newLineIndentStyles[i + 1] = oldLineIndentStyles[i];
                    }
                    break;
                }
            }
            this.lineStyles = newLineIndentStyles;
            // Notify the canvas to re-render the textbox
            this.dirty = true;
            this.canvas?.requestRenderAll();
            this.initDimensions();
            // Notify the canvas to re-render the textbox
            this.dirty = true;
            this.canvas?.requestRenderAll();
        }
        if (this.isEditing && e.keyCode === 9 && !e.shiftKey) { // Check if Tab key is pressed during editing
            e.preventDefault(); // Prevent the default behavior of the Tab key
            // Get the current line index and the line's indent level
            const cursorPosition = this.get2DCursorLocation(this.selectionStart, true);
            const currentLineIndex = cursorPosition.lineIndex;
            const currentIndentLevel = this.isLineIndent(this.get2DCursorLocation(this.selectionStart)?.lineIndex);
            // Increase the indent level for the current line
            const newIndentLevel = currentIndentLevel + 1; // Increase the indent level as needed
            // Update the line's indent level in the lineStyles object
            this.lineStyles[currentLineIndex] = { indentLevel: newIndentLevel };
            // Notify the canvas to re-render the textbox
            this.dirty = true;
            this.canvas?.requestRenderAll();
            this.initDimensions();
            // Notify the canvas to re-render the textbox
            this.dirty = true;
            this.canvas?.requestRenderAll();
        }
        else if (this.isEditing && (e.shiftKey && e.keyCode === 9)) { // Check if Tab + shift key is pressed during editing
            e.preventDefault(); // Prevent the default behavior of the Tab key
            // Get the current line index and the line's indent level
            const cursorPosition = this.get2DCursorLocation(this.selectionStart, true);
            const currentLineIndex = cursorPosition.lineIndex;
            const currentIndentLevel = this.isLineIndent(this.get2DCursorLocation(this.selectionStart)?.lineIndex);
            // Increase the indent level for the current line
            const newIndentLevel = currentIndentLevel - 1; // Increase the indent level as needed
            if (newIndentLevel === 1)
                return; // means it is at first
            // Update the line's indent level in the lineStyles object
            this.lineStyles[currentLineIndex] = { indentLevel: newIndentLevel };
            // Notify the canvas to re-render the textbox
            this.dirty = true;
            this.canvas?.requestRenderAll();
            this.initDimensions();
            // Notify the canvas to re-render the textbox
            this.dirty = true;
            this.canvas?.requestRenderAll();
        }
        else {
            super.onKeyDown(e); // Handle other key events normally
        }
        this.canvas.fire("object:modified", { target: this });
    }
    /**
     * Bullets adding
     * @param _line
     * @param lineIndex
     * @param desiredWidth
     * @private
     */
    _wrapLine(_line, lineIndex, desiredWidth) {
        var lineWidth = 0, graphemeLines = [], line = [], words = _line.split(this._reSpaceAndTab), word = "", offset = 0, infix = " ", wordWidth = 0, infixWidth = 0, largestWordWidth = 0, lineJustStarted = true, additionalSpace = this._getWidthOfCharSpacing();
        desiredWidth -= this.getIndentSpace(lineIndex);
        for (var i = 0; i < words.length; i++) {
            word = fabric.util.string.graphemeSplit(words[i]);
            wordWidth = this._measureWord(word, lineIndex, offset);
            offset += word.length;
            lineWidth += infixWidth + wordWidth - additionalSpace;
            if (lineWidth >= desiredWidth && !lineJustStarted) {
                graphemeLines.push(line);
                line = [];
                lineWidth = wordWidth;
                lineJustStarted = true;
            }
            if (!lineJustStarted) {
                line.push(infix);
            }
            line = line.concat(word);
            infixWidth = this._measureWord([infix], lineIndex, offset);
            offset++;
            lineJustStarted = false;
            if (wordWidth > largestWordWidth) {
                largestWordWidth = wordWidth;
            }
        }
        i && graphemeLines.push(line);
        if (largestWordWidth + this.getIndentSpace(lineIndex) > this.dynamicMinWidth) {
            this.dynamicMinWidth = largestWordWidth - additionalSpace + this.getIndentSpace(lineIndex);
        }
        return graphemeLines;
    };
    /**
     * Determines the indentation level for a specific line.
     * @param {number} line - The index of the line.
     * @returns {number} - The indentation level for the specified line.
     */
    isLineIndent(line) {
        var lineIndex = this._styleMap && this._styleMap[line] ? this._styleMap[line].line : line;
        var lineStyles = this.lineStyles[lineIndex];
        return lineStyles?.indentLevel || 2;
    };
    /**
     * Calculates the indentation space based on the line index and font size.
     * @param {number} lineIndex - The index of the line.
     * @returns {number} - The calculated indentation space.
     */
    getIndentSpace(lineIndex) {
        if (this.isBulletText) {
            return this.isLineIndent(lineIndex) * this.fontSize;
        }
        else {
            return this.isLineIndent(lineIndex) * this.fontSize / 3;
        }
    };
    /**
     * Calculates the maximum width of the text, considering line widths and indentation spaces.
     * @returns {number} - The maximum width of the text.
     */
    calcTextWidth() {
        var maxWidth = this.getLineWidth(0);
        for (var i = 1, len = this._textLines.length; i < len; i++) {
            var currentLineWidth = this.getLineWidth(i) + this.getIndentSpace(i);
            if (currentLineWidth > maxWidth) {
                maxWidth = currentLineWidth;
            }
        }
        return maxWidth;
    };
    /**
     * Gets the indentation style for a specific line, considering numbering or bullet points.
     * @param {number} line - The index of the line.
     * @param {string} [t] - Optional parameter for the line index from the style map.
     * @returns {string} - The indentation style ("number", "bullet", or "bullet" if not specified).
     */
    getIndentStyle(e, t = undefined) {
        var n = !t && this._styleMap ? this._styleMap[e].line : e, r = this.lineStyles[n];
        return this.isNumberBullet ? "number" : (r ? r.indentStyle || "bullet" : "bullet");
    };
    /**
     * Parses the input value into an integer if it is a string.
     * @param {string | number} value - The value to be parsed.
     * @returns {number} - The parsed integer value.
     */
    _parsedIntVal(value) {
        let val = value;
        if (typeof value === "string")
            val = parseInt(value);
        return val;
    }
    /**
     * Sets the text styles on the canvas context for rendering or measuring text.
     * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
     * @param {object} charStyle - The character style object.
     * @param {boolean} forMeasuring - Indicates whether the styles are for measuring purposes.
     */
    _setTextStyles(ctx, charStyle, forMeasuring) {
        ctx.textBaseline = 'alphabetical';
        if (this.path) {
            switch (this.pathAlign) {
                case 'center':
                    ctx.textBaseline = 'middle';
                    break;
                case 'ascender':
                    ctx.textBaseline = 'top';
                    break;
                case 'descender':
                    ctx.textBaseline = 'bottom';
                    break;
                default:
                    ctx.textBaseline = 'middle';
            }
        }
        ctx.font = this._getFontDeclaration(charStyle, forMeasuring);
    }

    /**
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     */
    _removeShadow(ctx) {
        if (!this.shadow) {
            return;
        }

        ctx.shadowColor = '';
        ctx.shadowBlur = ctx.shadowOffsetX = ctx.shadowOffsetY = 0;
    }
    /**
     * Renders the background of the object on the canvas.
     * Renders the stroke around the object
     * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
     */
    _renderBackground(ctx) {
        if (this.borderWidth > 0 && this.borderStyle !== 'none') {
            var dim = this._getNonTransformedDimensions();
            ctx.lineWidth = this.borderWidth;
            ctx.strokeStyle = this.borderFill;
            switch (this.borderStyle) {
                case 'solid':
                    ctx.setLineDash([]);
                    break;
                case 'dashed':
                    ctx.setLineDash([this.borderWidth * 10, this.borderWidth * 10]);
                    break;
                case 'dotted':
                    ctx.setLineDash([this.borderWidth, this.borderWidth * 2]);
                    break;
                case 'double':
                    ctx.setLineDash([this.borderWidth * 20, this.borderWidth * 3, this.borderWidth * 3, this.borderWidth * 3, this.borderWidth * 3, this.borderWidth * 3, this.borderWidth * 3, this.borderWidth * 3]);
                    break;
                default:
                    break;
            }

            // // Calculate the coordinates for the border rectangle
            var borderX = -dim.x / 2;
            var borderY = -dim.y / 2;
            var borderWidth = dim.x;
            var borderHeight = dim.y;


            // Draw the border rectangle
            ctx.strokeRect(borderX, borderY, borderWidth, borderHeight);
        }
        if (!this.backgroundColor) {
            return;
        }
        // Draw the filled background rectangle
        ctx.fillRect(
            -dim.x / 2,
            -dim.y / 2,
            dim.x,
            dim.y
        );



        // if there is a background color, no other shadows should be casted
        this._removeShadow(ctx);
    }


    /**
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     */

    _getPrevItemsCount(currLineIndex, currIndentLevel, lineStyles) {
        let count = 0;
        if (!currIndentLevel)
            return;
        const keys = Object.keys(lineStyles).slice(0, currLineIndex).reverse();
        for (let i = 0; i < keys?.length; i++) {
            const currentStyleInd = parseInt(keys[i]);
            const currentStyleIndent = lineStyles[currentStyleInd].indentLevel;
            if (currentStyleIndent + 1 === currIndentLevel)
                break;
            if (currentStyleIndent === currIndentLevel)
                count++;
        }
        return count;
    }
    /**
     * Converts a decimal number to a Roman numeral.
     * @param {number} num - The decimal number to convert.
     * @returns {string} - The Roman numeral representation of the input number.
     */
    convertToRoman(num) {
        const romanNumerals = {
            m: 1000,
            cm: 900,
            d: 500,
            cd: 400,
            c: 100,
            xc: 90,
            l: 50,
            xl: 40,
            x: 10,
            ix: 9,
            v: 5,
            iv: 4,
            i: 1,
        };
        let result = "";
        for (let key in romanNumerals) {
            while (num >= romanNumerals[key]) {
                result += key;
                num -= romanNumerals[key];
            }
        }
        return result;
    }
    /**
     * Converts a decimal number to an alphabetical representation.
     * @param {number} num - The decimal number to convert.
     * @returns {string} - The alphabetical representation of the input number.
     */
    convertToApha(num) {
        const englishAlphabets = [
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
            'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
            'u', 'v', 'w', 'x', 'y', 'z'
        ];
        let result = "";
        while (num > 0) {
            const index = (num - 1) % 26;
            result = englishAlphabets[index] + result;
            num = Math.floor((num - 1) / 26);
        }
        return result;
    }
    /**
     * Generates a numbered or alphabetic bullet text based on the line index and indent level.
     * @param {number} lineIndex - The index of the current line.
     * @returns {string} - The generated bullet text.
     */
    _getNumberBullet(lineIndex) {
        let lineStyles = { ...this.lineStyles };
        if (!lineIndex)
            return "1";
        let currentLineIndentLevel, prevItemsCount = 0;
        let bulletNumberText = "";
        currentLineIndentLevel = lineStyles[lineIndex]?.indentLevel;
        prevItemsCount = this._getPrevItemsCount(lineIndex, currentLineIndentLevel, lineStyles);
        if ([2, 5, 8, 11].includes(currentLineIndentLevel)) {
            bulletNumberText = `${this._parsedIntVal(prevItemsCount) + 1}`;
        }
        if ([3, 6, 9, 12].includes(currentLineIndentLevel)) {
            bulletNumberText = `${this.convertToApha((this._parsedIntVal(prevItemsCount) || 0) + 1)}`;
        }
        if ([4, 7, 10, 13].includes(currentLineIndentLevel)) {
            bulletNumberText = `${this.convertToRoman((this._parsedIntVal(prevItemsCount) || 0) + 1)}`;
        }
        return bulletNumberText;
    }
    /**
     * Gets the updated line index based on the current text lines and style group index.
     * @param {number} lineIndex - The index of the current line.
     * @returns {number} - The updated line index.
     */
    getUpdatedLineIndex(lineIndex) {
        const textLines = this.getUpdatedTextLines();
        const currLine = textLines?.find(t => t.lineIndex === lineIndex);
        return currLine?.textStyleGroupIndex || lineIndex;
    }
    /**
     * Gets the bullet text for a specific line based on the line index and style settings.
     * @param {number} lineIndex - The index of the current line.
     * @returns {object} - An object containing the bullet text and new font size.
     */
    getLineBulletText(lineIndex) {
        const styleInd = this.bulletStyle;
        if (!styleInd)
            return { text: "" };
        const newIndex = this.getUpdatedLineIndex(lineIndex);
        if (newIndex === -1)
            return { text: "" };
        const currStyle = this.styles[lineIndex];
        const newFontSize = currStyle ? currStyle[0]?.fontSize : this.fontSize;
        const style = this.getIndentStyle(newIndex);
        if (0 !== lineIndex && this._styleMap[lineIndex].line === this._styleMap[lineIndex - 1].line)
            return { text: "" };
        switch (style) {
            case "number":
                return { text: this._getNumberBullet(newIndex), newFontSize };
            case "bullet":
                if (this.isBulletText) {
                    return { text: this.bulletStyleMap[styleInd], newFontSize };
                }
                else {
                    return { text: this.bulletStyleMap[0], newFontSize };
                }
            default:
                return { text: "" };
        }
    };
    /**
     * Calculates the left offset for rendering a text line based on the line index and alignment.
     * @param {number} lineIndex - The index of the current line.
     * @returns {number} - The calculated left offset for rendering the text line.
     */
    _getLineLeftOffset(lineIndex) {
        var indentSpace = this.getIndentSpace(lineIndex), lineWidth = this.getLineWidth(lineIndex) + indentSpace;
        return "center" === this.textAlign ? (this.width - lineWidth) / 2 + indentSpace : "right" === this.textAlign ? this.width - lineWidth + indentSpace : indentSpace;
    };
    /**
     * Renders a text line on the canvas, including bullet text if applicable.
     * @param {function} method - The rendering method to use.
     * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
     * @param {string} line - The text content of the line.
     * @param {number} left - The left offset for rendering the line.
     * @param {number} top - The top offset for rendering the line.
     * @param {number} lineIndex - The index of the current line.
     */
    _renderTextLine(method, ctx, line, left, top, lineIndex) {
        //@ts-ignore
        this.callSuper("_renderTextLine", method, ctx, line, left, top, lineIndex);
        const { text } = this.getLineBulletText(lineIndex);
        if (text) {
            top -= this.fontSize * this._fontSizeFraction;
            left = this.isBulletText ? left - (this.fontSize * 1.5) : left ;
            this._renderChar(method, ctx, lineIndex, 0, this.isNumberBullet ? text + "." : text, left, top);
        }
    };
    /**
     * Handles the mouse-up event for the object, triggering a custom event if a parameter is clicked.
     * @param {Event} e - The mouse-up event.
     */
    handleMouseUp(e) {
        if (!this.isEditing && this.canvas) {
            const pointer = this.canvas.getPointer(e.e, false);
            const param = this.paramBounds.find(param => {
                if (pointer.x >= param.left + this.left &&
                    pointer.x <= this.left + param.left + param.width &&
                    pointer.y >= param.top + this.top &&
                    pointer.y <= this.top + param.top + param.height) {
                    return true;
                }
                else {
                    return false;
                }
            });
            if (param) {
                const zoom = this.canvas.getZoom();
                const { scaleX, scaleY, width, height } = this;
                const { left, top } = this.getBoundingRect(false);
                const padLeft = (width * scaleX * zoom - width) / 2;
                const padTop = (height * scaleY * zoom - height) / 2;
                const eventData = {
                    object: this,
                    position: {
                        left: left + padLeft + param.width * zoom + param.left,
                        top: top + padTop + param.height * zoom + param.top
                    },
                    param
                };
                this.canvas.fire("param:selected", eventData);
            }
        }
    }
    /**
     * Updates the name of a parameter in the object's parameters and triggers a params update.
     * @param {string} key - The key of the parameter to update.
     * @param {string} name - The new name for the parameter.
     */
    updateParam(key, name) {
        this.params = this.params.map(p => {
            if (p.key === key) {
                return {
                    ...p,
                    name
                };
            }
            return p;
        });
        this.updateParams();
    }
    /**
     * Sets and updates the parameters based on the text content.
     * Also sets selection styles for each parameter.
     */
    setParams() {
        const params = this.getParamsFromKeys(this.text);
        if (this.params) {
            const updatedParams = params.map(param => {
                const existingParam = this.params.find(p => p.key === param.key);
                if (existingParam) {
                    return {
                        ...param,
                        name: existingParam.name
                    };
                }
                return param;
            });
            this.params = updatedParams;
        }
        else {
            this.params = params;
        }
        this.params.forEach(param => {
            this.setSelectionStyles({
                textBackgroundColor: "#dcdde1",
                key: param.key,
                id: param.id,
                name: param.name
            }, param.startIndex, param.endIndex);
        });
    }
    /**
     * Sets parameter bounds after a delay to allow for text layout calculations.
     */
    setParamBounds() {
        setTimeout(() => {
            let textLines = this.getUpdatedTextLines();
            let paramBounds = [];
            textLines.forEach(textLine => {
                const lineHeight = this.__lineHeights[parseInt(textLine.textStyleGroupIndex)];
                const params = this.getKeysFromTextStyles(textLine.lineStyles);
                const linekeyBounds = params.map(param => {
                    const charBounds = this.__charBounds[textLine.lineIndex].map(cbs => ({
                        ...cbs,
                        top: lineHeight * textLine.lineIndex
                    }));
                    const charBoundMin = charBounds[param.startIndex - textLine.startIndex];
                    const charBoundMax = charBounds[param.endIndex - 1 - textLine.startIndex];
                    if (!charBoundMin || !charBoundMax) {
                        return {};
                    }
                    const lineWidth = this.__lineWidths[textLine.lineIndex];
                    const width = this.width;
                    let shift = 0;
                    if (this.textAlign === "center") {
                        shift = (width - lineWidth) / 2;
                    }
                    else if (this.textAlign === "right") {
                        shift = width - lineWidth;
                    }
                    const charBound = {
                        ...charBoundMin,
                        ...param,
                        shift,
                        left: shift + charBoundMin.left,
                        top: charBoundMin.top,
                        width: charBoundMax.width + charBoundMax.left - charBoundMin.left,
                        height: charBoundMin.height
                    };
                    return charBound;
                });
                paramBounds = paramBounds.concat(linekeyBounds);
            });
            this.paramBounds = paramBounds;
        }, 250);
    }
    /**
     * Extracts keys and styles from text styles and groups them by ID.
     * @param {object} textSyles - The text styles for a line.
     * @returns {Array} - An array of parameters extracted from text styles.
     */
    getKeysFromTextStyles(textSyles) {
        let charStyles = [];
        let params = [];
        Object.keys(textSyles).forEach(style => {
            if (textSyles[style].key) {
                charStyles = charStyles.concat({
                    index: parseInt(style),
                    key: textSyles[style].key,
                    id: textSyles[style].id,
                    name: textSyles[style].name
                });
            }
        });
        const groupedCharStyles = groupBy(charStyles, "id");
        Object.keys(groupedCharStyles).forEach(group => {
            const size = groupedCharStyles[group].length;
            const key = groupedCharStyles[group][0].key;
            const name = groupedCharStyles[group][0].name;
            const indexes = groupedCharStyles[group].map(g => g.index).sort((a, b) => a - b);
            const [startIndex] = [indexes[0]];
            const param = {
                key,
                startIndex,
                name,
                endIndex: startIndex + size,
                id: group
            };
            params = params.concat(param);
        });
        return params;
    }
    /**
     * Update text lines normalizing text and adding styles by text line
     */
    getUpdatedTextLines() {
        let allText = this.text;
        const textLines = this.textLines;
        let updatedTextLines = [];
        let textStyleGroupIndex = 0;
        let startIndex = 0;
        let lineIndex = 0;
        textLines.forEach((textLine, index) => {
            let currentTextLine = textLine;
            let isBreakLine = false;
            lineIndex = index;
            const prevUpdatedLine = updatedTextLines[index - 1];
            if (allText[0] === "\n") {
                allText = allText.substring(1);
                textStyleGroupIndex += 1;
                if (index) {
                    prevUpdatedLine.breakLine = true;
                }
            }
            else {
                const textLineChange = index ? " " : "";
                currentTextLine = textLineChange + currentTextLine;
            }
            const initialPart = allText.substring(0, currentTextLine.length);
            const remainingPart = allText.substring(currentTextLine.length);
            if (index) {
                if (prevUpdatedLine.breakLine) {
                    startIndex = 0;
                }
                else {
                    startIndex = prevUpdatedLine.startIndex + prevUpdatedLine.text.length + 1;
                }
            }
            allText = remainingPart;
            updatedTextLines = updatedTextLines.concat({
                text: initialPart,
                breakLine: isBreakLine,
                textStyleGroupIndex,
                startIndex,
                lineIndex: lineIndex,
                initialText: textLine
            });
        });
        const textStyleGroups = this.styles;
        const updatedTextLinesWithStyles = updatedTextLines.map(updatedTextLine => {
            const textStyleGroup = textStyleGroups[updatedTextLine.textStyleGroupIndex];
            const indexes = Array(updatedTextLine.text.length)
                .fill(0)
                .map((_, i) => (updatedTextLine.startIndex + i).toString());
            const lineStyles = pick(textStyleGroup, indexes);
            return { ...updatedTextLine, lineStyles };
        });
        return updatedTextLinesWithStyles;
    }
    /**
     * Clears all selection styles applied to the text by resetting background colors.
     */
    clearStyles() {
        const styleGroups = this.styles;
        Object.keys(styleGroups).forEach(key => {
            const styleGroup = styleGroups[key];
            const styleGroupArray = Object.keys(styleGroup).map(k => ({ ...styleGroup[k], startIndex: parseInt(k) }));
            const groupedById = groupBy(styleGroupArray, "id");
            Object.keys(groupedById).forEach(k => {
                if (k !== "undefined" && k !== "null") {
                    const group = groupedById[k];
                    const startIndex = group[0].startIndex;
                    const endIndex = startIndex + group.length;
                    this.setSelectionStart(startIndex);
                    this.setSelectionEnd(endIndex);
                    this.setSelectionStyles({
                        textBackgroundColor: "",
                        key: null,
                        id: null,
                        name: null
                    }, startIndex, endIndex);
                }
            });
        });
    }
    /**
     * Updates parameters by clearing existing styles, setting new parameters, and updating parameter bounds.
     */
    updateParams() {
        this.clearStyles();
        this.setParams();
        this.setParamBounds();
    }
    /**
     * Gets the reference color based on the effect, returning the fill color if no effect is applied.
     * @returns {string} - The reference color.
     */
    getRefColor() {
        if (!this.effect || this.effect === "NONE") {
            return this.fill;
        }
        // @ts-ignore
        return this[REF_TARGET[this.effect]];
    }
    /**
     * Overrides the fabric.js toObject method to include additional properties specific to this class.
     * @param {Array} propertiesToInclude - An array of properties to include in the output.
     * @returns {object} - The object representation of the instance.
     */
    toObject(propertiesToInclude = []) {
        return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
            fontURL: this.fontURL,
            params: this.params.map(p => ({ key: p.key, name: p.name })),
            effect: this.effect,
            light: this.light,
            lineStyles: this.lineStyles,
            bulletStyle: this.bulletStyle,
            bulletStyleMap: this.bulletStyleMap,
            isBulletText: this.isBulletText,
            isNumberBullet: this.isNumberBullet,
        });
    }
    /**
     * Overrides the fabric.js toJSON method to include additional properties specific to this class.
     * @param {Array} propertiesToInclude - An array of properties to include in the output.
     * @returns {string} - The JSON representation of the instance.
     */
    toJSON(propertiesToInclude = []) {
        return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
            fontURL: this.fontURL,
            params: this.params.map(p => ({ key: p.key, name: p.name })),
            effect: this.effect,
            light: this.light,
            lineStyles: this.lineStyles,
            bulletStyle: this.bulletStyle,
            bulletStyleMap: this.bulletStyleMap,
            isBulletText: this.isBulletText,
            isNumberBullet: this.isNumberBullet,
        });
    }
    static fromObject(options, callback) {
        return callback && callback(new fabric.StaticText(options));
    }
}
fabric.StaticText = fabric.util.createClass(StaticTextObject, {
    type: StaticTextObject.type
});
fabric.StaticText.fromObject = StaticTextObject.fromObject;
fabric.StaticText.prototype._getBulletStyle = function () {
    if (!this.isBulletText)
        return;
    return this.bulletStyle;
};
