// eslint-disable-next-line
import { fabric } from "fabric";
//import { v4 as uuidv4 } from "uuid";


export const getScormIndexString = (
    title = "Reveal",
    slideListData,
    isPreview,
    fontsUrl
) => {
    const slideDataScript = `
        <script>
            const slideData = ${JSON.stringify(slideListData)};
        </script>
    `;

    return `
            <!doctype html>
            <html lang="en">
            <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no">
                <title>${title}</title>
                <link rel="stylesheet" href="assets/css/reset.min.css">
                <link rel="stylesheet" href="assets/css/reveal.min.css">
                <link rel="stylesheet" href="assets/css/black.min.css">
                <link rel="stylesheet" href="${fontsUrl}">
                
                <!-- Necessary scripts for fabric -->
                <script src="https://cdnjs.cloudflare.com/ajax/libs/fabric.js/4.5.0/fabric.min.js"></script>
                <!-- Link to reveal.js CSS -->
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/reveal.min.css">
                <!-- Link SCROM API when on preview -->
                <script src="
                https://cdn.jsdelivr.net/npm/pipwerks-scorm-api-wrapper@0.1.2/index.min.js
                "></script>

                <!-- Theme for the presentation -->
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/theme/white.min.css">

                <!-- Necessary scripts -->

                <script src="https://cdn.jsdelivr.net/npm/reveal.js@5.1.0/dist/reveal.min.js"></script>
                <script src="https://cdn.jsdelivr.net/npm/reveal.js@4.2.0/dist/notes.min.js"></script>
                <style>
                    #slides-container{
                        pointer-events: auto !important;
                    }
                    input:focus-visible {
                        outline: none;
                    }
                    .reveal section canvas {
                        border: 1px solid #000;  /* Customize border width and color */
                        border-radius: 5px;      /* Optional rounded corners */
                    }
                </style>
            </head>
            <body>
            <div class="reveal">
                <div class="slides" id="slides-container">
                    <!-- Dynamically generated sections -->
                </div>
            </div>
            
            ${slideDataScript}
            
            <script>
            // Define the scaling factor from the editor
            const editorScaleFactor = 1; // Example value; dynamically fetch if necessary

            // Define reference boundingRect dimensions after initialization of the first slide
            let referenceBoundingRect = null;

            slideData.forEach(slide => {
                const boundingRect = slide.objects.find(obj => obj.id === 'boundingRect');

                if (boundingRect) {
                    const { fabricCanvas, section } = createFabricCanvas(slide.id, boundingRect);

                    if (!referenceBoundingRect) {
                        referenceBoundingRect = { width: boundingRect.width, height: boundingRect.height };
                    }

                    const scaleX = (referenceBoundingRect.width / boundingRect.width) * editorScaleFactor;
                    const scaleY = (referenceBoundingRect.height / boundingRect.height) * editorScaleFactor;

                    // Load and scale each object
                    const parsedCanvasJSON = slide.objects.filter(obj => obj.id !== 'boundingRect');
                    parsedCanvasJSON.forEach(object => {
                        object.left *= scaleX;
                        object.top *= scaleY;
                        object.width *= scaleX;
                        object.height *= scaleY;
                        object.selectable = false;
                        object.evented = false;
                        if (object.scaleX) object.scaleX *= scaleX;
                        if (object.scaleY) object.scaleY *= scaleY;

                        if (object.type === 'textbox') {
                            const textbox = new fabric.Textbox(object.text, {
                                fontFamily: object.fontFamily,
                                ...object
                            });
                            textbox.set({ selectable: false, evented: false });
                            fabricCanvas.add(textbox);
                        } else if (object.type === 'image') {
                            const imagePath = object.src;
                            fabric.Image.fromURL(imagePath, (fabricImageInstance) => {
                                fabricImageInstance.set({
                                    top: object.top,
                                    left: object.left,
                                    scaleX: object.scaleX,
                                    scaleY: object.scaleY,
                                    angle: object.angle,
                                    originX: object.originX,
                                    originY: object.originY,
                                    selectable: false,
                                    evented: false
                                });
                                fabricCanvas.add(fabricImageInstance);
                            });
                        } else if (object.type === 'polygon') {
                            const polygon = new fabric.Polygon(object.points, {...object});
                            // setAnimation(polygon, fabricCanvas);
                            fabricCanvas.add(polygon);
                        } else if (object.type === 'line') {
                            const line = new fabric.Line(object.points, {...object});
                            // setAnimation(line, fabricCanvas);
                            fabricCanvas.add(line);
                        } else if (object.type === 'circle') {
                            const circle = new fabric.Circle({...object});
                            // setAnimation(circle, fabricCanvas);
                            fabricCanvas.add(circle);
                        } else {
                            console.warn("Unsupported shape type: " + object.type);
                        }
                    });
                }
            });

            // Function to create Fabric canvas for a given slide using boundingRect dimensions
            function createFabricCanvas(slideId, boundingRect) {
                const canvasId = 'canvas-' + slideId;
                const section = document.createElement('section');
                section.setAttribute('data-id', slideId);

                const canvas = document.createElement('canvas');
                canvas.id = canvasId;
                section.appendChild(canvas);

                document.getElementById('slides-container').appendChild(section);

                if (!referenceBoundingRect) {
                    referenceBoundingRect = { width: boundingRect.width, height: boundingRect.height };
                }

                return {
                    fabricCanvas: new fabric.Canvas(canvasId, {
                        backgroundColor: 'white',
                        height: boundingRect.height * editorScaleFactor,
                        width: boundingRect.width * editorScaleFactor,
                        selection: false,
                        isDrawingMode: false,
                        evented: false,
                    }),
                    section
                };
            }

            function initializeReveal() {
                if (typeof Reveal !== 'undefined') {
                    if (referenceBoundingRect) {
                        Reveal.initialize({
                            hash: true,
                            width: referenceBoundingRect.width * editorScaleFactor,
                            height: referenceBoundingRect.height * editorScaleFactor,
                            minScale: 1,
                            maxScale: 1,
                            plugins: []
                        });
                        console.log('Reveal.js initialized with dimensions:', referenceBoundingRect.width, referenceBoundingRect.height);
                    } else {
                        console.warn('Reference boundingRect dimensions not found.');
                    }
                } else {
                    console.warn('Reveal.js is not loaded');
                }
            }

            window.addEventListener('load', initializeReveal);
        </script>




            </body>
            </html>
`;
};


