import {createSlice} from '@reduxjs/toolkit'

const fabricCanvas = createSlice({
    name: 'fabricCanvas',
    initialState: {
        canvas: null,
        activeObject: null,
        globalFontSize:null,
        activePanel: '',
        fontList: [],
        clipBoard: null,
        isContext: false,
        videoCoords:{x:0,y:0},
        ctxCoords: {left: 0, top: 0},
        linkPopupStatus: false,
        linkPopupCoords: {x: 0, y: 0},
        jumpToSlidePopupStatus: false,
        jumpToSlidePopupCoords: {x: 0, y: 0},
        isPlaying:false,
        videoControl:false
    },
    reducers: {
        setActiveCanvas: (state, action) => {
            state.canvas = action.payload;
        },
        setActiveObject: (state, action) => {
            state.activeObject = action.payload;
        },
        setFontList: (state, action) => {
            state.fontList = action.payload;
        },
        setContext: (state, action) => {
            state.isContext = action.payload;
        },
        setCtxCoords: (state, action) => {
            state.ctxCoords = action.payload;
        },
        setVideoCoords: (state, action) => {
            state.videoCoords = action.payload;
        },
        setClipBoard: (state, action) => {
            state.clipBoard = action.payload
        },
        clearClipBoard: (state) => {
            state.clipBoard = null
        },
        setLinkPopupStatus: (state, action) => {
            state.linkPopupStatus = action.payload;
        },
        setLinkPopupCoords: (state, action) => {
            state.linkPopupCoords = action.payload;
        },
        setGlobalFontSize: (state, action) => {
            state.globalFontSize = action.payload;
        },
        setJumpToSlidePopupStatus: (state, action) => {
            state.jumpToSlidePopupStatus = action.payload;
        },
        setJumpToSlidePopupCoords: (state, action) => {
            state.jumpToSlidePopupCoords = action.payload;
        },
        setVideoPlay: (state, action) =>{
            state.isPlaying = action.payload
        },
        setVideoControl:(state, action) =>{
            state.videoControl = action.payload
        }
    }
});

// Selector to access the fontList from the state
export const selectFontList = (state) => state.fabricCanvas.fontList;

export const {
    setActiveCanvas,
    setVideoPlay,
    setVideoControl,
    setActiveObject,
    setFontList,
    setContext,
    setCtxCoords,
    setClipBoard,
    clearClipBoard,
    setLinkPopupStatus,
    setGlobalFontSize,
    setLinkPopupCoords,
    setJumpToSlidePopupStatus,
    setJumpToSlidePopupCoords,
    setVideoCoords
} = fabricCanvas.actions;
export default fabricCanvas.reducer;