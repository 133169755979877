import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { useAuth } from '../../context/AuthContext';
import { useParams } from 'react-router';

const ProjectHistory = ({ userId }) => {
  const [historyData, setHistoryData] = useState([]);
  const bucketName = 'slidesdeck';
  const { user } = useAuth();
  const { projectId } = useParams();

  console.log('projectId', projectId);

  useEffect(() => {
     // Configure AWS SDK
     AWS.config.update({
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });
    const s3 = new AWS.S3();

    const fetchHistoryData = async () => {
      try {
        const params = {
          Bucket: bucketName, // Ensure this is the correct bucket name
          Prefix: `${user?.user_id}/versions/`,
        };

        const data = await s3.listObjectsV2(params).promise();
       
        const versions = await Promise.all(
          data.Contents
          .filter(item => item.Key.includes(projectId)) // Filter by projectId
          .map(async (item) => {
            const objectData = await s3.getObject({
              Bucket: bucketName, // Use the correct bucket name here
              Key: item.Key,
            }).promise();
            
            
            const parsedData = JSON.parse(objectData.Body.toString('utf-8'));
            const versionNumber = item.Key.match(/version-(\d+)\.json$/)[1]; // Extract version number from Key
            // Generate signed URL
            const signedUrl = s3.getSignedUrl('getObject', {
              Bucket: bucketName,
              Key: item.Key,
              Expires: 60 * 60, // URL expires in 1 hour
            });
            
            

            return {
              ...parsedData,
              version: versionNumber,
              createdAt: item.LastModified,
              size: item.Size,
              signedUrl,
            };
          })
        );

        setHistoryData(versions);
      } catch (error) {
        console.error('Error fetching history data:', error);
      }
    };

    fetchHistoryData();
  }, [projectId, user?.user_id]);

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <main className="flex-1 bg-custom-skyBlue p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">Project History</h3>
            <div className="relative overflow-x-auto">
              {historyData.length === 0 ? (
                <p className="text-center text-gray-700">No history available.</p>
              ) : (
                <table className="w-full text-sm border border-gray-300 text-left text-white dark:text-white">
                  <thead className="text-xs text-white uppercase bg-custom-blue">
                    <tr>
                      <th scope="col" className="px-6 py-3">Version</th>
                      <th scope="col" className="px-6 py-3">Slides JSON</th>
                      <th scope="col" className="px-6 py-3">Date</th>
                      <th scope="col" className="px-6 py-3">Changes</th>
                      <th scope="col" className="px-6 py-3">Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((entry, index) => {
                      return (
                        <tr
                          key={`${entry.version}-${index}`} // Ensure unique key by combining version and index
                          className={`bg-white border-b text-black ${index % 2 === 0 ? 'bg-light-blue-100' : ''}`}
                        >
                          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis dark:text-black max-w-xs">
                            {entry.version}
                          </td>
                          <td className="px-6 py-4">
                            <a href={entry.signedUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                              LINK
                            </a>
                          </td>
                          <td className="px-6 py-4">{new Date(entry.createdAt).toLocaleString()}</td>
                          <td className="px-6 py-4">{entry.changes || 'N/A'}</td>
                          <td className="px-6 py-4">{entry.size} bytes</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ProjectHistory;
