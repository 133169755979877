import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { publicApi } from '../../api/publicApi';
import {DataContext} from './DataContext';

function LMSLogin({ onEmailSubmit = () => {} }) {
  const { organizationName } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const otpInputRef = useRef(null); // Create a ref for the OTP input
  const { data, updateUser} = useContext(DataContext);
  


  const handleSendOtp = async (e) => {
    e.preventDefault();

    if (!email) {
      console.log("Please provide an email");
      setInfoMessage("Please provide an email");
      return;
    }

    try {
      const response = await publicApi.post('/api/auth-otp/generate', {
        email: email,
        domain: data?.[0]?.attributes?.Domain
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const responseData = response; // Rename this variable

      if (responseData.message && responseData.message.includes('successfully')) {
        onEmailSubmit(email);
        console.log(responseData.message);
        setInfoMessage(responseData.message);
        setOtpSent(true); // Set OTP sent flag to true

        // Focus the OTP input field
        if (otpInputRef.current) {
          otpInputRef.current.focus();
        }
      } else {
        console.log(responseData.message || 'Failed to send OTP');
        setInfoMessage(responseData.message || 'Failed to send OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      setInfoMessage('Error sending OTP. Please try again later.');
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();


    try {
      console.log("Organization ID:", data?.[0]?.id)
      const response = await publicApi.post('/api/auth-otp/verify', {
        email: email,
        otp: otp,
        organizationId: data?.[0]?.id ,// Use the accessed organizationId
       
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const responseData = response; // Rename this variable
      console.log("Response Data:", responseData);

      if (responseData.message && responseData.message.includes('verified')) {
        console.log(responseData.message);
        setInfoMessage(responseData.message);

        // Store a simple flag in local storage
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('email', email); 
        updateUser({
          email: email,
        });

        // Redirect to the courses page
        navigate(`/lms/${organizationName}/courses`);
      } else {
        console.log(responseData.message || 'Failed to verify OTP');
        setInfoMessage(responseData.message || 'Failed to verify OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      setInfoMessage('Error verifying OTP. Please try again later.');
    }
  };
  useEffect(() => {
    // Check if the user is already logged in
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    if (isLoggedIn === 'true') {
      // Redirect to the courses page
      navigate(`/lms/${organizationName}/courses`);
    }
  }, [navigate, organizationName]);

  useEffect(() => {
    if (otpSent && otpInputRef.current) {
      otpInputRef.current.focus();
    }
  }, [otpSent]); // This effect runs only when otpSent changes

  return (
    <div
      className="flex items-center min-h-screen p-6"
      style={{ backgroundColor: data?.[0]?.attributes?.PrimaryColor || '#f0f0f0' }}
    >
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h2 className="mb-4 text-xl font-semibold text-gray-700">Enter your email</h2>
              <form onSubmit={otpSent ? handleVerifyOtp : handleSendOtp}>
                <div className="block text-sm">
                  <span className="text-gray-700">Email</span>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={otpSent}
                    className="block w-full border-2 border-gray-300 rounded-md mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                  />
                </div>
                {infoMessage && (
                  <div className="mt-2 text-sm text-gray-600">
                    {infoMessage}
                  </div>
                )}
                {otpSent && (
                  <div className="block mt-4 text-sm">
                    <span className="text-gray-700">OTP Code</span>
                    <input
                      type="text"
                      placeholder="Enter OTP code"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                      ref={otpInputRef} // Attach the ref to the OTP input
                      className="block w-full border-2 border-gray-300 rounded-md mt-1 text-sm focus:border-purple-400 focus:outline-none focus:shadow-outline-purple form-input"
                    />
                  </div>
                )}
                <button
                  type="submit"
                  className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150"
                  style={{ backgroundColor: data?.[0]?.attributes?.SecondaryColor || '#6b46c1' }}
                >
                  {otpSent ? 'Verify OTP' : 'Send OTP'}
                </button>
              </form>
            </div>
          </div>
          <div className="flex items-center justify-center md:w-1/2">
            <img
              src={data?.[0]?.attributes?.Logo || 'https://placehold.co/400'}
              alt="Organization Logo"
              className="mb-4 max-h-[200px] p-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LMSLogin;
