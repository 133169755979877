import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Timeline } from '@xzdarcy/react-timeline-editor';
import { useSelector } from 'react-redux';

import { CustomRender } from './Cutsom.jsx';
import TimelinePlayer from './player';

import './index.css';
import { shallowEqual } from 'react-redux';
import lottieControl from './lottieControl.js';
import audioControl from './audioControl.js';
import {
  setVideoControl,
} from '../../store/reducers/canvasSlice.js';
import { useDispatch } from 'react-redux';

const scaleWidth = 160;
const scale = 5;
const startLeft = 5;

const TimelineComponent = ({ addedObjects }) => {
  const { activeSlide, slideList } = useSelector(
    (state) => state.slideList,
    shallowEqual
  );
  const { canvas, isPlaying } = useSelector((state) => state.fabricCanvas);
  const dispatch = useDispatch();
  const [timeLineData, setTimelineData] = useState([]);
  const [timelineRows, setTimelineRows] = useState([]);
  const [timelineEffects, setTimelineEffects] = useState({});
  const timelineState = useRef();
  const autoScrollWhenPlay = useRef(true);
  const [lastEndTime, setLastEndTime] = useState(0);


  const handleLastEndTime = useCallback((data) => {
    const endTimes = data
      ?.map((item) => item?.actions.map((action) => action.end))
      .flat();

    return data?.length ? Math.max(...endTimes) : 0;
  }, []);

  // synchronize canvas objects along with timeline
  const renderObjectOnCanvas = useCallback(
    (objectId, allActions = {}, visibility, shouldRenderAll= false) => {
      if (!canvas) return;
      const actions = Object.values(allActions);

      if (shouldRenderAll) {
        canvas.forEachObject((obj) => {
          obj.visible = visibility;
        });
      } else {
        canvas.forEachObject((obj) => {
          const action = actions.find((action) => action.id === obj.id);
          if (action) {
            if (obj.id === objectId) {
              const playingTime = (action.end - action.start) * 1000;

              const timeLineEndTime = handleLastEndTime(
                actions?.map((item) => ({ actions: [item] }))
              );
              obj.visible =
                Math.round(timeLineEndTime) ===
                Math.round(timelineState.current.getTime())
                  ? true
                  : visibility;
              canvas.setActiveObject(obj);
              if (visibility) {
                const timer = setTimeout(() => {
                  obj.visible = false;
                  if (timer) {
                    clearTimeout(timer);
                  }
                }, playingTime);
              }
              if (obj && obj.type === "video" && visibility) {
                dispatch(setVideoControl(false));
                obj.play();
              }
            } else {
              dispatch(setVideoControl(false));
              if (obj && obj.type === "video") {
                if (isPlaying) {
                  obj.pause();
                }
              }
            }
          }
        });
      }

      canvas.renderAll();
    },
    [canvas, isPlaying, dispatch, handleLastEndTime]
  );

  // set timeline effects row wise
  const getTimeLineActions = useCallback(
    (file, slideId, newTimelineEffects, styleElement, newTimelineRows) => {
      const actionId = file.id; // Unique action ID from the shape file's ID
      const effectId = `effect-${file.id}`; // Create a unique effect ID
      switch (file?.type) {
        case "audio": {
          // Assuming you're determining styles (e.g., color, image) based on the file properties
          const backgroundColor = "red"; // Placeholder for your logic
          const backgroundImageUrl = "/assets/soundWave.png"; // Placeholder for your logic

          // Add audio controls in action
          newTimelineEffects[effectId] = {
            id: effectId,
            name: file.name,
            sequence: file?.sequence,
            // Using the audio file's name as the effect name
            source: {
              start: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  const src = action.data.src;
                  audioControl.start({
                    id: src,
                    src,
                    startTime: action.start,
                    engine,
                    time,
                  });
                }
              },
              enter: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  const src = action.data.src;
                  audioControl.start({
                    id: src,
                    src,
                    startTime: action.start,
                    engine,
                    time,
                  });
                }
              },
              leave: ({ action, engine }) => {
                const src = action.data.src;
                audioControl.stop({ id: src, engine });
              },
              stop: ({ action, engine }) => {
                const src = action.data.src;
                audioControl.stop({ id: src, engine });
              },
            },
          };

          // Append CSS rules for each effect
          styleElement.sheet.insertRule(
            `
                    .timeline-effect-${effectId} { 
                        cursor: pointer;
                        background-color: ${backgroundColor};
                        background-image: url("${backgroundImageUrl}");
                        background-position: bottom;
                        background-repeat: repeat-x;
                    }
                `,
            styleElement.sheet.cssRules.length
          );
          const audioObject = {
            id: actionId,
            start: file.start,
            end: file.end,
            effectId: effectId,
            data: {
              src: file.url,
              name: file.name,
              type: "audio",
            },
          };
          newTimelineRows.push({
            id: `${slideId}-audio-${file?.id}`,
            actions: [audioObject],
            sequence: file?.sequence,
          });
          break;
        }
        case "video": {
          // Assuming you're determining styles (e.g., color, image) based on the file properties
          const backgroundColor = "red"; // Placeholder for your logic
          const backgroundImageUrl = "/assets/soundWave.png"; // Placeholder for your logic

          const url = new URL(file?.url);
          const pathname = url.pathname;
          const fileName = pathname.split("/").pop();
          // Add video controls in action
          newTimelineEffects[effectId] = {
            id: effectId,
            name: fileName,
            sequence: file?.sequence, // Using the video file's name as the effect name
            source: {
              start: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  renderObjectOnCanvas(action.id, engine?._actionMap, true);
                }
              },
              enter: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  renderObjectOnCanvas(action.id, engine?._actionMap, true);
                }
              },
              leave: ({ action, engine, isPlaying }) => {
                if (isPlaying) {
                  renderObjectOnCanvas(action.id, engine?._actionMap, false);
                }
              },
              stop: ({ action, engine, isPlaying }) => {
                if (isPlaying) {
                  renderObjectOnCanvas(action.id, engine?._actionMap, false);
                }
                const src = action.data.src;
                audioControl.stop({ id: src, engine });
              },
            },
          };

          // Append CSS rules for each effect
          styleElement.sheet.insertRule(
            `
                    .timeline-effect-${effectId} { 
                        cursor: pointer;
                        background-color: ${backgroundColor};
                        background-image: url("${backgroundImageUrl}");
                        background-position: bottom;
                        background-repeat: repeat-x;
                    }
                `,
            styleElement.sheet.cssRules.length
          );
          const videoObject = {
            id: actionId,
            start: file.start,
            end: file.end,
            effectId: effectId,
            data: {
              src: file.url,
              thumbnail: file.thumbnail,
              name: fileName,
              type: "video",
            },
          };
          newTimelineRows.push({
            id: `${slideId}-video-${file?.id}`,
            actions: [videoObject],
            sequence: file?.sequence,
          });
          break;
        }
        case "image": {
          // Assuming you're determining styles (e.g., color, image) based on the file properties
          const backgroundImageUrl = file?.url; // Placeholder for your logic

          // Create a new URL object
          const url = new URL(file?.url);

          // Extract the pathname from the URL
          const pathname = url.pathname;

          // Split the pathname by '/' and get the last part, which is the file name
          const fileName = pathname.split("/").pop();
          // Add image controls in action
          newTimelineEffects[effectId] = {
            id: effectId,
            name: fileName,
            sequence: file?.sequence, // Using the image file's name as the effect name
            source: {
              start: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  const src = action.data.src;
                  const id = action.id;
                  renderObjectOnCanvas(id, engine?._actionMap, true);
                  lottieControl.enter({
                    id,
                    src,
                    startTime: action?.start || 0,
                    engine,
                    time,
                  });
                }
              },
              enter: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  const src = action.data.src;
                  const id = action.id;
                  renderObjectOnCanvas(id, engine?._actionMap, true);
                  lottieControl.enter({
                    id,
                    src,
                    startTime: action?.start || 0,
                    engine,
                    time,
                  });
                }
              },
              leave: ({ action, engine, isPlaying }) => {
                const id = action.id;
                if (isPlaying) {
                  renderObjectOnCanvas(id, engine?._actionMap, false);
                }
                lottieControl.leave({ id, engine });
              },
              stop: ({ action, engine, isPlaying }) => {
                const id = action.id;
                if (isPlaying) {
                  renderObjectOnCanvas(id, engine?._actionMap, false);
                }
                lottieControl.leave({ id, engine });
              },
            },
          };
          // Append CSS rules for each effect
          styleElement.sheet.insertRule(
            `
                    .timeline-effect-${effectId} { 
                        cursor: pointer;
                        background-image: url("${backgroundImageUrl}");
                        background-position: bottom;
                        background-repeat: repeat-x;
                    }
                `,
            styleElement.sheet.cssRules.length
          );
          const imageObject = {
            id: actionId,
            start: 0, // file?.start || 0,
            end: file?.end || 5,
            effectId: effectId,
            data: {
              src: file?.url,
              name: fileName,
              type: "image",
            },
          };
          newTimelineRows.push({
            id: `${slideId}-image-${file?.id}`,
            actions: [imageObject],
            sequence: file?.sequence,
          });
          break;
        }
        case "shape": {
          // Assuming you're determining styles (e.g., color, shape) based on the file properties
          const backgroundImageUrl = file?.src; // Placeholder for your logic

          const fileName = file?.src?.split("/").pop().split("?")[0];
          // Add shape controls in action
          newTimelineEffects[effectId] = {
            id: effectId,
            name: fileName,
            sequence: file?.sequence, // Using the shape file's name as the effect name
            source: {
              start: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  const src = action.data.src;
                  const id = action.id;
                  renderObjectOnCanvas(id, engine?._actionMap, true);
                  lottieControl.enter({
                    id,
                    src,
                    startTime: action?.start || 0,
                    engine,
                    time,
                  });
                }
              },
              enter: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                  const src = action.data.src;
                  const id = action.id;
                  renderObjectOnCanvas(id, engine?._actionMap, true);
                  lottieControl.enter({
                    id,
                    src,
                    startTime: action?.start || 0,
                    engine,
                    time,
                  });
                }
              },
              leave: ({ action, engine, isPlaying }) => {
                const id = action.id;
                if (isPlaying) {
                  renderObjectOnCanvas(id, engine?._actionMap, false);
                }
                lottieControl.leave({ id, engine });
              },
              stop: ({ action, engine, isPlaying }) => {
                const id = action.id;
                if (isPlaying) {
                  renderObjectOnCanvas(id, engine?._actionMap, false);
                }
                lottieControl.leave({ id, engine });
              },
            },
          };
          // Append CSS rules for each effect
          styleElement.sheet.insertRule(
            `
                    .timeline-effect-${effectId} { 
                        cursor: pointer;
                        background-image: url("${backgroundImageUrl}" || "");
                        background-position: bottom;
                        background-repeat: repeat-x;
                    }
                `,
            styleElement.sheet.cssRules.length
          );
          const shapeObject = {
            id: actionId,
            start: file?.start || 0,
            end: file?.end || 5,
            effectId: effectId,
            data: {
              src: file?.src,
              name: fileName,
              type: "shape",
            },
          };
          newTimelineRows.push({
            id: `${slideId}-shape-${file?.id}`,
            actions: [shapeObject],
            sequence: file?.sequence,
          });
          break;
        }
        case "textbox":
        case "button":
        case "textInput":
        case "card":
        case "quiz": {
          // Add shape controls in action
          newTimelineEffects[effectId] = {
            id: effectId,
            name: effectId,
            sequence: file?.sequence,
            source: {
              start: ({ action, engine, isPlaying }) => {
                if (isPlaying) {
                  const id = action.id;
                  renderObjectOnCanvas(id, engine?._actionMap, true);
                }
              },
              enter: ({ action, engine, isPlaying }) => {
                if (isPlaying) {
                  const id = action.id;
                  renderObjectOnCanvas(id, engine?._actionMap, true);
                }
              },
              leave: ({ action, engine, isPlaying }) => {
                if (isPlaying) {
                  renderObjectOnCanvas(action.id, engine?._actionMap, false);
                }
              },
              stop: ({ action, engine, isPlaying }) => {
                if (isPlaying) {
                  renderObjectOnCanvas(action.id, engine?._actionMap, false);
                }
              },
            },
          };
          // Append CSS rules for each effect
          styleElement.sheet.insertRule(
            `
                    .timeline-effect-${effectId} { 
                        cursor: pointer;
                        background-position: bottom;
                        background-repeat: repeat-x;
                    }
                `,
            styleElement.sheet.cssRules.length
          );
          const textObject = {
            id: actionId,
            start: file?.start || 0,
            end: file?.end || 5,
            effectId: effectId,
            data: {
              text: file?.text || file?.label || file?.question || "",
              type: file.type,
            },
          };
          newTimelineRows.push({
            id: `${slideId}-${file.type}-${file?.id}`,
            actions: [textObject],
            sequence: file?.sequence,
          });
          break;
        }
        default:
          break;
      }
    },
    [renderObjectOnCanvas]
  );

  useEffect(() => {
    const newTimelineRows = [];
    const newTimelineEffects = {};
    const styleElement = document.createElement("style");
    document.head.appendChild(styleElement);
    [...slideList].forEach((slide, index) => {
      const sortedActions = [
        ...slide?.audio,
        ...slide?.video,
        ...slide?.image,
        ...slide?.shape,
        ...slide?.textbox,
        ...slide?.button,
        ...slide?.textInput,
        ...slide?.card,
        ...slide?.quiz,
      ]?.sort((a, b) => a.sequence - b.sequence);
      sortedActions?.forEach((file) => {
        getTimeLineActions(
          file,
          slide?.id,
          newTimelineEffects,
          styleElement,
          newTimelineRows
        );
      });
    });
    setTimelineRows(newTimelineRows);
    setTimelineEffects(newTimelineEffects);
    // Cleanup: Remove the style element when the component unmounts or updates
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [slideList, addedObjects, getTimeLineActions]); // Re-run effect if slideList changes

  useEffect(() => {
    if (activeSlide) {
      const slideData = timelineRows?.filter((obj) =>
        obj.id.includes(activeSlide.id)
      );
      const time = handleLastEndTime(slideData);
      setLastEndTime(time);
      setTimelineData(slideData);
    }
  }, [timelineRows, activeSlide, addedObjects, handleLastEndTime]);

  // set canvas to initial state when unmount the timeline component
  useEffect(() => {
    return () => {
      renderObjectOnCanvas("", {}, true, true);
    };
  }, [renderObjectOnCanvas]);

  return (
    <div className="timeline-editor-engine w-full h-full">
      <TimelinePlayer
        timelineState={timelineState}
        autoScrollWhenPlay={autoScrollWhenPlay}
        timeLineEndTime={lastEndTime}
      />
      <Timeline
        editorData={timeLineData || []}
        effects={timelineEffects || {}}
        hideCursor={false}
        autoScroll={true}
        scale={scale}
        style={{ width: "1600px", height: "100%" }}
        scaleWidth={scaleWidth}
        startLeft={startLeft}
        ref={timelineState}
        onChange={(data) => {
          console.log("onchnage", data);
          const time = handleLastEndTime(data);
          setLastEndTime(time);
          setTimelineData(data);
        }}
        getActionRender={(action, row) => {
          return <CustomRender action={action} row={row} />;
        }}
      />
    </div>
  );
};

export default TimelineComponent;