import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCalendarAlt, faEnvelope, faImage, faFile, faHashtag, faLock, faSlidersH, faTextWidth, faClock, faLink } from '@fortawesome/free-solid-svg-icons';
import { fabric } from 'fabric';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { startCase } from 'lodash';
import { setAllMediaToSlide } from '../../../store/reducers/slideListSlice';
import { useDispatch } from 'react-redux';
import { shallowEqual } from 'react-redux';

const InputText = () => {
  const [selectedInputType, setSelectedInputType] = useState('date');
  const { canvas } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector(state => state.slideList, shallowEqual);
  const [inputText, setInputText] = useState('');
  const [placeholder, setPlaceholder] = useState('Enter Date');
  const dispatch = useDispatch()

  const inputTypes = [
    'date', 'email', 'number',
    'password', 'tel', 'text', 'time', 'link',
  ];

  // Mapping input types to Font Awesome icons
  const icons = {
    tel: faPhone,
    date: faCalendarAlt,
    email: faEnvelope,
    image: faImage,
    file: faFile,
    number: faHashtag,
    password: faLock,
    range: faSlidersH,
    text: faTextWidth,
    time: faClock,
    link: faLink,
  };

  const renderInputField = (type, placeholder) => {
    const Icon = icons[type]; // Get the appropriate icon for the input type
    return (
      <div className="relative mt-2">
        <FontAwesomeIcon icon={Icon} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type={type}
          placeholder={placeholder}
          className={`pl-8 input bg-white border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:ring-blue-500`}
        />
      </div>
    );
  };

  const handleAddElement = (type) => {
    const iconData = icons[type]; // Get the appropriate icon for the input type
    // Create input field Fabric object
    const id = uuidv4();
    dispatch(setAllMediaToSlide({ type: 'textInput', data: { label: inputText, id } }));
    const svgPath = iconData.icon[4]; // The SVG path data

    const inputField = new fabric.StaticInput({
      id,
      width: 200,
      height: 40,
      fill: 'white', // Default fill color
      borderColor: 'black', // Default border color
      borderWidth: 1, // Default border width
      placeholder: inputText, // Default placeholder text
      onChange: () => { }, // Default onChange handler
      svgPath,
      type: 'textInput',
      slideId: activeSlide?.id,
      inputType: type || 'text',
    });

    canvas.add(inputField); // Add input field to canvas
    canvas.renderAll();
  }

  return (
    <div className="pt-4 px-2">
      <div className="mb-4">
        <label className="block text-lg font-semibold mb-2">Select Input Type:</label>
        <select onChange={(e) => {
          setSelectedInputType(e.target.value)
          setInputText('')
          setPlaceholder(`Enter ${startCase(e.target.value)}`)
        }} value={selectedInputType} className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
          {inputTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
      </div>
      <div className="mb-4"> {/* Changed marginBottom: '10px' to Tailwind's mb-4 */}
        <label className="block text-lg font-semibold mb-2">Input Text:</label>
        <input type="text" placeholder={placeholder} value={inputText} onChange={(e) => setInputText(e.target.value)} className="input bg-white border border-gray-300 rounded py-2 px-4 block w-full focus:border-blue-500 focus:ring-blue-500" />
      </div>
      <div>
        <label className="block text-lg font-semibold mb-2">Input Preview:</label>
        {renderInputField(selectedInputType, inputText)}
      </div>
      <div className="mb-4 p-4">
        {/* Insert the Add to Canvas button with the onClick handler */}
        <button onClick={() => handleAddElement(selectedInputType)}>Add to Canvas</button>
      </div>
    </div>
  );
};

export default InputText;
