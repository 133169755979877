import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  faXmark,
  faCircleChevronRight,
  faCircleChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import {
  createCanvasImage,
  createNewSlide,
  getFabricImageFromURL,
  handleLoader,
} from "../../../../utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { isArray } from "lodash";
import { shallowEqual } from "react-redux";
import { useLocation } from "react-router";
import { useAlert } from "../../../../context/AlertContext";

const ResultModal = ({ isOpen, closeModal }) => {
  const { slideList, activeSlide } = useSelector(
    (state) => state.slideList,
    shallowEqual
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [resultData, setResultData] = useState(null);
  const dispatch = useDispatch();
  const previewRef = useRef(null);
  const location = useLocation();
  const projectId = useMemo(() => location.pathname.split('/').pop() || '', [location]);
  const {showAlert} = useAlert();

  const allQuestionsObjects = useMemo(() => {
    if (slideList?.length) {
      let newList = [];
      slideList?.forEach((slide) => {
        const objectList =
          slide?.canvas
            ?.getObjects()
            ?.filter(
              (obj) => obj?.type === "quiz" && obj?.formData?.addToScoring
            ) || [];
        newList = [...newList, ...objectList];
      });
      return newList;
    }
    return [];
  }, [slideList]);

  const finalScoringQuestion = useMemo(() => {
    if (allQuestionsObjects?.length) {
      const data = allQuestionsObjects.map((i, index) => ({
        formData: { ...i.formData, id: i.id, sequence: index },
        questionType: i.questionType,
        slideId: i.slideId,
      }));
      return [...data];
    }
    return [];
  }, [allQuestionsObjects]);

  const resultObj = useMemo(() => {
    if (slideList?.length) {
      const result = slideList?.find((slide) => {
        return (
          slide?.canvas?.getObjects()?.filter((obj) => obj?.type === "result")
            ?.length > 0
        );
      });
      return result;
    }
    return null;
  }, [slideList]);

  React.useEffect(() => {
    if (finalScoringQuestion?.length) {
      const questionList = finalScoringQuestion
        ?.filter((i) => i.questionType)
        ?.map((i) => ({
          ...i?.formData,
          question: i?.formData?.question || i?.formData?.title,
          questionType: i.questionType,
          slideId: i.slideId,
        }));
      setResultData(questionList);
    }
  }, [finalScoringQuestion]);

  const nextSlide = () => {
    if (currentIndex < 2) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleClose = () => {
    setResultData(null);
    setCurrentIndex(0);
    closeModal();
  };

  const handleAddImage = (image) => {
    const newSlide = resultObj ? activeSlide : createNewSlide(dispatch,slideList?.length,projectId,showAlert, true);
    handleLoader(true, "load_image_on_canvas", dispatch);
    const imageId = resultObj ? resultObj.id : uuidv4();
    getFabricImageFromURL(
      image.url,
      (fabricImage) => {
        fabricImage.set({
          id: imageId,
          borderWidth: 0,
          borderStyle: "none",
          borderFill: "rgb(0,0,0)",
          listStyle: "none",
          link: "",
          jumpToSlide: 0,
          slideId: newSlide?.id,
          resultData,
          type: "result",
        });
        fabricImage.setControlsVisibility({
          mt: false,
          mb: false,
        });
        fabricImage.scaleToWidth(newSlide.canvas.width);
        console.log('fabricImagefabricImage', fabricImage);
        if (resultObj) {
          fabricImage.set({
            left: resultObj.left,
            top: resultObj.top,
          });
          newSlide.canvas.remove(resultObj);
          newSlide.canvas.add(fabricImage);
        } else {
          fabricImage.set({
            left: 20,
            top: 50,
          });
          newSlide.canvas.add(fabricImage);
          newSlide.canvas.setActiveObject(fabricImage);
        }
        newSlide.canvas.renderAll();
        const thumbnail = createCanvasImage(newSlide.canvas);
        newSlide.setThumbnail(thumbnail);
        handleLoader(false, "", dispatch);
        handleClose();
      },
      () => {
        handleLoader(false, "", dispatch);
      }
    );
  };

  const addPreviewToslide = async () => {
    const element = previewRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    handleAddImage({ url: data });
  };

  const handleResult = useCallback(
    (data, slideId) => {
      const newList = [...resultData];
      const index = newList.findIndex((obj) => obj.id === data.id);
      if (index !== -1) {
        newList[index] = { ...newList[index], ...data };
        setResultData(newList);
      }
    },
    [resultData]
  );

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative mx-auto p-5 border w-[750px] shadow-lg rounded-[15px] bg-white">
        <div className="flex items-center justify-between  border-b pb-4">
          <h3 className="text-[16px] leading-6 text-gray-900 font-semibold">
            Result
          </h3>
          <button onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} className="size-4 text-gray-400" />
          </button>
        </div>
        <div className="my-5 w-[710px]">
          {finalScoringQuestion?.length &&
          isArray(resultData) &&
          resultData?.length ? (
            <div className="relative w-full max-w-2xl mx-auto">
              <div className="overflow-hidden relative">
                <div
                  className="flex items-center transition-transform duration-1000"
                  style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                  <div className="w-full flex-shrink-0 text-blue p-2">
                    <div className="max-h-96 overflow-y-auto overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left border-r border-gray-300 w-1/4">
                              Question Type
                            </th>
                            <th className="py-3 px-6 text-left border-r border-gray-300 w-3/5">
                              Question
                            </th>
                            <th className="py-3 px-6 text-left w-2/5">
                              Added To Final Scoring
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                          {resultData?.map((item, id) => (
                            <tr
                              className="border-b border-gray-200 hover:bg-gray-100"
                              key={`result-${id}`}
                            >
                              <td className="py-3 px-6 text-left border-r border-gray-300 w-1/4">
                                <div>{item.questionType}</div>
                              </td>
                              <td className="py-3 px-6 text-left border-r border-gray-300 w-3/5">
                                <div>{item.question}</div>
                              </td>
                              <td
                                id="question1Answer"
                                className="py-3 px-6 text-center w-2/5"
                              >
                                <input
                                  id={`addToScoring-${item.id}`}
                                  type="checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer"
                                  checked={!!item?.addToScoring}
                                  value={!!item?.addToScoring}
                                  onChange={(e) => {
                                    handleResult(
                                      {
                                        id: item.id,
                                        addToScoring: e.target.checked,
                                      },
                                      item.slideId
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className="w-full flex-shrink-0 text-blue p-2"
                    ref={previewRef}
                    id="preview-result"
                  >
                    <h1 className="text-center text-blue-600 font-bold text-xl">
                      Result
                    </h1>
                    <div className="max-h-96 overflow-y-auto overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200">
                        <thead>
                          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left border-r border-gray-300 w-1/2">
                              Question
                            </th>
                            <th className="py-3 px-6 text-left border-r border-gray-300 w-1/2">
                              Answer
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                          {resultData
                            ?.filter((i) => i.addToScoring)
                            ?.map(({ question, questionType, trueAnsPropmt, correctAnswer, options }, id) => (
                              <tr
                                className="border-b border-gray-200 hover:bg-gray-100"
                                key={`result-${id}`}
                              >
                                <td className="py-3 px-6 text-left border-r border-gray-300 w-1/2">
                                  <div>{question}</div>
                                </td>
                                <td className="py-3 px-6 text-left border-r border-gray-300 w-1/2">
                                  {questionType === "true-false" && <div>
                                    {trueAnsPropmt || '-'}
                                  </div>}

                                  {questionType === "single-selection" && <div>
                                    {options?.[correctAnswer]?.label}
                                  </div>}

                                  {questionType === "multiple-selection" && <div>
                                    {correctAnswer.map(ans => options?.[ans]?.label).join(', ')}
                                  </div>}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className={`absolute top-1/2 -left-[30px] transform -translate-y-1/2 p-2 text-white ${
                  currentIndex === 0 ? "opacity-60" : ""
                }`}
                onClick={prevSlide}
                disabled={currentIndex === 0}
              >
                <FontAwesomeIcon
                  icon={faCircleChevronLeft}
                  className="size-4 text-gray-800"
                />
              </button>
              <button
                className={`absolute top-1/2 -right-[30px] transform -translate-y-1/2 p-2 text-white ${
                  currentIndex === 1 ? "opacity-60" : ""
                }`}
                onClick={nextSlide}
                disabled={currentIndex === 1}
              >
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  className="size-4 text-gray-800"
                />
              </button>
            </div>
          ) : (
            <p className="max-w-lg mx-auto text-center text-slate-400">
              No result
            </p>
          )}
        </div>
        <div className="flex items-center justify-end  border-t pt-4 gap-[20px]">
          <button onClick={handleClose} className="text-gray-400 text-[14px]">
            Cancel
          </button>
          {currentIndex === 1 && (
            <button
              onClick={addPreviewToslide}
              className={`bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full text-[14px] focus:outline-none ${
                resultData?.length === 0 ? "opacity-50" : ""
              }`}
              disabled={!resultData || resultData?.length === 0}
            >
              {resultObj ? "Update to slide" : "Add To Next Slide"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultModal;
