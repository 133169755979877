import React from "react";
import Layout from "../../modules/LayoutModule";
import Content from "../../modules/LayoutModule/Content/FrontPage/Content";

function HomepageContainer() {
  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default HomepageContainer;
