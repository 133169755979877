import TextFontControl from "./TextFontControl";
import TextFormatAndFillControl from "./TextFormatAndFillControl";
import TextAlignmentAndListControl from "./TextAlignmentAndListControl";
import LayerAndLinkControl from "./LayerAndLinkControl";
import BorderControl from "./BorderControl";
import AITextControl from "./AITextControl";
import React from "react";
import { useSelector } from "react-redux";
import { fabric } from "fabric";
import { AddTextIcon } from "../../../utils/iconHelper";
import { centerFabricObject } from "../../../utils/helpers";
import { v4 as uuidv4 } from 'uuid';
import { setAllMediaToSlide } from "../../../store/reducers/slideListSlice";
import { useDispatch } from "react-redux";
import AnimationControl from "../AnimationControl";

const TextPanel = () => {

    const dispatch = useDispatch();
    const { activeSlide } = useSelector(state => state.slideList);
    const { activeObject } = useSelector(state => state.fabricCanvas);
    // Handler for text type selection
    const handleTextClick = () => {
        const { canvas } = activeSlide;
        const textId = uuidv4();
        const newText = new fabric.StaticText({
            text: 'Click to add some text.',
            fontSize: 18,
            textAlign: 'center',
            width: 250,
            fontFamily: "Open Sans",
            borderWidth: 0,
            borderStyle: 'none',
            borderFill: "rgb(0,0,0)",
            listStyle: 'none',
            link: '',
            jumpToSlide: 0,
            id: textId,
            slideId: activeSlide?.id,
            type: 'textbox'
        });
        // Create updated text to display in the timeline 
        const textUpdated = newText.on('changed', function(e) {
        });
        newText.setControlsVisibility({
            mt: false, mb: false, tl: false, tr: false, bl: false, br: false, mtr: true,
        });


        // Then customize the size or shape of visible controls
        newText.controls.mtr.cornerSize = 10; // Example: Change the size of the rotation control
        newText.controls.mtr.cornerShape = 'circle'; // Change the shape of the rotation control
        newText.controls.mtr.cornerBackgroundColor = 'red'; // Change rotation control background color
        // Apply similar customizations for other controls as needed
        centerFabricObject(newText, canvas);
        canvas.add(newText);
        canvas.setActiveObject(newText);
        dispatch(setAllMediaToSlide({
            type: 'textbox', data: {
                id: textId,
                type: 'text',
                text: textUpdated,// Send updated text to Redux 
            }
        }))
        canvas.renderAll();
    };

    return (
        <div className="flex flex-col w-[280px] svg-box overflow-y-auto overflow-x-hidden bg-gray-50 p-4">
            <div className="items-center justify-center">
                <label htmlFor="fontSelector" className="mb-2 block text-xxs font-medium text-gray-600">Add Text
                    Block</label>
                <div className="mb-6 flex items-center">
                    <div className="flex w-full items-center justify-center">
                        <button
                            className="flex h-10 flex-grow items-center text-gray-600 justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
                            onClick={handleTextClick}
                        >
                            <AddTextIcon />
                            Add a text block
                        </button>
                    </div>
                </div>
                
                {
                    activeObject && activeObject.type === 'textbox' && (
                        <>
                            <TextFontControl />
                            <TextFormatAndFillControl />
                            <TextAlignmentAndListControl />
                            <LayerAndLinkControl />
                            <BorderControl />
                            <AnimationControl/>
                            <AITextControl />
                        </>
                    )
                }
            </div>
        </div>
    )
}
export default TextPanel;
