// src/container/UserLMSContainer/LMSTopBar.jsx
import React, { useContext } from 'react';
import { DataContext } from './DataContext';

const LMSTopBar = () => {
  const { data, loading, error } = useContext(DataContext);
  console.log("data", data);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data.</div>;

  return (
    <div className="topbar flex items-center justify-between p-4 bg-gray-100 shadow-md border-b-2 border-gray-300">
      <div className="flex items-center">
        <img src={data?.[0]?.attributes?.Logo} alt="Organization Logo" className="h-10 w-10 mr-4" />
        <h1 className="text-xl font-bold">{data?.[0]?.attributes?.Name}'s LMS</h1>
      </div>
    
    </div>
  );
};

export default LMSTopBar;
