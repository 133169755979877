import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const strapiAdminToken = process.env.REACT_APP_STRAPI_ADMIN_API_TOKEN;

export const publicApi = {
  get: (url) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}${url}`, {
          headers: {
            Authorization: `Bearer ${strapiAdminToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  delete: (url) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseUrl}${url}`, {
          headers: {
            Authorization: `Bearer ${strapiAdminToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  patch: (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${baseUrl}${url}`, data, {
          headers: {
            Authorization: `Bearer ${strapiAdminToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  post: (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}${url}`, data, {
          headers: {
            Authorization: `Bearer ${strapiAdminToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  put: (url, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}${url}`, data, {
          headers: {
            Authorization: `Bearer ${strapiAdminToken}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};