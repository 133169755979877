import axios from "axios";
import baseUrl from "./config";

export const api = {
  header: () => {
    const header = {};
    return header;
  },
  getToken: (isPublic) => {
    const token = localStorage.getItem("token");
    let headers = api.header();
    if (token) {
      const header = !isPublic
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {};
      headers = { ...headers, ...header };
      return headers;
    }
    return headers;
  },
  get: (url, isPublic) => {
    const headers = api.getToken(isPublic);

    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}${url}`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            reject(err);
          } else {
            reject(err);
          }
        });
    });
  },

  delete: (url) => {
    const headers = api.getToken();
    return new Promise((resolve, reject) => {
      axios
        .delete(`${baseUrl}${url}`, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  patch: (url, data) => {
    const headers = api.getToken();
    return new Promise((resolve, reject) => {
      axios
        .patch(`${baseUrl}${url}`, data, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  post: (url, data, isPublic) => {
    let headers = api.getToken(isPublic);

    // Check if the request is for the newsletter endpoint
    if (url === '/newsletters') {
      headers = {
        ...headers,
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      };
    }
  
    return new Promise((resolve, reject) => {
      axios
        .post(`${baseUrl}${url}`, data, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  put: (url, data, isPublic) => {
    const headers = api.getToken(isPublic);
    return new Promise((resolve, reject) => {
      axios
        .put(`${baseUrl}${url}`, data, {
          headers,
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
