// src/container/DashboardContainer/NewProject.jsx
 
import React, { useState } from "react";
import Sidebar from './Sidebar'; // Import the Sidebar component
import TopBar from './TopBar'; // Import the TopBar component
import { useProjectForm } from "../../utils/useProjectForm"; // Ensure correct import path
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
//import { deleteProject, setProjects } from "../../store/reducers/projectListSlice";
//import baseUrl from "../../api/config";
//import SaveProject from './SaveProject/SaveProject'; // Ensure this import is correct

const AddNewProject = () => {
  const { register, handleSubmit, errors, isDirty, onSubmit, watch } = useProjectForm();
  const navigate = useNavigate(); // Initialize useNavigate

  const [imagePreview, setImagePreview] = useState(null);
  const watchProjectImage = watch("project_image");

  const [isOptionalOpen, setIsOptionalOpen] = useState(false); // State for collapsible section
  const [description, setDescription] = useState(""); // State for Quill editor

  React.useEffect(() => {
    if (
      watchProjectImage &&
      !watchProjectImage?.url &&
      watchProjectImage.length > 0
    ) {
      const file = watchProjectImage[0];
      const filePreview = URL.createObjectURL(file);
      setImagePreview(filePreview);

      // Cleanup: Revoke object URL to avoid memory leaks
      return () => URL.revokeObjectURL(filePreview);
    }
  }, [watchProjectImage]);

  const handleFormSubmit = async (data) => {
    await onSubmit(data); // Call the existing onSubmit function
    navigate('/dashboard'); // Redirect to /dashboard
  };

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar /> {/* Use the Sidebar component */}

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <TopBar /> {/* Use the TopBar component */}

        {/* Main Section */}
        <main className="flex-1 bg-gray-100 p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">Add New Project</h3>
            <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Project Name
                </label>
                <input
                  type="text"
                  {...register("name")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Project Image
                </label>
                <input
                  type="file"
                  {...register("project_image")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.project_image && (
                  <p className="text-red-500 text-sm">{errors.project_image.message}</p>
                )}
                {imagePreview && (
                  <div className="mt-4">
                    <img
                      src={imagePreview}
                      alt="Selected Project"
                      className="h-40 w-auto border rounded"
                    />
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Project Status
                </label>
                <select
                  {...register("status")}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="draft"
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                  <option value="draft">Draft</option>
                </select>
              </div>

              {watch("status") === "public" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Price
                  </label>
                  <input
                    type="number"
                    {...register("price")}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              )}

              {/* Collapsible Optional Information Section */}
              <div className="bg-gray-50 p-4 rounded shadow mt-6">
                <button
                  type="button"
                  className="text-md font-semibold mb-2"
                  onClick={() => setIsOptionalOpen(!isOptionalOpen)}
                >
                  {isOptionalOpen ? "Hide Optional Information" : "Show Optional Information"}
                </button>

                {isOptionalOpen && (
                  <div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                        className="mt-1"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Category
                      </label>
                      <input
                        type="text"
                        {...register("category")}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Tags
                      </label>
                      <input
                        type="text"
                        {...register("tags")}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Comma-separated"
                      />
                    </div>
                  </div>
                )}
              </div>
            

              <div className="flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 text-white bg-gray-500 rounded mr-2"
                  onClick={() => navigate('/dashboard')}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`px-4 py-2 text-white rounded ${
                    isDirty ? "bg-blue-500" : "bg-blue-300 cursor-not-allowed"
                  }`}
                  disabled={!isDirty}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddNewProject;