import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useHandleCheckout from "./handleCheckout";

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleCheckout = useHandleCheckout();

  useEffect(() => {
    // Extract project_id and amount from location.state
    const projectId = location.state?.project_id;
    const amount = location.state?.amount;

    if (projectId && amount) {
      // Trigger the checkout process
      handleCheckout(projectId, amount);
    } else {
      // If no project_id or amount, redirect to a safe page (like projects or dashboard)
      navigate("/dashboard");
    }
  }, [handleCheckout, location.state, navigate]);

  return (
    <div>
      <h2>Processing your payment...</h2>
    </div>
  );
};

export default Checkout;
