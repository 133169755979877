import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { handleAddVideo } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { shallowEqual } from "react-redux";

const AVATAR = { UPLOADED: "Uploads", EXISTING: "Avatars" };

const AvatarPanel = () => {
  const [isAvatarModal, setIsAvatarModal] = useState(false);
  const { canvas } = useSelector((state) => state.fabricCanvas);
  const { activeSlide } = useSelector((state) => state.slideList, shallowEqual);
  const [selectedTab, setSelectedTab] = useState(AVATAR.UPLOADED);
  const [currentStep, setCurrentStep] = useState(1);
  const { audioList } = useSelector((state) => state.imageBucket);
  const [videoUrl, setVideoUrl] = useState(null); // State to store video URL
 

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsAvatarModal(false);
  };

  const handleOpenModal = () => {
    setIsAvatarModal(true);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const { avatars, avatarLoading } = useSelector((state) => state.avatars);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 4));
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const [data, setData] = useState({ audio: "" }); // State to manage selected audio
  const [ loading, setLoading] = useState(false); // State to manage loading spinner

  const pollEventStatus = (eventId, variant) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`https://peterkros-lms-avatar.hf.space/call/${variant}/${eventId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env["REACT_APP_HF_API_TOKEN"]}`,
          }
        });

        if (!response.ok) {
          throw new Error('Failed to check event status');
        }

        const responseText = await response.text();
        console.log('Event status:', responseText);

        // Check if the responseText contains the completion status
        if (responseText.includes('event: complete')) {
          clearInterval(intervalId); // Stop polling immediately
        

          // Extract the video URL from the responseText
          const urlMatch = responseText.match(/"https:\/\/slidesdeck\.s3[^"]+"/);
          if (urlMatch) {
            const videoUrl = urlMatch[0].slice(1, -1); // Remove the surrounding quotes
            setVideoUrl(videoUrl); // Set the video URL
            console.log("videoUrl", videoUrl);
          }
        } else if (responseText.includes('event: heartbeat')) {
          // Handle heartbeat event if necessary
          console.log('Heartbeat received, continuing to poll...');
        }
      } catch (error) {
        console.error('Error checking event status:', error);
        clearInterval(intervalId); // Stop polling on error
      }
    }, 5000); // Poll every 5 seconds
  };

  const handleApiCall = async (variant, audioId) => {
    const imageFile = 'https://backend.ailms.co/uploads/test_character_d5c5582da9.png';
    const maxRetries = 5;
    const retryDelay = 5000;

    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        const response = await fetch(`https://peterkros-lms-avatar.hf.space/call/${variant}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env["REACT_APP_HF_API_TOKEN"]}`,
          },
          body: JSON.stringify({
            data: [
              { path: imageFile },
              { path: audioList.find(audio => audio.id === audioId).url },
              variant === 'generate_full_video' ? "Transparent" : "Standard" // Change mode based on variant
            ]
          })
        });

        if (!response.ok) {
          const errorDetails = await response.text();
          throw new Error(`Failed to create sample: ${errorDetails}`);
        }

        const result = await response.json();
        console.log('Sample created:', result);

        if (result.event_id) {
          console.log('Event ID:', result.event_id);
          pollEventStatus(result.event_id, `generate_sample_video`); // Pass the variant for polling
          return; // Exit the function if successful
        }
      } catch (error) {
        console.error(`Attempt ${attempt} failed:`, error);
        if (attempt < maxRetries) {
          console.log(`Retrying in ${retryDelay / 1000} seconds...`);
          await new Promise(resolve => setTimeout(resolve, retryDelay));
        } else {
          console.error('Max retries reached. Could not create sample.');
        }
      }
    }
  };

  const handleCreateSample = async () => {
    if (!data.audio) return;
    setLoading(true);
    await handleApiCall('generate_sample_video', data.audio);
    setLoading(false);
  };

  const handleCreateFullVideo = async () => {
    if (!data.audio) return;
    setLoading(true);
    await handleApiCall('generate_full_video', data.audio);
    setLoading(false);
  };

  return (
    <>
      <div className="flex flex-col w-[280px] svg-box overflow-y-auto bg-gray-50 p-4">
        <label
          htmlFor="fontSelector"
          className="mb-2 block text-xs font-medium text-gray-600"
        >
          Avatar Panel
        </label>

        <div className="mb-2 flex items-center">
          <div className="flex w-full items-center justify-center">
            <button
              className="flex h-10 flex-grow items-center text-gray-600 justify-center rounded-md border bg-white text-xs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200"
              onClick={handleOpenModal}
            >
              <FontAwesomeIcon
                icon={"wand-magic-sparkles"}
                className="size-3 mr-2 text-gray-600"
              />
              Generate Avatar
            </button>
          </div>
        </div>

        <div className="flex items-center justify-center p-2 bg-white rounded-md rounded-b-none border border-b-0">
          <div
            className={`flex w-full justify-center rounded-l-md p-2 border transition-all duration-200 active:bg-gray-200 border-r-0 ${
              selectedTab === AVATAR.UPLOADED ? "bg-gray-100" : ""
            }`}
            onClick={() => handleTabClick(AVATAR.UPLOADED)}
          >
            <label
              className={`text-xs rounded-md text-gray-600 cursor-pointer`}
            >
              Uploads
            </label>
          </div>
          <div
            className={`flex w-full justify-center rounded-r-md p-2 border transition-all duration-200 active:bg-gray-200 border-l-0 ${
              selectedTab === AVATAR.EXISTING ? "bg-gray-100" : ""
            }`}
            onClick={() => handleTabClick(AVATAR.EXISTING)}
          >
            <label
              className={`text-xs rounded-md text-gray-600 cursor-pointer`}
            >
              Videos
            </label>
          </div>
        </div>

        <div className="svg-box flex mb-6 items-start justify-center overflow-x-hidden bg-white rounded-md border-t-0 rounded-t-none h-[165px] overflow-y-auto p-2 border">
        {selectedTab === AVATAR.UPLOADED && (
            <div className="grid grid-cols-3 gap-2">
              {avatarLoading && <img src="/loader.gif" alt="Avatar loader" />}
              {avatars.map((avatar, index) => {
                return (
                  <div
                    onClick={() => {
                      handleAddVideo(
                        avatar,
                        canvas,
                        null,
                        dispatch,
                        activeSlide?.id
                      );
                    }}
                    key={index}
                    className="group relative size-[45px] transition-all duration-200 flex-none cursor-pointer overflow-hidden rounded-md bg-white hover:opacity-85 hover:ring ring-gray-200 active:ring-gray-300"
                  >
                    <video
                      className="VideoInput_video"
                      style={{ display: "none" }}
                      src={avatar.url}
                    />
                    <img
                      src={avatar.thumbnail}
                      alt={index}
                      className="h-full w-full object-cover rounded-md"
                    />
            
                  </div>
                );
              })}
            </div>
        )}

        {selectedTab === AVATAR.EXISTING && <>
          <p className="text-xs rounded-md text-gray-600"> Nothing to show right now! </p>
        </>}

        </div>
      </div>

      {isAvatarModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-1/3 relative z-60">
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <div className="step-form">
              <div className="steps flex justify-between mb-4">
                <span className={`px-2 py-1 rounded-full ${currentStep === 1 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"}`}>Step 1</span>
                <span className={`px-2 py-1 rounded-full ${currentStep === 2 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"}`}>Step 2</span>
                <span className={`px-2 py-1 rounded-full ${currentStep === 3 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"}`}>Step 3</span>
                <span className={`px-2 py-1 rounded-full ${currentStep === 4 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"}`}>Step 4</span>
              </div>
              <div className="step-content mb-6">
                {currentStep === 1 && (
                  <div className="text-center">
                    <h2 className="text-lg font-semibold mb-2">Step 1 - Create Avatar</h2>
                    <p className="text-sm text-gray-700">
                      Talking Avatar will create an AI avatar based on your Audio File. You can first create Audio in the Audio tab.
                    </p>
                    <p className="text-sm text-gray-700 mt-2">
                      To create a talking avatar, in the next step select your audio and character type to create a 5s sample. After approving the sample, we will create an avatar for your full audio.
                    </p>
                  </div>
                )}
                {currentStep === 2 && (
                  <div className="text-center">
                    <h2 className="text-lg font-semibold mb-2">Step 2 - Select Audio</h2>
                    <select
                      className="w-full p-2 border border-gray-300 rounded mt-2"
                      onChange={(e) => setData({ ...data, audio: e.target.value })}
                      value={data.audio}
                    >
                      <option value="" disabled>Select an audio file</option>
                      {audioList.map((audio) => (
                        <option key={audio.id} value={audio.id}>
                          {audio.name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      onClick={handleCreateSample}
                      disabled={!data.audio}
                    >
                      Create Sample
                    </button>
                    {loading && <img src="/spiner.gif" className="size-10 center" alt="Avatar loader" />}
                    {videoUrl && <video controls className="h-[300px] p-4 items-center self-center">
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>}
                  </div>
                )}
                {currentStep === 3 && (
                  <div className="text-center">
                    <h2 className="text-lg font-semibold mb-2">Step 2 - Select Audio</h2>
                    <select
                      className="w-full p-2 border border-gray-300 rounded mt-2"
                      onChange={(e) => setData({ ...data, audio: e.target.value })}
                      value={data.audio}
                    >
                      <option value="" disabled>Select an audio file</option>
                      {audioList.map((audio) => (
                        <option key={audio.id} value={audio.id}>
                          {audio.name}
                        </option>
                      ))}
                    </select>
                    <button
                      className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      onClick={handleCreateFullVideo}
                      disabled={!data.audio}
                    >
                      Create Full Video
                    </button>
                    {loading && <img src="/spiner.gif" className="size-10 center" alt="Avatar loader" />}
                    {videoUrl && <video controls className="h-[300px] p-4 items-center self-center">
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>}
                  </div>
                )}
                {currentStep === 4 && <div className="text-center">Content for Step 4</div>}
              </div>
              <div className="step-navigation flex justify-between">
                <button
                  className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 disabled:opacity-50"
                  onClick={handlePreviousStep}
                  disabled={currentStep === 1}
                >
                  Previous
                </button>
                <button
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                  onClick={handleNextStep}
                  disabled={currentStep === 4}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AvatarPanel;
