import React, { useState } from "react";
import { api } from "../../../../api/api";
const SupportModal = ({ isVisible, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null);
  const [honeypot, setHoneypot] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(null); // Reset status message

    if (honeypot) {
      // If honeypot field is filled, assume it's a bot
      setStatus({ success: false, message: "Spam detected." });
      return;
    }

    try {
      await api.post("/api/email", {
        to: process.env.REACT_APP_SUPPORT_EMAIL, // Add this to your .env file
        subject: `Support Request from ${formData.name}`,
        text: formData.message,
        html: `<p><strong>Name:</strong> ${formData.name}</p>
               <p><strong>Email:</strong> ${formData.email}</p>
               <p><strong>Message:</strong> ${formData.message}</p>`,
      });
      setStatus({ success: true, message: "Email sent successfully!" });
      setFormData({ name: "", email: "", message: "" }); // Clear form
    } catch (error) {
      setStatus({
        success: false,
        message: error.response?.data?.error?.message || "Failed to send email.",
      });
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-96 p-6 relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-black"
        >
          ✕
        </button>
        <h2 className="text-xl font-semibold mb-4">Contact Support</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div className="mb-4">
            <textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-lg p-2"
              rows="4"
              required
            />
          </div>
          <div style={{ display: "none" }}>
            <input
              type="text"
              name="honeypot"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg"
          >
            Send
          </button>
        </form>
        {status && (
          <p
            className={`mt-4 text-sm ${
              status.success ? "text-green-500" : "text-red-500"
            }`}
          >
            {status.message}
          </p>
        )}
      </div>
    </div>
  );
};

export default SupportModal;
