import React, { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { publicApi } from '../../api/publicApi';  // Adjust the path to your API service

// Create the context
export const DataContext = createContext();

// Create the provider component
export const DataProvider = ({ children }) => {
  const { organizationName } = useParams();  // Access the dynamic route param (organizationName)
  const [data, setData] = useState(null);    // State to hold the fetched data
  const [loading, setLoading] = useState(true);  // Loading state to handle async data fetching
  const [error, setError] = useState(null);  // Error state in case of API failure
  const [user, setUser] = useState(null);  // State to hold the logged-in user

  // Function to set the user after login
  const updateUser = (userData) => {
    setUser(userData);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('email');
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);
 console.log("Data Context User:", user);
  useEffect(() => {
    // Fetch the organization data when the component mounts
    const fetchData = async () => {
      try {
        // Example: Fetch organization-specific data from your backend
        const response = await publicApi.get(`/api/organizations/?filters[Name][$eq]=${organizationName}&populate=lms_user&populate=courses`);
        setData(response.data);  // Store the data in state
      } catch (err) {
        console.error('Error fetching organization data:', err);
        setError(err);
      } finally {
        setLoading(false);  // Set loading to false after fetching completes
      }
    };
    // Fetch data only if organizationName is available
    if (organizationName) {
      fetchData();
    }
  }, [organizationName]);

  

  // The context provider will pass down `data`, `loading`, `error`, `user`, and `updateUser` to any consuming component
  return (
    <DataContext.Provider value={{ data, loading, error, user, updateUser }}>
      {children}
    </DataContext.Provider>
  );
};
