import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCropperVisibility} from "../../../store/reducers/cropperSlice";

const ImageActions = () => {

    const {canvas} = useSelector(state => state.fabricCanvas);
    const {show: showCropper} = useSelector(state => state.cropper);
    const dispatch = useDispatch();

    return (
        <div>
            <label htmlFor="fontSelector"
                   className="mb-2 block text-xxs font-medium text-gray-600">Image Cropper</label>
            <div className="mb-4 flex items-center justify-center">
                {
                    !showCropper && (
                        <button
                            onClick={() => {
                                let obj = canvas.getActiveObject();
                                if(obj && obj.type === 'image'){
                                    obj.cropInit();
                                    dispatch(setCropperVisibility(true));
                                }
                                // const zoom = canvas.getZoom();
                                // canvas.requestRenderAll();
                                // dispatch(setCrop({
                                //     unit: 'px',
                                //     x: activeObject.left * zoom,
                                //     y: activeObject.top * zoom,
                                //     width: (activeObject.getScaledWidth() * zoom) / 4,
                                //     height: (activeObject.getScaledHeight() * zoom) / 4
                                // }));
                                // dispatch(setCanvasImageSrc(createCanvasImage(canvas)));

                            }}
                            className="flex size-10 flex-grow items-center justify-center rounded-md border text-gray-600 bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200">
                            <FontAwesomeIcon icon={"crop"} className="size-3 text-gray-600 mr-2"/>
                            Crop Image
                        </button>
                    )
                }
            </div>
        </div>
    )
}
export default ImageActions;