import React from "react";
import Topbar from "./Content/Topbar/Topbar";

function LayoutModule({ children }) {
  return (
    <>
      <Topbar />
      <div className="h-full max-h-[calc(100vh-64px)] overflow-auto">{children}</div>
    </>
  );
}

export default LayoutModule;
