import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../../api/api';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import ReactQuill from 'react-quill-new';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const EditProject = () => {
  const { project_id } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({
    name: '',
    description: '',
    status: 'draft',
    category: '',
    tags: [],
    price: 0,
    likes: 0,
    views: 0,
    downloads: 0,
    image: ''
  });
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [initialProjectData, setInitialProjectData] = useState({});

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await api.get(`/api/projects/${project_id}`);
        if (response.data) {
          const project = response.data.attributes;
          const tags = response.data.attributes.tags.data.map(tag => ({
            id: tag.id,
            tagName: tag.attributes.tagName
          }));
          const imageUrl = project.project_image.data.attributes.formats.medium.url;

          // Use the description directly as it is already an HTML string
          const descriptionHtml = project.description || '';

          setProjectData({
            ...project,
            tags: tags || [],
            price: project.price || 0,
            likes: project.likes || 0,
            views: project.views || 0,
            downloads: project.purchases || 0,
            image: `https://backend.ailms.co${imageUrl}`,
            description: descriptionHtml // Use the HTML string directly
          });
          setInitialProjectData({
            ...project,
            tags: tags || [],
            price: project.price || 0,
            likes: project.likes || 0,
            views: project.views || 0,
            downloads: project.downloads || 0,
            image: `https://backend.ailms.co${imageUrl}`,
            description: descriptionHtml // Use the HTML string directly
          });
          setTitleCharCount(project.name.length);
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchProjectData();
  }, [project_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' && value.length <= 100) {
      setTitleCharCount(value.length);
    }
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDescriptionChange = (value) => {
    setProjectData((prevData) => ({
      ...prevData,
      description: value
    }));
  };

  const handleStatusChange = (status) => {
    setProjectData((prevData) => ({
      ...prevData,
      status
    }));
  };

  const handleSave = async () => {
    try {
      const updatedData = {};
      for (const key in projectData) {
        if (projectData[key] !== initialProjectData[key]) {
          updatedData[key] = projectData[key];
        }
      }
      // Wrap the updatedData in a data object
      await api.put(`/api/projects/${project_id}`, { data: updatedData });
      navigate('/projects');
    } catch (error) {
      console.error('Error saving project data:', error);
    }
  };

  return (
    <div className="flex h-screen bg-custom-skyBlue">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <TopBar />
        <main className="flex-1 bg-gray-100 p-4 overflow-auto">
          <div className="bg-white p-6 rounded shadow">
            <h3 className="text-lg font-semibold mb-2">Edit Project</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white"
                value={projectData.name}
                onChange={handleInputChange}
              />
              <p className="text-xxs text-gray-500">{titleCharCount}/100</p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <ReactQuill
                value={projectData.description || ''}
                onChange={handleDescriptionChange}
                className="bg-white"
                modules={{
                  toolbar: [
                    [{ 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, 
                     {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image', 'code-block'],
                    ['clean']                                         
                  ],
                }}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="price"
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white"
                value={projectData.price}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Image URL</label>
              <input
                type="text"
                name="image"
                className="mt-1 block rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white"
                value={projectData.image}
                onChange={handleInputChange}
              />
            </div>
            {projectData.image && (
              <div className="mb-4 flex justify-center">
                <img src={projectData.image} alt="Project" className="w-60 h-auto rounded-md" />
              </div>
            )}
            <div className="mb-4 flex gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Likes</label>
                <p>{projectData.likes}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Views</label>
                <p>{projectData.views}</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Downloads</label>
                <p>{projectData.downloads}</p>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Status</label>
              <select
                name="status"
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white"
                value={projectData.status}
                onChange={(e) => handleStatusChange(e.target.value)}
              >
                <option value="private">Private</option>
                <option value="public">Public</option>
                <option value="draft">Draft</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <input
                type="text"
                name="category"
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-white"
                value={projectData.category}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Tags</label>
              <div className="flex flex-wrap gap-2 mt-2">
                {projectData.tags && projectData.tags.length > 0 ? projectData.tags.map(tag => (
                    <span key={tag.id} className="bg-blue-200 hover:bg-blue-300 py-1 px-2 rounded-lg text-xxs">
                    {tag.tagName}
                    </span>
                )) : "N/A"}
              </div>
            </div>
            <button
              className="mt-4 bg-blue-500 text-white p-2 rounded-lg"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditProject;
