import React from "react";
import { TickMarkIcon } from "../../../utils/iconHelper";
import { useAuth, useCheckSubscription } from "../../../context/AuthContext";
import baseUrl from "../../../api/config"
import { useNavigate } from "react-router-dom";


const Payment = () => {
  const { user, logout } = useAuth();
  const isActive = useCheckSubscription(user);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate('/');
  };
      console.log("isActive", isActive);
  
  return (
    <>
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
        <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <p className="text-md text-blue-600 mb-4">PRICING</p>
          <button className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center SS_ProductCheckout" onClick={handleLogout}>Logout</button>
          <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-700 ">
            Designed for business teams like yours
          </h2>
          <p className="mb-5  text-gray-500 sm:text-xl ">
            Here at Landwind we focus on markets where technology, innovation,
            and capital can unlock long-term value and drive economic growth.
          </p>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow  xl:p-8">
            <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
            <p className="font-light text-gray-500 sm:text-lg ">
              Best option for personal use &amp; for your next project.
            </p>
            <div className="flex items-baseline justify-center my-8">
              <span className="mr-2 text-5xl font-extrabold">$29</span>
              <span className="text-gray-500 ">/month</span>
            </div>

            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>Individual configuration</span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>No setup, or hidden fees</span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Team size: <span className="font-semibold">1 developer</span>
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Premium support: <span className="font-semibold">6 months</span>
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Free updates: <span className="font-semibold">6 months</span>
                </span>
              </li>
            </ul>
            <button
              className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center SS_ProductCheckout"
              data-id="5"
              data-email={user?.email}
              data-url={baseUrl}
            >
              Get started
            </button>
          </div>

          <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow xl:p-8">
            <h3 className="mb-4 text-2xl font-semibold">Company</h3>
            <p className="font-light text-gray-500 sm:text-lg ">
              Relevant for multiple users, extended &amp; premium support.
            </p>
            <div className="flex items-baseline justify-center my-8">
              <span className="mr-2 text-5xl font-extrabold">$59</span>
              <span className="text-gray-500 ">/month</span>
            </div>

            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>Individual configuration</span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>No setup, or hidden fees</span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Team size: <span className="font-semibold">10 developers</span>
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Premium support: <span className="font-semibold">24 months</span>
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Free updates: <span className="font-semibold">24 months</span>
                </span>
              </li>
            </ul>
            <button
              className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center SS_ProductCheckout"
              data-id="6"
              data-email={user?.email}
              data-url={baseUrl}
            >
              Get started
            </button>
          </div>

          <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow  xl:p-8">
            <h3 className="mb-4 text-2xl font-semibold">Enterprise</h3>
            <p className="font-light text-gray-500 sm:text-lg ">
              Best for large scale uses and extended redistribution rights.
            </p>
            <div className="flex items-baseline justify-center my-8">
              <span className="mr-2 text-5xl font-extrabold">$129</span>
              <span className="text-gray-500 ">/month</span>
            </div>

            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>Individual configuration</span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>No setup, or hidden fees</span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Team size: <span className="font-semibold">100+ developers</span>
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Premium support: <span className="font-semibold">36 months</span>
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <TickMarkIcon />
                <span>
                  Free updates: <span className="font-semibold">36 months</span>
                </span>
              </li>
            </ul>
            <button
              className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center SS_ProductCheckout"
              data-id="7"
              data-email={user?.email}
              data-url={baseUrl}
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
