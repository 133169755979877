import {sendBackward, sendForward} from "../../../utils/helpers";
import React from "react";
import {useSelector} from "react-redux";
import {LayerBackIcon, LayerFrontIcon} from "../../../utils/iconHelper";
import LinkControl from "../LinkControl";

const LayerAndLinkControl = () => {

    const {activeSlide} = useSelector(state => state.slideList);

    return (
        <div className="mb-6 flex items-center space-x-4">
            <div className="w-1/2">
                <label htmlFor="fontSelector" className="truncate text-xxs font-medium text-gray-600">Send
                    Front/Back</label>
                <div className="mt-2 flex">
                    <button
                        onClick={() => sendForward(activeSlide.canvas)}
                        className="flex size-10 flex-grow items-center justify-center rounded-l-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200">
                        <LayerFrontIcon/>
                    </button>
                    <button
                        onClick={() => sendBackward(activeSlide.canvas)}
                        className="flex size-10 flex-grow items-center justify-center rounded-r-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200">
                        <LayerBackIcon/>
                    </button>
                </div>
            </div>
            <LinkControl/>
        </div>
    )
}
export default LayerAndLinkControl;