import React, { useState } from 'react'; // Import useState
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate instead of useHistory
import Topbar from '../../Content/Topbar/Topbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import useArticles from './useArticles'; // Import the custom hook
import Footer from '../../Content/Footer/Footer';
import SupportModal from './Modal';
const Docs = () => {
  const navigate = useNavigate(); // Updated to useNavigate
  const { articles, loading, error } = useArticles(); // Use the custom hook
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  // Function to handle search
  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.length > 0 && Array.isArray(articles)) {
      const results = articles.filter(article =>
        article.title.toLowerCase().includes(query.toLowerCase()) ||
        article.content.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  // Function to handle category clicks and navigate to the respective category's article page
  const handleCategoryClick = (slug) => {
    navigate(`/docs/category/${slug}`); // Updated to use navigate
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading articles</div>;

  const highlightText = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <span key={index} className="bg-yellow-200">{part}</span> : part
    );
  };

  return (
    <>
      <Topbar />
      <div className="flex flex-col items-center">
        
        {/* Jumbotron with Search Bar */}
        <div className="relative text-white text-center w-full"> {/* Removed py-12 */}
          <img src="header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" /> {/* Full width image */}
          <img src="component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4" /> {/* Added image in the bottom left corner */}
          <div className="relative z-10 py-12"> {/* Added py-12 to inner div */}
            <h1 className="text-4xl font-bold mb-4">How Can We Help?</h1>
            <p className="mb-6">Our Docs are here to help</p>
            <div className="max-w-md mx-auto relative mb-6"> {/* Changed max-w-lg to max-w-md */}
              <input
                type="text"
                placeholder="Search the knowledge base..."
                className="w-full p-4 pl-12 rounded-xl text-gray-800 text-left" // Added text-left class
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <FontAwesomeIcon icon={faMagnifyingGlass} className="w-6 h-6 absolute left-3 top-4 text-gray-400" />
              {searchResults.length > 0 && (
                <div className="absolute bg-white shadow-lg rounded-lg mt-2 w-full z-10">
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className="p-4 cursor-pointer hover:bg-gray-100 text-black" // Added text-black class
                      onClick={() => handleCategoryClick(result.slug)}
                    >
                      {highlightText(result.title, searchQuery)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Section below Jumbotron */}
        <div className="flex w-full flex-col items-center"> {/* Centered the help topics section */}
          {/* Help Topics Section */}
          <div className="w-full md:w-2/3 border-r">
            <div className="py-8 px-4">
              <div className="max-w-6xl mx-auto">
                <div className="text-center"> {/* Centered the help topics section */}
                  <h2 className="text-xxl opacity-80 font-semibold mb-12">Help Topics</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Category Blocks */}
                    {/* Category Block for 'My Account' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex flex-col items-center cursor-pointer" // Updated to flex-col and items-center
                      onClick={() => handleCategoryClick('my-account')}
                    >
                      <img src="/access.gif" alt="My Account" className="w-24 h-24 mb-4" /> 
                      <div className="text-center"> 
                        <h3 className="text-lg uppercase font-semibold">My Account</h3>
                        <p>How to manage your account and its features.</p>
                      </div>
                    </div>

                    {/* Category Block for 'Billing & Payments' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex flex-col items-center cursor-pointer" // Updated to flex-col and items-center
                      onClick={() => handleCategoryClick('billing-payments')}
                    >
                      <img src="/credit-card.gif" alt="Billing & Payments" className="w-24 h-24 mb-4" /> 
                      <div className="text-center"> 
                        <h3 className="text-lg uppercase font-semibold">Billing & Payments</h3>
                        <p>Information about how we charge you for our services.</p>
                      </div>
                    </div>

                    {/* Category Block for 'Getting Started' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex flex-col items-center cursor-pointer" // Updated to flex-col and items-center
                      onClick={() => handleCategoryClick('getting-started')}
                    >
                      <img src="/rocket.gif" alt="Getting Started" className="w-24 h-24 mb-4" /> 
                      <div className="text-center"> 
                        <h3 className="text-lg uppercase font-semibold">Getting Started</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>

                    {/* Category Block for 'SCROM and LMS' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex flex-col items-center cursor-pointer" // Updated to flex-col and items-center
                      onClick={() => handleCategoryClick('scrom-export-and-lms')}
                    >
                      <img src="/parallel.gif" alt="SCROM Export and LMS" className="w-24 h-24 mb-4" /> 
                      <div className="text-center"> 
                        <h3 className="text-lg uppercase font-semibold">SCROM Export and LMS</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>

                    {/* Category Block for 'Editor' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex flex-col items-center cursor-pointer" // Updated to flex-col and items-center
                      onClick={() => handleCategoryClick('project-editor')}
                    >
                      <img src="/browser.gif" alt="Project Editor" className="w-24 h-24 mb-4" /> 
                      <div className="text-center"> 
                        <h3 className="text-lg uppercase font-semibold">Project Editor</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>

                    {/* Category Block for 'Legal & Copyrights' */}
                    <div
                      className="bg-white p-4 rounded-lg shadow flex flex-col items-center cursor-pointer" // Updated to flex-col and items-center
                      onClick={() => handleCategoryClick('legal-and-copyrights')}
                    >
                      <img src="/legal.gif" alt="Legal & Copyrights" className="w-24 h-24 mb-4" /> 
                      <div className="text-center"> 
                        <h3 className="text-lg uppercase font-semibold">Legal & Copyrights</h3>
                        <p>Articles to get you up running quick and easy.</p>
                      </div>
                    </div>
                    {/* Add more categories similarly... */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Support Section */}
          <div className="w-full md:w-2/3 border-r mt-4 md:mt-0 text-center"> {/* Adjusted width and margin for mobile */}
            <div className="p-4 bg-custom-gray">
              <h2 className="text-2xl font-semibold mb-4">Need Support?</h2>
              <p>Can't find the answer you're looking for? We're here to help!</p>
              <button className="mt-4 mb-4 bg-custom-lightBlue text-white p-2 rounded-lg" onClick={() => setIsModalVisible(true)} >Contact Support</button>
              <SupportModal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Docs;