import { useState } from 'react';
import {publicApi} from '../../../../api/publicApi'

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await publicApi.post('/api/newsletters', {
        data: {
          email: email,
          confirmed: false,  // or any other field required by your API
        },
      });
      console.log(response);
      setMessage('Confirmation email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setMessage('Error sending confirmation email.');
    }
  };
  
  

  return (
    <div className="relative bg-custom-cyan py-10 px-4 sm:px-6 flex flex-col sm:flex-row justify-between items-center w-full">
      <img
        src="cube1.png"
        alt="Cube"
        className="absolute bottom-0 left-0 w-28 h-28"
      />
    
      <div className="text-white text-lg mb-4 sm:mb-0 sm:w-1/2 pl-24 xs:pl-8 pr-8">
        Sign up today and never miss out on exciting developments that can take your corporate training to the next level!
      </div>
      <div className="flex sm:w-1/2">
        <form onSubmit={handleEmailSubmit} className="flex w-full">
          <input
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-3 rounded-l-lg w-full sm:w-auto flex-grow"
            required
          />
          <button type="submit" className="bg-blue-500 text-white p-3 rounded-r-lg">
            Subscribe
          </button>
        </form>
      </div>
      {message && <p className="text-white mt-4">{message}</p>}
    </div>
  );
};

export default Newsletter;
