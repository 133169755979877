import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteImageFromS3 } from '../../../utils/helpers';
import { useAuth } from '../../../context/AuthContext';
import { DeleteSheetIcon } from '../../../utils/iconHelper';

const ImageGallery = ({ uploadStatus, handleAddImage, hideRemoveImg = false }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { imageList } = useSelector((state) => state.imageBucket);

  const filteredImageList = imageList.filter((image) =>
    /\.(jpg|jpeg|png|gif)(\?.*)?$/i.test(image.url)
  );

  const handleDeleteImage = async (image) => {
    // Call the delete function
    await deleteImageFromS3(image, dispatch, user?.user_id);
  };

  const handleDragImageStart = (e, image) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(image))
  }

  return (
    <div className='svg-box flex mb-6 items-start justify-center overflow-x-hidden bg-white rounded-md border-t-0 rounded-t-none overflow-y-auto p-2 border'>
      {filteredImageList.length === 0 && uploadStatus && (
        <div className='grid grid-cols-3 gap-2'>
          <div className='group relative size-[45px] transition-all duration-200 flex items-center justify-center cursor-pointer overflow-hidden rounded-md bg-gray-100'>
            <FontAwesomeIcon
              icon={faSpinner}
              className='animate-spin text-gray-600 size-5'
            />
          </div>
        </div>
      )}
      {filteredImageList && filteredImageList.length !== 0 && (
        <div className='grid grid-cols-3 gap-2'>
          {uploadStatus && (
            <div className='group relative size-[45px] transition-all duration-200 flex items-center justify-center cursor-pointer overflow-hidden rounded-md bg-gray-100'>
              <FontAwesomeIcon
                icon={faSpinner}
                className='animate-spin text-gray-600 size-5'
              />
            </div>
          )}
          {filteredImageList.map((image, index) => (
            <div
              onClick={() => {
                handleAddImage(image);
              }}
              key={image?.id}
              className='group relative size-[45px] transition-all duration-200 flex-none cursor-pointer overflow-hidden rounded-md bg-white hover:opacity-85 hover:ring ring-gray-200 active:ring-gray-300'
            >
              <img
                src={image.url}
                alt={index}
                className='h-full w-full object-cover rounded-md'
                loading='lazy'
                onDragStart={(e) => handleDragImageStart(e, image)} 
              />
              {!hideRemoveImg && (
                <button
                  className='delete-button absolute top-0 right-0 p-1 bg-slate-200 hover:bg-slate-300 rounded-[3px] text-[6px] flex items-center justify-center opacity-0 group-hover:opacity-100'
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the click event of the image/container
                    handleDeleteImage(image); // Use your actual delete function here
                  }}
                >
                  <DeleteSheetIcon className='text-white-0' />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
      {filteredImageList && filteredImageList.length === 0 && !uploadStatus && (
        <div className='flex flex-col flex-grow items-center justify-center w-full h-full text-center text-gray-600 text-xxs'>
          <div className='flex flex-row items-center justify-center w-full mb-2'>
            <FontAwesomeIcon icon={'file-image'} className='flex size-10' />
          </div>
          <div className='flex flex-row items-center justify-center w-full'>
            No image files found.
          </div>
        </div>
      )}
    </div>
  );
};
export default ImageGallery;
