import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {handleLoader} from "../../../utils/helpers";
import {triggerRefreshAudioWaveform, setAudioAddedStatus, setAllMediaToSlide} from "../../../store/reducers/slideListSlice";
import audioFile from "../../../utils/audioFile";
import {deleteRecordedAudioFromS3} from "../../../utils/helpers";

const RecordedAudioGallery = ({uploadStatus, handleSetSelectedAudio}) => {

    const {recordedAudioList} = useSelector(state => state.imageBucket);
    const dispatch = useDispatch();

    const handleLinkAudioToSlide = (audio) => {
        handleLoader(true, 'link_audio_to_slide', dispatch);
        const newAudio = new audioFile('');
        newAudio.loadAudioJSON(audio.getAudioJSON());
        dispatch(setAllMediaToSlide({ type: 'audio', data: newAudio }));
        dispatch(triggerRefreshAudioWaveform());
        dispatch(setAudioAddedStatus(true));
        handleLoader(false, "", dispatch);
    }
    const handleDeleteAudio = async (audio) => {
        // Call the delete function
        await deleteRecordedAudioFromS3(audio, dispatch);
    };

    return (
        <div
            className="svg-box flex mb-6 items-start justify-center overflow-x-hidden bg-white rounded-md border-t-0 rounded-t-none h-[365px] overflow-y-auto p-2 border">
            {
                recordedAudioList.length === 0 && uploadStatus && (
                    <div className="grid grid-cols-3 gap-2">
                        <div
                            className="group relative size-[45px] transition-all duration-200 flex items-center justify-center cursor-pointer overflow-hidden rounded-md bg-gray-100"
                        >
                            <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-5"/>
                        </div>
                    </div>
                )
            }
            {recordedAudioList && recordedAudioList.length !== 0 && (
                <div className="grid grid-cols-3 gap-2">
                    {
                        uploadStatus && (
                            <div
                                className="group relative size-[45px] transition-all duration-200 flex items-center justify-center cursor-pointer overflow-hidden rounded-md bg-gray-100"
                            >
                                <FontAwesomeIcon icon={faSpinner} className="animate-spin text-gray-600 size-5"/>
                            </div>
                        )
                    }
                    {recordedAudioList.map((audio, index) => (
                        <div onClick={() => handleSetSelectedAudio(audio)} key={index} onDoubleClick={() => {handleLinkAudioToSlide(audio)}}
                             className="group relative size-[45px] items-center justify-center transition-all duration-200 flex-none cursor-pointer overflow-hidden rounded-md bg-white hover:opacity-85 hover:ring ring-gray-200 active:ring-gray-300">
                            <div className="flex flex-col items-center justify-center h-full">
                                <FontAwesomeIcon icon={"file-audio"} className="text-gray-600 size-5"/>
                                <p className="text-center text-xxs text-gray-600 truncate">{audio.name}</p>
                                <button
                                    className="absolute top-0 h-[10px] w-[10px] right-1 p-1 bg-black text-white rounded-[50px] text-[6px] flex items-center justify-center"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the click event of the image/container
                                        handleDeleteAudio(audio); // Use your actual delete function here
                                    }}
                                    >x</button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {
                recordedAudioList && recordedAudioList.length === 0 && !uploadStatus && (
                    <div
                        className="flex flex-col flex-grow items-center justify-center w-full h-full text-center text-gray-600 text-xs">
                        <div className="flex flex-row items-center justify-center w-full mb-2">
                            <FontAwesomeIcon icon={"file-image"} className="flex size-10"/>
                        </div>
                        <div className="flex flex-row items-center justify-center w-full">
                            No recorded audio files found.
                        </div>
                    </div>
                )
            }
        </div>
    )

}
export default RecordedAudioGallery;