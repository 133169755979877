   // src/components/SearchBar/SearchBar.jsx
   import React from 'react';
   import { ReactSearchAutocomplete } from 'react-search-autocomplete';

   const TextSearch = ({ items, onSearch, onSelect, placeholder }) => {
     return (
       <div style={{ width: "100%", paddingBottom: "10px"}}>
         <ReactSearchAutocomplete
           items={items}
           onSearch={onSearch}
           onSelect={onSelect}
           placeholder={placeholder}
           autoFocus
           styling={{ zIndex: 2 }} // To ensure the search box is above other elements
         />
       </div>
     );
   };

   export default TextSearch;