import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import Topbar from "../../modules/LayoutModule/Content/Topbar/Topbar";
import { Link } from 'react-router-dom';
import Footer from "../../modules/LayoutModule/Content/Footer/Footer";

function BlogArticle() {
    const { id , slug} = useParams();

    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [popularPosts, setPopularPosts] = useState([]);

    useEffect(() => {
        axios.get(`https://backend.ailms.co/api/articles/${id}`)
            .then(response => {
                setPost(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Error fetching post data');
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        axios.get('https://backend.ailms.co/api/articles')
            .then(response => {
                setPopularPosts(response.data.data.slice(0, 3));
            })
            .catch(error => console.error('Error fetching popular posts:', error));
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!post) return <div>Post not found</div>;

    return (
        <>
            <Topbar />
               {/* Jumbotron with Search Bar */}
        <div className="relative text-white text-center w-full"> {/* Removed py-12 */}
          <img src="/header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" /> {/* Full width image */}
          <img src="/component4.png" alt="Component 4" className="absolute bottom-10 left-0 w-24 h-4" /> 
          <img src="/component4.png" alt="Component 4" className="absolute top-10 opacity-50 right-0 w-24 h-4" /> {/* Added image in the bottom left corner */}
          <div className="relative z-10 py-12"> {/* Added py-12 to inner div */}
            <h1 className="text-4xl font-bold mb-4">Blog</h1>
          </div>
        </div>
            <div className="p-4">
                <nav className="text-sm text-gray-500">
                    <a href="/" className="hover:underline">Home</a> &gt; 
                    <a href="/blog" className="hover:underline">Blog</a> &gt;
                    <span className="text-gray-700">{post?.attributes?.Slug || slug}</span>
                </nav>
            </div>
            <main className="container mx-auto mt-8">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-8/12 px-4 mb-8">
                        <h2 className="text-4xl font-bold mt-4 mb-2">{post.attributes.Title}</h2>
                        <p className="text-sm text-gray-500 mb-4">
                            Published on: {new Date(post.attributes.publishedAt).toLocaleDateString()} | Likes: {post.attributes.likes || 0}
                        </p>
                        <div className="blog-detail-container">
                            {post.attributes.Content ? (
                                <BlocksRenderer content={post.attributes.Content} />
                            ) : (
                                <p>No content available</p>
                            )}
                        </div>
                    </div>
                    <div className="w-full md:w-4/12 px-4 mb-8">
                        <div className="bg-custom-gray px-4 py-6 rounded">
                            <h3 className="text-lg font-bold mb-2">Popular Articles</h3>
                            <ul className="list-disc list-inside">
                                {popularPosts.map(post => (
                                    <li key={post.id}>
                                        <Link to={`/blog/${post.id}`} className="text-gray-700 hover:text-gray-900">{post.attributes.Title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default BlogArticle;