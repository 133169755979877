import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Topbar from "../../modules/LayoutModule/Content/Topbar/Topbar";
import { CommentSection } from 'react-comments-section';
import 'react-comments-section/dist/index.css';
import Footer from "../../modules/LayoutModule/Content/Footer/Footer";
import { publicApi } from '../../api/publicApi';
import useHandleCheckout from '../../utils/handleCheckout';

const SingleProject = () => {
  const { project_id, project_name } = useParams();
  const [project, setProject] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const handleCheckout = useHandleCheckout();

  useEffect(() => {
    const fetchProjects = async () => {
      console.log("Fetching project with ID:", project_id);
      try {
        const response = await publicApi.get(`/api/projects/${project_id}`);
        if (response.data) {
          setProject(response.data);
        } else {
          console.log("No data received");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchProjects();
  }, [project_id]);

  if (!project) {
    console.log("Project data is not available");
    return <div>Loading...</div>;
  }

  
  
  

  const sampleComments = [
    {
      userId: '01a',
      comId: '012',
      fullName: 'Riya Negi',
      avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'Hey, Loved your blog! ',
      replies: []
    },
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I have a doubt about the 4th point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      replies: []
    }
  ];

  return (
    <div>
      <Topbar />
      <div className="relative text-white text-center w-full">
        <img src="/header_image.png" alt="Header" className="absolute inset-0 w-full h-full object-cover" />
        <div className="relative z-10 py-12">
          <h1 className="text-4xl font-bold mb-4">Marketplace</h1>
          <p className="mb-6">Explore our wide range of projects and assets available for purchase.</p>
        </div>
      </div>
      <div className="px-10">
        <div className="p-4">
          <nav className="text-sm text-gray-500">
            <a href="/" className="hover:underline">Home</a> &gt; 
            <a href="/Marketplace" className="hover:underline">Marketplace</a> &gt;
            <span className="text-gray-700">{project_name?.name || project_name}</span>
          </nav>
        </div>

        {project && (
          <div className="p-4">
            <div className="flex flex-col md:flex-row-reverse gap-6">
              <div className="w-full md:w-2/3">
                <img 
                  src={`https://backend.ailms.co${project.attributes.project_image.data.attributes.formats.large?.url || project.attributes.project_image.data.attributes.url}`}
                  alt={project.attributes.name} 
                  className="w-full h-96 object-cover rounded-lg" 
                />
                <div className="mt-4 flex gap-2 overflow-x-auto">
                  {project.attributes.gallery_images && project.attributes.gallery_images.data.map((img, index) => (
                    <img 
                      key={index} 
                      src={`https://backend.ailms.co${img.attributes.formats.thumbnail.url}`} 
                      alt={project.attributes.name || "Project image"}
                      className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                    />
                  ))}
                  {project.attributes.slides.data.slice(0, 3).map((slide, index) => (
                    slide.attributes.thumbnail ? (
                      <img 
                        key={index} 
                        src={slide.attributes.thumbnail} 
                        alt={`Slide ${index + 1}`}
                        className="h-20 w-20 object-cover rounded-lg cursor-pointer"
                      />
                    ) : (
                      <div key={index} className="h-20 w-20 bg-gray-200 rounded-lg"></div>
                    )
                  ))}
                  {project.attributes.slides.data.length < 3 && 
                    Array.from({ length: 3 - project.attributes.slides.data.length }).map((_, index) => (
                      <div key={`placeholder-${index}`} className="h-20 w-20 bg-gray-200 rounded-lg"></div>
                    ))
                  }
                </div>
              </div>

              <div className="w-full md:w-1/3">
                <h1 className="text-3xl font-bold mb-4">{project.attributes.name}</h1>
                <p className="text-gray-700 mb-2">Category: {project.attributes.category}</p>
                <p className="text-gray-700 mb-2">Price: ${project.attributes.price}</p>

                <div className="flex flex-wrap gap-2 mb-4">
                  {project.attributes.tags.data && project.attributes.tags.data.length > 0 ? project.attributes.tags.data.map(tag => (
                    <span key={tag.id} className="bg-blue-200 hover:bg-blue-300 py-1 px-2 rounded-lg text-xs">
                      {tag.attributes.tagName}
                    </span>
                  )) : "No tags available"}
                </div>

                <button 
                  className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xxs font-medium text-gray-900 rounded-lg bg-custom-lightBlue hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300" 
                  onClick={() => handleCheckout(project.attributes.project_id, project.attributes.price, project.attributes.user_id)}
                  style={{ minWidth: '100px' }}
                >
                  <span className="relative text-white px-6 text-xs font-bold py-1.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
                    Buy ${project.attributes.price || "XX"} 
                  </span>
                </button>
               
              </div>
            </div>

            <div className="mt-8">
              <div className="tabs flex">
                <button 
                  className={`mr-4 text-lg ${activeTab === 'description' ? 'font-bold' : ''}`} 
                  onClick={() => setActiveTab('description')}
                >
                  Description
                </button>
                <button 
                  className={`text-lg ${activeTab === 'comments' ? 'font-bold' : ''}`} 
                  onClick={() => setActiveTab('comments')}
                >
                  Comments
                </button>
              </div>

              <div className="tab-content mt-4">
                {activeTab === 'description' && (
                  <p className="text-gray-700">
                    {typeof project.attributes.description === 'string' ? project.attributes.description : "Sample description: This is a placeholder description for the project. Detailed information about the project will be displayed here."}
                  </p>
                )}
                
                {activeTab === 'comments' && (
                  <CommentSection
                      currentUser={{
                        currentUserId: '01a',
                        currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
                        currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
                        currentUserFullName: 'Riya Negi'
                      }}
                      logIn={{
                        loginLink: 'http://localhost:3001/',
                        signupLink: 'http://localhost:3001/'
                      }}
                      commentData={sampleComments}
                      onSubmitAction={(data) => console.log('check submit, ', data)}
                      currentData={(data) => {
                        console.log('current data', data)
                      }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SingleProject;
