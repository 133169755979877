import {LinkSlideIcon, LinkUrlIcon} from "../../utils/iconHelper";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeJumpToSlidePopupStatus, changeLinkPopupStatus} from "../../utils/helpers";

const LinkControl = () => {

    const {activeObject, canvas} = useSelector(state => state.fabricCanvas);
    const linkUrlRef = useRef(null);
    const linkSlideRef = useRef(null);
    const [isLinkUrlOpen, setIsLinkUrlOpen] = useState(false);
    const [isLinkSlideOpen, setIsLinkSlideOpen] = useState(false);
    const [link, setLink] = useState('');
    const [jumpToSlide, setJumpToSlide] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if(activeObject) {
            const {link, jumpToSlide} = activeObject;
            setLink(link);
            setJumpToSlide(jumpToSlide);
        }
    }, [activeObject, activeObject.link])

    const handleEmbedURLChange = (event) => {
        const newValue = event.target.value;
        setLink(newValue);
    }

    const handleEmbedSlideChange = (event) => {
        const newValue = event.target.value;
        if(isNaN(newValue)) {
            setJumpToSlide(0);
            return;
        }
        setJumpToSlide(parseInt(newValue));
    }

    const embedUrlInObject = () => {
        activeObject.set({
            link: link,
        });
        if(link === '') {
            changeLinkPopupStatus(canvas, false, dispatch);
        } else {
            changeLinkPopupStatus(canvas, true, dispatch);
        }
        canvas.renderAll();
    }

    const embedJumpToSlide = () => {
        activeObject.set({
            jumpToSlide: jumpToSlide,
        });

        if(jumpToSlide === 0) {
            changeJumpToSlidePopupStatus(canvas, false, dispatch);
        } else {
            changeJumpToSlidePopupStatus(canvas, true, dispatch);
        }

        canvas.renderAll();
    }

    const toggleLinkUrlPopup = () => {
        setIsLinkUrlOpen((prevIsLinkUrlOpen) => !prevIsLinkUrlOpen);
    }

    const toggleLinkSlidePopup = () => {
        setIsLinkSlideOpen((prevIsLinkSlideOpen) => !prevIsLinkSlideOpen);
    }

    const handleClickOutside = (event) => {
        if (linkUrlRef.current && !linkUrlRef.current.contains(event.target)) {
            setIsLinkUrlOpen(false);
        }

        if (linkSlideRef.current && !linkSlideRef.current.contains(event.target)) {
            setIsLinkSlideOpen(false);
        }
    };

    useEffect(() => {
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array means this effect runs only once, on mount

    return (
        <div className="w-1/2 relative text-gray-600">
            <label htmlFor="fontSelector" className="text-xxs font-medium text-gray-600">Link</label>
            <div className="mt-2 flex">
                <div className="flex items-center flex-grow" ref={linkUrlRef}>
                    <button
                        onClick={toggleLinkUrlPopup}
                        className={`flex size-10 flex-grow items-center justify-center rounded-l-md border text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200 ${isLinkUrlOpen ? ' bg-gray-100' : 'bg-white'}`}>
                        <LinkUrlIcon/>
                    </button>
                    {
                        isLinkUrlOpen && (
                            <div
                                className="absolute flex items-center justify-center text-xxs top-full right-0 mt-2 z-50 bg-white w-[212px] shadow-md rounded-md p-2 space-x-2">
                                <input
                                    id="embedUrlControl"
                                    className="p-2 border bg-gray-100 border-gray-200 rounded-md h-8 w-full"
                                    onChange={handleEmbedURLChange}
                                    value={link}
                                    type="text"
                                    placeholder="https://example.com"
                                />
                                <button
                                    onClick={embedUrlInObject}
                                    className="flex h-8 p-2 items-center justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200">
                                    Embed
                                </button>
                            </div>
                        )
                    }
                </div>
                <div className="flex items-center flex-grow" ref={linkSlideRef}>
                    <button
                        onClick={toggleLinkSlidePopup}
                        className={`flex size-10 flex-grow items-center justify-center rounded-r-md border text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200 ${isLinkSlideOpen ? ' bg-gray-100' : 'bg-white'}`}>
                        <LinkSlideIcon/>
                    </button>
                    {
                        isLinkSlideOpen && (
                            <div
                                className="absolute flex items-center justify-center text-xxs top-full right-0 mt-2 z-50 bg-white w-[212px] shadow-md rounded-md p-2 space-x-2">
                                <p className="flex flex-grow">Link to slide</p>
                                <input
                                    id="embedUrlControl"
                                    className="p-2 border bg-gray-100 border-gray-200 rounded-md h-8 w-[60px]"
                                    onChange={handleEmbedSlideChange}
                                    value={jumpToSlide}
                                    type="number"
                                    min={0}
                                />
                                <button
                                    onClick={embedJumpToSlide}
                                    className="flex h-8 p-2 items-center justify-center rounded-md border bg-white text-xxs transition-colors duration-200 hover:bg-gray-100 active:bg-gray-200">
                                    Embed
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default LinkControl;