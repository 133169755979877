import React from 'react';
import TopBar from './../Topbar';
import Footer from './Footer';

const PrivacyPolicy = () => {
    return (
        <>
        <TopBar />
        <div className="terms-container" style={{ margin: '0 auto', maxWidth: '800px', textAlign: 'left' }}>
            <h1 style={{ fontSize: '2em', marginBottom: '20px', textAlign: 'center' }}>Privacy Policy</h1>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>1. Use of Website</h2>
                <p>You must be at least 18 years old to use this website. You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of or restrict or inhibit the use and enjoyment of this site by any third party.</p>
            </section>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>2. Intellectual Property</h2>
                <p>All content on this website, including but not limited to text, graphics, logos, and software, is the property of AIMS and is protected by applicable copyright and trademark laws.</p>
            </section>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>3. User Accounts</h2>
                <p>You may be required to create an account to access certain features of the website. You are responsible for maintaining the confidentiality of your account and password.</p>
            </section>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>4. Prohibited Activities</h2>
                <p>You agree not to engage in any of the following activities: Attempting to gain unauthorized access to the website. Using the website for any unlawful purpose.</p>
            </section>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>5. Limitation of Liability</h2>
                <p>AIMS is not liable for any damages arising from your use of the website, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
            </section>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>6. Modifications</h2>
                <p>AIMS reserves the right to modify or discontinue the website or any portion of it without notice.</p>
            </section>
            <section className="terms-section" style={{ marginBottom: '20px' }}>
                <h2 style={{ fontSize: '1.5em' }}>7. Governing Law</h2>
                <p>These terms are governed by and construed in accordance with the laws of Spain, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>
            </section>
        </div>
        <Footer />
        </>
    );
};

export default PrivacyPolicy;