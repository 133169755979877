import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setCropperVisibility} from "../../store/reducers/cropperSlice";
import {useDispatch, useSelector} from "react-redux";
const CropperPanel = ()=>{

    const { canvas} = useSelector(state => state.fabricCanvas);
    const dispatch=useDispatch()
    return(
        <div className='flex fixed z-50 top-0'>
            <div className="flex items-center justify-end w-fit mx-auto max-w-600 z-1000 h-[40px] bg-gray-900 p-[10px]  text-white rounded-md shadow-md overflow-hidden pointer-events-auto">
                <div>
                <button
                    onClick={() => {
                    let obj = canvas.getActiveObject();
                    if(['cropper','nextCropped','background'].includes(obj.id)){
                        obj.img.cropApply();
                        setTimeout(()=>{
                            canvas.renderAll();
                        },100)
                        // reRenderCanvasObjects(canvas, crop, true);
                        dispatch(setCropperVisibility(false));
                    }
                }}

                    className="inline-block mr-[10px] p-[3px] text-white no-underline font-inter font-normal text-base leading-5 focus:outline-none focus:border-none  bg-green-500 cursor-pointer appearance-none tap-highlight-transparent ">
                    <FontAwesomeIcon icon={"check"}
                                     className="size-3 text-gray-600 mr-2 transition-colors duration-200 group-hover:text-green-500"/>

                    Save
                </button>
                <button
                    onClick={() => {
                        let objs = canvas.getObjects();
                        let cropper = objs.find(ob=>ob.id === 'background');
                        console.log(cropper)
                        if(cropper){
                            cropper.img.cropApply(true)
                        }
                        /*reRenderCanvasObjects(canvas, crop, false);*/
                        dispatch(setCropperVisibility(false));
                    }}
                    className="inline-block p-[3px] text-white no-underline font-inter font-normal text-base leading-5 focus:outline-none focus:border-none  bg-green-500 cursor-pointer appearance-none tap-highlight-transparent  ">
                    <FontAwesomeIcon icon={"close"} className="size-3 text-gray-600 mr-2 transition-colors duration-200 group-hover:text-red-500"/>
                    Cancel
                </button>
                </div>

            </div>
        </div>
    )
}
export default CropperPanel;